import {AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {IRoutingParams} from '../../../common/models/context.model';
import {Registry} from '../../../common/models/registry.model';
import {ApiV2Service} from '../../../common/services/api-v2.service';
import {ContextService} from '../../../common/services/context.service';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {LocalPreloader, PreloaderService} from '../../../common/services/preloader.service';
import {isAdmin, isClubManager} from '../../../common/models/user-info.model';
import {ClubSettingService} from '../../club-settings/club-settings.service';
import {AuthService} from '../../../common/services/auth.service';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';
import {IReportParameter} from '../../../common/models/reports.model';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-finance-registry',
  templateUrl: './finance-registry.component.html',
  styleUrls: ['./finance-registry.component.scss']
})
export class FinanceRegistryComponent implements OnInit, AfterViewInit {
  protected ngUnsubscribe: Subject<void> = new Subject();
  public context: IRoutingParams;
  public registry: Registry[] = [];

  // -------------------------------------------------------
  public start: Date = new Date();
  public end: Date = new Date();
  public showPaymentScreenToAdmin = false;
  public ordersArray: MatTableDataSource<any> = new MatTableDataSource([]);
  public ordersArraySummary: MatTableDataSource<any> = new MatTableDataSource([]);
  public ordersColumnNames = [
    'paymentDate',
    'clientName',
    'paymentSource',
    'type',
    'info',
    'paymentMethod',
    'amount'
  ];
  public ordersColumns = [
    {name: 'paymentDate', displayName: 'Дата оплаты'},
    {name: 'clientName', displayName: 'Клиент'},
    {name: 'paymentSource', displayName: 'Источник оплаты'},
    {name: 'type', displayName: 'Тип'},
    {name: 'info', displayName: 'Информация о покупке'},
    {name: 'paymentMethod', displayName: 'Способ оплаты'},
    {name: 'amount', displayName: 'Оплачено'}
  ];
  public isExistData: boolean | undefined;
  public dataConsolidated: {}[];
  public consolidatedColumnNames: string[];
  public consolidatedColumns: {}[];
  public selectedColumnFilter = '';
  public searchString = '';
  public reportName: string;
  public filteredValues =  {};
  public selectModel: any = {};
  public selectFilter: any = {};
  public selectButtonFilter: {};
  public linkWhatsApp = 'https://wa.me/79031711653?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D0%90%D0%BD%D0%B0%D1%81%D1%82%D0%B0%D1%81%D0%B8%D1%8F!%20%D0%9F%D0%B8%D1%88%D1%83%20%D0%B2%D0%B0%D0%BC%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B2%D0%BE%D0%B4%D1%83%20%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD-%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%20%D0%B2%20Sport%20Priority'
  public linkTelegram = 'https://t.me/account_sp'
  public hideTitle: any;
  public filterSelectObj = [];
  public validationStatus: string;
  public durationAboniment = '';
  public selectPeriod: 'today' | 'week' | 'month' | 'any' = 'today';
  public clubTimeZone: number | undefined;
  public range = new FormGroup({
    start: new FormControl(this.start, [Validators.required]),
    end: new FormControl(this.end, [Validators.required]),
  });
  public isValid: boolean;
  public params: IReportParameter[] = null;
  public param: any;
  public pageIndex = 0;
  public pageSize = 20;
  public totalItems = 0;

  @ViewChild('tablePaymentsRef') public tablePaymentsRef: ElementRef;

  @ViewChild('tmplPaymentPopup') tmplPaymentPopup: TemplateRef<any>;
  @ViewChild('tmplSubscriptionPopup') tmplSubscriptionPopup: TemplateRef<any>;
  @ViewChild('tmplCertificatePopup') tmplCertificatePopup: TemplateRef<any>;
  @ViewChild('tmplClubeventPopup') tmplClubeventPopup: TemplateRef<any>;

  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.ordersArray.sort = sort;
  }

  dateFilter = (d: Date | null): boolean => {
    const date = d || new Date();
    return moment(date) <= moment();
  };

  constructor(
    private apiV2: ApiV2Service,
    private contextService: ContextService,
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: ClubSettingService,
    public authService: AuthService,
    private alertsService: AlertsService,
    private readonly preloaderService: PreloaderService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    moment.locale('ru');
    this.context = this.contextService.getRoutingParams();
    this.contextService.contextInfo.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(contextInfo => this.clubTimeZone = contextInfo?.clubs.find(c => c.id === this.context.clubId)?.timeZone);
    await this.apiV2.registry.getByClub(this.context.clubId).then(async (registry) => {
      this.registry = registry.sort((a, b) => { return b.createdAt - a.createdAt });
    });


    // -------------------- Загрузка таблицы "Все оплаты"
    // проверка галочки "Показывать экран оплат администраторам"
    const settings = await this.settingsService.getClubSettingsByClub();
    const alias = settings.find(s => s.alias === 'ShowPaymentScreenToAdmin');
    this.showPaymentScreenToAdmin = alias?.value === 'true';
    // проверка на то, что это мэнэджер (администратор) клуба
    const ui = await this.authService.getUserInfo();
    if (isClubManager(ui) && this.showPaymentScreenToAdmin) {
      this.showPaymentScreenToAdmin = true;
    }
    if (isAdmin(ui)) {
      this.showPaymentScreenToAdmin = true;
    }

    const _startDate = this.start ? moment(this.start) : null; // !!!!!
    this.range.get('start').setValue(_startDate.toDate());
    if (this.showPaymentScreenToAdmin) {
      await this.loadData();
    }
  }

  ngAfterViewInit() {
    this.ordersArray.paginator = this.paginator;
  }

  public dateFormat(date: Date): any {
    return moment(date).format('DD.MM.YYYY');
  }

  public data(data: string, field: string): any {
    return JSON.parse(data)[field];
  }

  public formatNumber(value: number): string {
    let point = '';
    let x = String(value).replace(/(\.|,)\d+/, (m) => { point = m; return ''; });
    x = x.split('').reverse().join('')
      .replace(/(\d{3})/g, '$1 ')
      .split('').reverse().join('');
    return x + point;
  }

  onRegistry(registry: Registry) {
    this.router.navigate([`/${this.context.cityId}/${this.context.clubNetId}/${this.context.clubId}/finance/registry/${registry.id}`], {relativeTo: this.route}).then();
  }


  // --------Все оплаты

  convertDurationUnit(subsc: { duration: number, durationUnit: number }) {
    switch (subsc?.durationUnit) {
      case 0  : return `${subsc.duration} дн.`;
      case 1 : return `${subsc.duration} нед.`;
      case 2: return `${subsc.duration} мес.`;
      case 3 :
        const rem = subsc.duration % 10;
        return subsc.duration + (rem === 0 ? 'лет' : rem === 1 ? 'год' : rem <= 4 ? 'года' : 'лет');
      default: return 'Н/Д';
    }
  }

  async reloadData() {
    this.pageIndex = 0;
    await this.loadData();
  }

  @LocalPreloader<FinanceRegistryComponent>(x => x.preloaderService, a => a.tablePaymentsRef)
  public async loadData() {
    this.isExistData = false;
    this.getTimeRange();
    const sd = moment(this.start).format('YYYY-MM-DDT00:00:00');
    const ed = moment(this.end).format('YYYY-MM-DDT23:59:59');

    await this.apiV2.clubPayments.getOrdersByClub(this.context.clubId, sd, ed, this.pageIndex, this.pageSize).then(async (res) => {
      const _arr: any[] = [];
      if (res && res?.items.length > 0) {
        // Формируем массив данных
        let _id = 0;
        let amountSummary = 0;
        const amountPeriod = res?.amountTotal.toFixed(2);
        for (const item of res?.items) {
          const _item = this.dataParse(item, _id++);
          _arr.push(_item);
          amountSummary += Number(_item.amount);
        }
        this.ordersArray.data = _arr;
        setTimeout(() => {
          this.pageIndex = res?.pagingInfo.pageIndex;
          this.pageSize = res?.pagingInfo.pageSize;
          this.totalItems = res?.pagingInfo.totalItemCount;
          this.paginator.pageIndex = res?.pagingInfo.pageIndex;
          this.paginator.pageSize = res?.pagingInfo.pageSize;
          this.paginator.length = res?.pagingInfo.totalItemCount;
        });

        this.ordersArraySummary.data = [{
          id: 0,
          paymentDate: 'Итого',
          amount: amountSummary.toFixed(2)
        }];

        this.dataConsolidated = [
          {id: 0, dateFrom: moment(this.start).format('DD.MM.YYYY'), dateTo: moment(this.end).format('DD.MM.YYYY'), dateToday: moment().format('DD.MM.YYYY'), Paid: amountPeriod}
        ];
        this.consolidatedColumnNames = ['dateFrom', 'dateTo', 'dateToday', 'Paid'];
        this.consolidatedColumns = [{name: 'dateFrom', displayName: 'Дата начала'}, {name: 'dateTo', displayName: 'Дата конца'}, {name: 'dateToday', displayName: 'Дата формирования'}, {name: 'Paid', displayName: 'Оплачено'}];

        this.ordersArray.filterPredicate = this.customFilterPredicate();
        if (this.ordersArray.data.length === 0) {
          this.hideTitle = true
        } else {
          this.hideTitle = false
          // формирование масива обьектов фильтрации
          for (const i of this.ordersColumnNames) {
            this.filterSelectObj.push({name: i, options: []});
          }
          // переменные фильтрации
          this.filterSelectObj.filter((o) => {
            o.options = this.getFilterObject(this.ordersArray.data, o.name);
            o.options.sort();
          });
          // создает значения для фильрации
          this.filteredValues = JSON.parse(JSON.stringify(this.ordersArray.data[0]));
          this.selectButtonFilter = JSON.parse(JSON.stringify(this.ordersArray.data[0]));
          for (const i in this.filteredValues) {
            this.filteredValues[i] = [];
            this.selectButtonFilter[i] = null;
          }
        }
      }
      this.isExistData = res && res?.items.length > 0;
    });
  }

  public dataParse(item: any, id: any) {
    const res = {
      id: id,
      billingData: item.billingData,
      paymentDate: moment(item.date).utcOffset(this.clubTimeZone).format('DD.MM.YYYY HH:mm') || '', // Дата покупки
      type: this.getOperationType(item), // Тип
      info: this.infoPayment(item) || '', // Информация о покупке ()
      // infoDetail: this.infoDetailPayment(item) || "",
      amount: this.convertAmount(item) || '', // item?.billingData?.amount, // Оплачено
      paymentMethod: this.convertPayMethod(item) || '', // Способ оплаты
      paymentSource: this.convertClientType(item) || '', // Источник оплаты
      clientInfo: item.clientInfo,
      urlClient: item.clientInfo?.id ? this.contextService.makeContextUrl(`clubclients/${item.clientInfo.id}`) : undefined,
      clientName: item.clientInfo?.fullName,
      contactPhone: item.billingData?.contactPhone,
      isExpanded: false,
      subscriptionInfo: item.subscriptionInfo,
      orderPayment: item.orderPayment,
      certInfo: item.certInfo,
      clubEventInfo: item.clubEventInfo,
      cert: item?.cert || '',
      certAboniment: item?.certAboniment || '',
      certAbonimentData: item?.certDuration || '',
      certCode: item?.billingData?.certificateNumber || '',
      comment: item?.billingData?.comment || 'Не указано',
      commentInOperation: item?.billingData?.commentInOperation || 'Не указано',
      // dataBill: JSON.parse(item?.billingData?.dataBilling)  || "",
      orderDesc: item?.billingData?.orderItemDesc || '',
      // other: other || ""
    };
    return res;
  }

  public convertAmount(item: any) {
    if (item.amount) {
      const _a = item.amount;
      const nstring = (_a + '');
      const narray  = nstring.split('.');
      const result  = (narray.length > 1 ? narray[0] + '.' + (narray[1].length === 1 ? narray[1] + '0' : narray[1]) : narray[0] + '.00');
      return result;
    }
    return '0.00';
  }

  // Тип
  public getOperationType(item: any) {
    if (item.billingData) {
      switch (item.billingData.operationType) {
        case 6: return 'Абонемент';
        case 8: return 'Оплата';
        case 18: return 'Билет';
        case 19: return 'Сертификат';
        default: return '';
      }
    } else {
      return item.orderPayment ? 'Доплата по абонементу' : 'Абонемент';
    }
  }

  public convertClientType(item: any) {
    if (item.billingData) {
      switch (item.billingData.clientType) {
        case 0: return 'CRM';
        case 1: return 'Виджет "Онлайн оплата"';
        case 2: return 'Виджет "Сертификаты"';
        case 3: return 'Приложение';
        default: return 'Н/Д';
      }
    } else {
      return 'CRM';
    }
  }

  public infoPayment(item: any) {
    const _type = item.billingData?.operationType;
    switch (_type) {
      case 6:
        return item.subscriptionName ?? item.billingData.comment ?? '';
      case 19: // Сертификат
        if (item.certInfo?.type === 1) {
          return item.subscriptionInfo?.name;
        } else {
          return 'На сумму';
        }
      case 18:
        let ticketsInfo = item.clubEventInfo?.ticketsCount > 1 ? 'Билеты' : 'Билет';
        if (item.clubEventInfo?.ticketTitle) {
          ticketsInfo += ` «${item.clubEventInfo?.ticketTitle}»`;
        }
        if (item.clubEventInfo?.ticketsCount > 1) {
          ticketsInfo += ` ×${item.clubEventInfo?.ticketsCount}`;
        }
        if (item.clubEventInfo?.desc) {
          ticketsInfo += `\nМероприятие «${item.clubEventInfo?.desc}»`;
        }
        return ticketsInfo;
      case 8:
        if (item?.subscriptionName) { return item?.subscriptionName; }
        if (item?.billingData?.comment) { return item?.billingData?.comment; }
        if (item?.billingData?.commentInOperation) { return item?.billingData?.commentInOperation; }
        const _parse = JSON.parse(item?.billingData?.dataBilling);
        if (_parse?.formData) { return _parse?.formData?.productDescription; } else { return ''; }
      default:
        if (item.orderPayment && item.subscriptionInfo) {
          return item.subscriptionName ?? item.billingData.comment ?? '';
        } else {
          return '';
        }
    }
  }

  public convertPayMethod(item: any) {
    switch (item.orderPayment?.source) {
      case 'cash': return 'Наличные';
      default: return 'Безналичный';
    }
  }

  public cellContent(elem: any, itemName: any) {
    if (elem) {
      if (itemName === 'clientName' && elem['clientInfo']) {
        if (elem['urlClient']) {
          return `<a class="btn btn-link" href="${elem['urlClient']}">${elem['clientInfo'].fullName}</a>`;
        } else {
          const client = elem['clientInfo'].fullName ?? '';
          const phone = elem['clientInfo'].phone ? `</br>${elem['clientInfo'].phone}` : '';
          return client + phone;
        }
      }
      return elem[itemName];
    }
    return '';
  }

  async onRangePickerChange(event: any) {
    this.selectPeriod = 'any';
    this.getTimeRange();
    if (event.value) {
      await this.reloadData();
    }
  }
  getTimeRange() {
    if (this.range.valid) {
      this.start = this.range.get('start').value;
      this.end = this.range.get('end').value;
    }
  }

  showPopUp(item: any, e: any) {
    if (e.target.href) {
      return;
    }
    switch (item.billingData?.operationType) {
      case 6:
        this.alertsService.showConfirm({
          template: { ref: this.tmplSubscriptionPopup, context: { item } },
          buttons: []
        });
        break;
      case 8:
        this.alertsService.showConfirm({
          template: { ref: this.tmplPaymentPopup, context: { item } },
          buttons: []
        });
        break;
      case 18:
        this.alertsService.showConfirm({
          template: { ref: this.tmplClubeventPopup, context: { item } },
          buttons: []
        });
        break;
      case 19:
        this.alertsService.showConfirm({
          template: { ref: this.tmplCertificatePopup, context: { item, expiredAt: moment(item.expiredAt).format('LLL') } },
          buttons: []
        });
        break;
      default:
        if (!item.orderPayment || !item.subscriptionInfo) {
          throw new Error();
        }
        this.alertsService.showConfirm({
          template: { ref: this.tmplSubscriptionPopup, context: { item } },
          buttons: []
        });
        break;
    }
  }

  async onPageChange(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    await this.loadData();
  }

  AlignRight(Element) {
    switch (Element) {
      case 'amount':
        return true;
    }
  }
  customFilterPredicate() {
    return(data, columnFilter: string): boolean => {
      let rowDataAsString = '';
      let isPositionAvailable = true;
      const searchString = JSON.parse(columnFilter);
      for (const colName of this.ordersColumnNames) {
        if (data[colName]) {
          rowDataAsString += data[colName] + '\n';
        }
      }
      rowDataAsString = rowDataAsString.toLowerCase();

      if (rowDataAsString.indexOf(this.searchString.toLowerCase()) === -1) {
        return false;
      }
      if (this.selectedColumnFilter === '') {
        return true;
      } else {
        for (const filterKey in searchString) {
          if (searchString[filterKey].length) {
            isPositionAvailable = isPositionAvailable && searchString[filterKey].includes(data[filterKey] && data[filterKey].toString().trim());
          }
        }
        return isPositionAvailable;
      }
    };
  }
  applyFilterInput(filterValue) {
    this.searchString = filterValue;
    this.ordersArray.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
    if (this.ordersArraySummary.data.length) {this.AdditionDataSummary()}
  }
  clearFilterInput() {
    this.searchString = '';
    this.ordersArray.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
    if (this.ordersArraySummary.data.length) {this.AdditionDataSummary()}
  }
  onChangeSelect(filterValue, filterColum) {
    this.selectFilter = filterValue[filterColum];
  }
  applyFilterColum(filterValue, filterColum) {
    this.filteredValues[filterColum] = filterValue;
    // tslint:disable-next-line:triple-equals
    if (this.filteredValues[filterColum] == 0) {
      this.selectButtonFilter[filterColum] = null
    } else {this.selectButtonFilter[filterColum] = filterValue}
    this.selectedColumnFilter = filterColum;
    this.ordersArray.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
    if (this.ordersArraySummary.data.length) {this.AdditionDataSummary()}
  }
  clearColumn(filterColum) {
    this.filteredValues[filterColum] = [];
    this.selectModel[filterColum] = [];
    this.selectButtonFilter[filterColum] = null;
    this.selectFilter = [];
    this.ordersArray.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
    if (this.ordersArraySummary.data.length) {this.AdditionDataSummary()}
  }
  filterCheckBoxValue() {
    for (const selectValue in this.filteredValues) {
      if (this.filteredValues[selectValue].length === 0) {
        this.filterSelectObj.filter((o) => {
          if (o.name === selectValue) {
            o.options = this.getFilterObject(this.ordersArray.filteredData, o.name);
          }
        });
      }
    }
  }
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(String(obj[key])) && obj[key] !== '') {
        uniqChk.push(String(obj[key]));
      }
      return obj;
    });
    return uniqChk;
  }

  trackByFn(index, item) {
    return item.id; // unique id corresponding to the item
  }

  AdditionDataSummary() {
    let amount = 0;
    this.ordersArray.filteredData.filter((o) => {
      if (o.amount) {
        amount += Number(o.amount);
      }
    });
    this.ordersArraySummary.data.filter((o) => {
      o.amount = amount.toFixed(2);
    });
  }

}
