import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { ISubscription, IDurationUnit } from 'app/common/models/subscription.model';
import { SubscriptionService } from 'app/components/subscriptions/services/subscription.service';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { ICourseModel } from 'app/common/models/course.model';
import { IPlanVariant } from 'app/common/models/plan-variant.model';
import { EditItemComponent } from 'app/common/edit-item.component';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { CoursesService } from 'app/components/courses/services/courses.service';
import { VariantsComponent } from '../variants/variants.component';
import { MediaService } from 'app/common/services/media.service';
import * as moment from 'moment';
import { IIdName } from 'app/common/models/id-name.model';
import { SubscriptionActivationType } from 'app/common/models/activation-type';

@Component({
  selector: 'app-subscription-edit',
  templateUrl: './subscription-edit.component.html',
  styleUrls: ['./subscription-edit.component.scss']
})
export class SubscriptionEditComponent extends EditItemComponent<ISubscription> implements OnInit {
  public static readonly componentName: string = 'SubscriptionEditComponent';

  public clubCourses: Array<ICourseModel> = [];

  fieldMessages = {
    name: {
      NotEmpty: 'Это поле обязательно'
    }
  };

  @ViewChild(VariantsComponent) variants: VariantsComponent;
  visibleOnSaleCampain: boolean;
  visibleOnSuspend: boolean;
  initCourses: IIdName[];

  constructor(
    injector: Injector,
    protected mediaService: MediaService,
    protected service: SubscriptionService,
    protected coursesService: CoursesService,
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.coursesService.getItemList()
      .then(courses => this.clubCourses = courses);
  }

  afterModelInit() {
    if (!this.Model.medias) {
      this.Model.medias = {
        images: [],
        videos: []
      }
    }
    this.initCourses = this.Model.courses;
  }

  showSales() {
    if (this.visibleOnSaleCampain) {
      this.addingPriceOnSaleCampain();
      this.addingDates();
    }
    else {
      this.clearPriceOnSaleCampain();
      this.clearDates();
    }
  }

  showSuspend() {
    if (this.visibleOnSuspend) {
      this.form.get('suspendNumberDays').setValue(0);
      this.form.get('suspendNumberTimes').setValue(0);
    } else {
      this.form.get('suspendNumberDays').setValue(null);
      this.form.get('suspendNumberTimes').setValue(null);
    }
  }

  clearPriceOnSaleCampain() {
    this.Model.variants.forEach(m => {
      m.priceOnSaleCampain = null;
    });
    this.clearDates();
  }

  clearDates() {
    this.Model.saleCampainStartDate = null;
    this.Model.saleCampainEndDate = null;
  }

  addingDates() {
    let startDate = moment().add('day', 1).hours(0).minutes(0).seconds(9);
    let endDate = moment().add('day', 8).hours(23).minutes(59).seconds(9);

    this.Model.saleCampainStartDate = moment(startDate).valueOf();
    this.Model.saleCampainEndDate = moment(endDate).valueOf();
  }

  addingPriceOnSaleCampain() {
    this.Model.variants.forEach(m => {
      m.priceOnSaleCampain = m.price;
    });
  }

  modelTemplate(): Promise<ISubscription> {
    const result: ISubscription = {
      id: null,
      name: '',
      description: '',
      group: '',
      clientSubscriptionLimit: 0,
      toDateCorrection: 0,
      daysUntilActivation: 0,
      saleCampainStartDate: null,
      saleCampainEndDate: null,
      sortOrder: '',
      entityStatus: EntityStatus.published,
      activationType: SubscriptionActivationType.firstClassVisit,
      courses: [],
      clientSubscriptionCount: 0,
      isSoldSubscriptionsUpdate: false,
      progressiveDiscountEnabled: null,
      medias: {
        images: [],
        videos: []
      },
      variants: ((vars) => {
        const res = [];
        for (const v of vars) {
          const newVar: IPlanVariant = {
            duration: 1,
            durationUnit: IDurationUnit[IDurationUnit.month],
            price: 0,
            priceOnSaleCampain: 0,
            visitCount: v,
            entityStatus: EntityStatus.published,
            id: null,
            title: null
          };
          res.push(newVar);
        };
        return res;
      })([1])
      // Здесь добавляем варианты цен абонементов. Передаем один вариант с кол-вом занятий = 1. Можем передать любые варианты. Безлимит = 10000.
    };

    return Promise.resolve(result);
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: [this.Model.name, [NotEmpty()]],
      description: [this.Model.description, []],
      group: [this.Model.group, []],
      sortOrder: [this.Model.sortOrder, []],
      visibleOnSaleCampain: [this.visibleOnSaleCampain, []],
      visibleOnSuspend: [this.visibleOnSuspend, []],
      suspendNumberDays: [this.Model.suspendNumberDays, []],
      suspendNumberTimes: [this.Model.suspendNumberTimes, []],
      progressiveDiscountEnabled: [this.Model.progressiveDiscountEnabled, []],
      entityStatus: [this.Model.entityStatus, [Validators.required]],
      medias: [this.Model.medias, []],
      courses: [this.Model.courses, [Validators.required]],
      variants: [this.Model.variants],
      activationType: [this.Model.activationType, [Validators.required]],
      clientSubscriptionLimit: [this.Model.clientSubscriptionLimit, []],
      toDateCorrection: [this.Model.toDateCorrection, []],
      daysUntilActivation: [this.Model.daysUntilActivation, []]
    });
    if (this.Model.name !== null) {
      this.Model.name = this.Model.name.trim()
    }

    this.visibleOnSaleCampain = this.Model.saleCampainStartDate ? true : false;
    this.visibleOnSuspend = this.Model.suspendNumberDays ? true : false;
  }

  openImage(event: any) {
    this.mediaService.saveRedirectUrl();
    var url = this.contextService.makeContextUrl('image/' + event);
    this.router.navigate([url]);
  }

  public _onToDateCorrectionBlur(event) {
    if (!event.srcElement.value)
      this.form.get('toDateCorrection').setValue(0);
  }

  public _onDaysUntilActivation(event) {
    if (!event.srcElement.value)
      this.form.get('daysUntilActivation').setValue(0);
  }

  public changeActivationType(event) {
    if (event == 'fixeddates')
      this.form.get('daysUntilActivation').setValue(0);
  }

  public async finishEdit(isDraft?: boolean) {
    if (this.form.get('suspendNumberDays').value === 0 || this.form.get('suspendNumberTimes').value === 0) {
      this.form.get('suspendNumberDays').setValue(null);
      this.form.get('suspendNumberTimes').setValue(null);
    }
    this.Model.isSoldSubscriptionsUpdate = this.Model.clientSubscriptionCount
      && this.isCoursesChanged()
      && await this.confirmSoldSubsUpdate();
    if (isDraft) {
      this.Model.entityStatus = EntityStatus.draft;
      super.finishEdit(false);
      return;
    }

    super.finishEdit(true);
  }

  private isCoursesChanged(): boolean {
    return this.initCourses.length !== this.Model.courses.length
      || this.initCourses.some(x => !this.Model.courses.some(y => y.id === x.id))
      || this.Model.courses.some(x => !this.initCourses.some(y => y.id === x.id))
      ;
  }

  private confirmSoldSubsUpdate(): Promise<boolean> {
    return new Promise<boolean>(resolve =>
      this.alertsService.showConfirm({
        header: 'У клиентов клуба есть действующие абоненты',
        message: 'Применить изменения по доступным видам занятий к действующим абонементам?',
        callbackEsc: () => resolve(false),
        buttons: [{
          title: 'Да, применить',
          callback: () => resolve(true),
        }, {
          title: 'Не применять',
          callback: () => resolve(false),
        }]
      })
    );
  }
}
