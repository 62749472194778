<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание мероприятия</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование мероприятия</span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="close()">Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <div class="row" *ngIf="Model.id" @animation>
    <div class="col-xs-12">
      <div class="short-info">
        <ul>
          <li>Название: <b>{{Model.name}}</b></li>
          <li>Начало акции: <b>{{toDate(Model.timeFrom)}}</b></li>
          <li>Окончание акции: <b>{{toDate(Model.timeTo)}}</b></li>
          <li>Доступно для всех городов: <b>{{Model.isGlobal ? 'Да' : 'Нет'}}</b></li>
          <li *ngIf="Model.locationText">Место проведения: <b>{{Model.locationText}}</b></li>
          <li *ngIf="Model.eventType">Тип мероприятия: <b>{{Model.eventType}}</b></li>
        </ul>
        <button type="button" (click)="setShowDetails()">{{!isShowDetails ? '[Показать]' : '[Скрыть]'}}</button>
      </div>
    </div>
  </div>

  <form [formGroup]="form" class="row">
    <ng-container *ngIf="isShowDetails">
    <div class="col-xs-12">
      <div class="form-group" myScrollTo>
        <div class="box-title">Название</div>
        <div class="row__field">
          <app-form-text-box formControlName="name"></app-form-text-box>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="form-group" myScrollTo>
        <div class="row__field">
          <input formControlName="isGlobal" type="checkbox" class="checkbox__slide" id="isGlobal">
          <label for="isGlobal" class="checkbox__slide_label">Доступно для всех городов</label>
        </div>
      </div>
    </div>
    <div class="clearfix hidden-sm-up"></div>

    <style>
      .datesBlock {
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;
        margin-top: .5em;
      }
    </style>
    <div class="datesBlock">
      <div class="form-group" style="margin-right: 20px;" myScrollTo>
        <div class="row__field">
          <div class="box-title">Начало акции</div>
          <app-date-time-picker [(date)]="this.Model.timeFrom" [isBindingTimeZone]="true" mode="daytime">
          </app-date-time-picker>
        </div>
      </div>
      <div class="form-group" myScrollTo>
        <div class="row__field">
          <div class="box-title">Окончание акции</div>
          <app-date-time-picker [(date)]="this.Model.timeTo" [isBindingTimeZone]="true" mode="daytime">
          </app-date-time-picker>
        </div>
      </div>
    </div>

    <div class="col-xs-12">
      <div class="form-group" myScrollTo>
        <div class="box-title">
          <span>Способы связи</span>
        </div>
        <div class="row__field">
          <app-club-event-contacts-table #contactsTable formControlName="contacts"></app-club-event-contacts-table>
        </div>
      </div>
    </div>

    <div class="col-xs-12">
      <div class="form-group" myScrollTo>
        <div class="box-title">Краткое описание</div>
        <div class="row__field">
          <div class="texteditor-wrap" >
            <textarea formControlName="shortDescription" maxLength="402"></textarea>
            <span *ngIf="form.get('shortDescription').invalid" >
                <span class="validation-message" *ngIf="form.get('shortDescription').errors.maxlength">
                  Краткое описание не должно
                  превышать {{form.get('shortDescription').errors.maxlength.requiredLength}} символов.
                </span>
              </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="form-group" myScrollTo>
        <div class="box-title">Описание</div>
        <div class="row__field">
          <app-form-text-editor formControlName="description"></app-form-text-editor>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-4">
      <div class="form-group" myScrollTo>
        <div class="box-title">Место проведения</div>
        <div class="row__field">
          <app-form-text-box formControlName="locationText"></app-form-text-box>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-4">
      <div class="form-group" myScrollTo>
        <div class="box-title">Карта - широта</div>
        <div class="row__field">
          <app-form-text-box formControlName="locationLat" type="number"></app-form-text-box>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-4">
      <div class="form-group" myScrollTo>
        <div class="box-title">Карта - долгота</div>
        <div class="row__field">
          <app-form-text-box formControlName="locationLon" type="number"></app-form-text-box>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-4">
      <div class="form-group" myScrollTo>
        <div class="box-title">Тип мероприятия</div>
        <div class="row__field">
          <app-form-select formControlName="eventType" [options]="eventTypes"></app-form-select>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-8">
      <div class="form-group" myScrollTo>
        <div class="box-title">Особые условия</div>
        <div class="row__field">
          <app-form-text-box formControlName="specialConditions"></app-form-text-box>
        </div>
      </div>
    </div>

    <div class="col-xs-12 datesBlock">
      <div class="form-group" style="margin-right: 20px;">
        <div class="box-title">Указать время продажи билетов</div>
        <div class="row__field">
          <input type="checkbox" [(ngModel)]="_salePeriodOn" (change)="_salePeriodOnChangeHandler()" class="checkbox__slide"
            formControlName="salePeriodOn" id="salePeriodOn">
          <label for="salePeriodOn" class="checkbox__slide_label"></label>
        </div>
      </div>
      <div class="form-group" style="margin-right: 20px;" myScrollTo [hidden]="!_salePeriodOn">
        <div class="row__field">
          <div class="box-title">Старт продажи</div>
          <app-date-time-picker [(date)]="this.Model.saleTimeFrom" mode="daytime">
          </app-date-time-picker>
        </div>
      </div>
      <div class="form-group" myScrollTo [hidden]="!_salePeriodOn">
        <div class="row__field">
          <div class="box-title">Заввершение продажи</div>
          <app-date-time-picker [(date)]="this.Model.saleTimeTo" mode="daytime">
          </app-date-time-picker>
        </div>
      </div>
    </div>

    <div class="col-xs-12">
      <div class="form-group" myScrollTo>
        <div class="box-title">
          <span>Билеты</span>
          <a class="box-link" (click)="tickectsTable.addTicket()">Добавить</a>
        </div>
        <div class="row__field">
          <app-club-event-tickets-table #tickectsTable formControlName="ticketVariants"></app-club-event-tickets-table>
        </div>
      </div>
    </div>

    <div class="col-xs-12">
      <div class="form-group" myScrollTo>
        <div class="box-title">
          <span>Обязательные данные</span>
          <a class="box-link" (click)="reqfildsTable.addField()">Добавить</a>
        </div>
        <div class="row__field">
          <app-club-event-reqfilds-table #reqfildsTable formControlName="requiredTicketFields"></app-club-event-reqfilds-table>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-md-6">
      <div class="form-group" myScrollTo>
        <div class="box-title">Ссылка с отчетом о мероприятии</div>
        <div class="row__field">
          <app-form-text-box formControlName="eventReport"></app-form-text-box>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-md-6">
      <div class="form-group" myScrollTo>
        <div class="box-title">Заголовок ссылки с отчетом о мероприятии</div>
        <div class="row__field">
          <app-form-text-box formControlName="eventReportTitle"></app-form-text-box>
        </div>
      </div>
    </div>

    <div class="col-xs-12">
      <div class="form-group">
        <div class="row__field">
          <app-gallery-cva [ngModel]="Model.medias" [multipleUpload]="true" (ngModelChange)="SaveMedias ? fastSaveMedias() : ''" formControlName="medias"
            [title]="'Фотогалерея'" (onItemOpen)="openImage($event)"></app-gallery-cva>
        </div>
      </div>
    </div>
    </ng-container>
  </form>

  <div class="row">
    <div class="col-xs-12">
      <div class="row" *ngIf="isEditAvailable">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit()" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span>Сохранить</span>
                  <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <!-- <div class="clearfix hidden-md-up"></div> -->

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-link" (click)="delete()">
                  <span app-key-help [key]="'deleteText'"></span>
                  <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <ng-container *ngIf="Model.id != null">
    <hr />
    <div class="row">
      <div class="col">
        <button class="btn btn-link" (click)="childrenClick()">Перейти к вложенным мероприятиям</button>
      </div>
    </div>
  </ng-container>

<!----------------УЧАСТНИКИ--------------------->
<div class="content__body" *ngIf="!clubEventBookingsParticipantIsEmpty">
  <span style="margin-bottom: 20px" class="content__header-title-value content__header-title-value_default">
    Участники
  </span>
  <div>
    <div class="row">
    <div class="col-md-6 col-xs-6">
      <div>
        <input type="text" class="input__text input__text--long form-control" placeholder="Поиск" [ngModel]="searchString" (ngModelChange)="applyFilterInput($event)">
        <button class="clear" matSuffix mat-icon-button (click)="clearFilterInput()" [disabled] = "!searchString">
          <mat-icon>{{ searchString == null || searchString.length == 0 ? 'search' : 'close' }}</mat-icon>
        </button>
      </div>
    </div>
    <span class="col-md-5 pull-right">
      <div class="form-group pull-right">
        <button class="btn btn-outline btn-small" (click)="_exportParticipants()">
          <div class="export-exel">
            <span class="btn-primary__desc">
              Выгрузить в Excel
            </span>
            <span class="sp sp-download"></span>
          </div>
        </button>
      </div>
    </span>
    </div>
  </div>
  <div class="content__body">
    <ng-container>
      <div class="clubrooms-container">

        <div class="table-scroll">
          <mat-table class="table-width" [dataSource]="dataSource | splitMatTableData | async" [trackBy]="trackByFn" matSort>
            <ng-container >
              <ng-container [matColumnDef]="item.name" *ngFor="let item of tableColumns; let i = index;">
                <mat-header-cell class="th-left" [ngClass]="{'th-right': AlignRight(item.name)}" *matHeaderCellDef [style.border-bottom]="(header['_sort'].active == item.name && header['_sort'].direction) ?'3px solid #00C168':''">
                  <span mat-sort-header #header>{{item.displayName}}</span>

                  <button mat-icon-button class="btn filter-button" [matMenuTriggerFor]="menu">
                    <mat-icon [style.color]="(filteredValues[item.name] == selectButtonFilter[item.name]) ?'#00C168':''">filter_list_alt</mat-icon>
                  </button>

                  <mat-menu #menu>
                    <div mat-menu-item class="menu-title" [disableRipple]="true"  (click)="$event.stopPropagation();">
                      {{item.displayName}}
                    </div>
                    <div class="check-box-filter" mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation();">
                      <mat-selection-list [(ngModel)]="selectModel[item.name]" (selectionChange)="onChangeSelect(selectModel, item.name)">
                        <mat-list-option *ngFor="let shoe of filterSelectObj[i].options" checkboxPosition="before" [value]="shoe">
                          {{shoe}}
                        </mat-list-option>
                      </mat-selection-list>
                    </div>
                    <div class="mat-menu-width" mat-menu-item [disableRipple]="true">
                      <div class="button-group-menu">
                        <button class="mat-button mat-button-menu" mat-raised-button (click)="clearColumn(item.name)">Очистить</button>
                        <button class="mat-button mat-button-menu" mat-raised-button (click)="applyFilterColum(selectFilter, item.name)">Найти</button>
                      </div>
                    </div>
                  </mat-menu>
                </mat-header-cell>
                <mat-cell class="th-left" [ngClass]="{'th-right': AlignRight(item.name)}" *matCellDef="let element" [innerHtml]="element[item.name]"></mat-cell>
              </ng-container>
            </ng-container>
            <mat-header-row class="border-row" *matHeaderRowDef="tableColumnNames"></mat-header-row>
            <mat-row class="table-summ border-row" *matRowDef="let row; columns: tableColumnNames;"></mat-row>
          </mat-table>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!----------------НЕ-ВЫБРАНО--------------------->
<div *ngIf="!clubEventBookingsNotSetIsEmpty">
  <div class="content__body">
    <ng-container>
      <p class="box-title">Заинтересованных в общем: {{clubEventBookingsNotSet.length}}</p>
    </ng-container>
  </div>
</div>
</div>
