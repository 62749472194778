<header class="content__header">
  <div class="content__header-title">
    <div class="content__back">
      <span class="left-arrow" (click)="close()">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngIf="!reportInfo">
        Аналитика
      </span>
      <span class="content__header-title-value" *ngIf="reportInfo" (click)="close()">
        {{reportInfo.displayName}}
      </span>
    </div>
  </div>
</header>

<div class="content__body">
  <div class="row" *ngIf="params && params.length">
    <ng-container *ngFor="let param of filterVisibleParams(); let i = index">
      <div class="col-xs-12 col-sm-6 col-md-3 param-item">
        <div class="form-group">
          <div class="box-title title-size">{{param.displayName}}*</div>
          <div class="row__field" [ngSwitch]="param.valueType">
            <ng-container *ngSwitchCase="'Date'">
              <app-date-picker-reports [(ngModel)]="param.value" (ngModelChange)="onParamChange()" [param]="param"></app-date-picker-reports>
            </ng-container>
            <ng-container *ngSwitchCase="'Bool'">
              <app-status-picker [(ngModel)]="param.value" (ngModelChange)="onParamChange()"></app-status-picker>
            </ng-container>
            <ng-container *ngSwitchCase="'Money'">
              <input [(ngModel)]="param.value" (ngModelChange)="onParamChange()" type="number" class="input__text input__text--long">
            </ng-container>
            <ng-container *ngSwitchCase="'Number'">
              <input [(ngModel)]="param.value" (ngModelChange)="onParamChange()" type="number" class="input__text input__text--long">
            </ng-container>
            <ng-container *ngSwitchCase="'EntUser'">
              <app-user-search [(ngModel)]="param.value" (ngModelChange)="onParamChange()" [description]="userSearchDescription"></app-user-search>
            </ng-container>
            <ng-container *ngSwitchCase="'EntClub'">
              <app-club-search [(ngModel)]="param.value" (ngModelChange)="onParamChange()"></app-club-search>
            </ng-container>
            <ng-container *ngSwitchCase="'EntCoach'">
              <app-coach-select [(ngModel)]="param.value" (ngModelChange)="onParamChange()"></app-coach-select>
            </ng-container>
            <ng-container *ngSwitchCase="'EntCity'">
              <!-- TODO: выбирать города здесь -->
            </ng-container>
            <ng-container *ngSwitchDefault>
              <input [(ngModel)]="param.value" (ngModelChange)="onParamChange()" type="text" class="input__text input__text--long">
            </ng-container>
          </div>

        </div>
      </div>
      <div class="hidden-md-down" *ngIf="!((i + 1)%3)"></div>
      <div class="hidden-md-up" *ngIf="!((i + 1)%2)"></div>
    </ng-container>
  </div>

  <div class="row">
    <div class="col-md-3" *ngIf="isValid">
      <div class="form-group">
        <br>
        <button class="btn btn-primary btn-small" (click)="getReportResult()">
          <span class="btn-primary__desc">Сформировать</span>
        </button>
        <br>
        <br>
        <div class="text-danger" *ngIf="hideTitle">*За выбранный период данные не найдены. Пожа́луйста выберите другой период.</div>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="!isValid">
      <div class="form-group">
        <label class="field-validation-error static" [innerHtml]="validationStatus"></label>
        <br>
        <br>
      </div>
    </div>
  </div>

  <ng-container *ngIf="dataSource.data.length !== 0">

    <div class="box-title" *ngIf="dataSummary">Сводный отчёт</div>
    <div class="table-scroll">
      <mat-table class="table-width-consolidated" [dataSource]="dataConsolidated | splitMatTableData | async" [trackBy]="trackByFn" *ngIf="dataSummary">
        <ng-container *ngIf="consolidatedColumns && consolidatedColumns.length">>
          <ng-container [matColumnDef]="item.name" *ngFor="let item of consolidatedColumns">
            <mat-header-cell *matHeaderCellDef>{{item.displayName}}</mat-header-cell>
            <mat-cell *matCellDef="let element" [innerHtml]="element[item.name]"></mat-cell>
          </ng-container>
        </ng-container>
        <mat-header-row class="border-row" *matHeaderRowDef="consolidatedColumnNames"></mat-header-row>
        <mat-row class="table-summ border-row" *matRowDef="let row; columns: consolidatedColumnNames;"></mat-row>
      </mat-table>
    </div>
    <br>
    <br>

    <div class="row">
      <div class="col-md-6 col-xs-6">
        <div>
          <input type="text" class="input__text input__text--long form-control" placeholder="Поиск" [ngModel]="searchString" (ngModelChange)="applyFilterInput($event)">
          <button class="clear" matSuffix mat-icon-button (click)="clearFilterInput()" [disabled] = "!searchString">
            <mat-icon>{{ searchString == null || searchString.length == 0 ? 'search' : 'close' }}</mat-icon>
          </button>
        </div>
      </div>
      <div class="col-md-5 pull-right" *ngIf="displayedColumns && displayedColumns.length">
        <div class="form-group pull-right">
          <button class="btn btn-outline btn-small" (click)="export()">
            <span class="btn-primary__desc">
              Выгрузить в Excel
            </span>
            <span class="sp sp-download"></span>
<!--                        <mat-icon>file_download</mat-icon>-->
          </button>
        </div>
      </div>
    </div>

    <div class="table-scroll">

      <mat-table class="table-width" #table [dataSource]="dataSource | splitMatTableData | async" [trackBy]="trackByFn" matSort multiTemplateDataRows>
        <ng-container *ngIf="displayedColumns && displayedColumns.length">
          <ng-container [matColumnDef]="item.name" *ngFor="let item of displayedColumns; let i = index;">
            <mat-header-cell class="th-left" [ngClass]="{'th-right': AlignRight(item.name)}" *matHeaderCellDef
                             [style.border-bottom]="(header['_sort'].active == item.name && header['_sort'].direction) ?'3px solid #00C168':''">
              <span mat-sort-header #header>{{item.displayName}}</span>

              <button mat-icon-button class="btn filter-button" [matMenuTriggerFor]="menu">
                <mat-icon [style.color]="(filteredValues[item.name] == selectButtonFilter[item.name]) ?'#00C168':''">filter_list_alt</mat-icon>
              </button>

              <mat-menu #menu>
                <div mat-menu-item class="menu-title" [disableRipple]="true"  (click)="$event.stopPropagation();">
                  {{item.displayName}}
                </div>
                <div class="check-box-filter" mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation();">
                  <mat-selection-list [(ngModel)]="selectModel[item.name]" (selectionChange)="onChangeSelect(selectModel, item.name)">
                    <mat-list-option *ngFor="let shoe of filterSelectObj[i].options" checkboxPosition="before" [value]="shoe">
                      {{shoe}}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
                <div class="mat-menu-width" mat-menu-item [disableRipple]="true">
                  <div class="button-group-menu">
                    <button class="mat-button mat-button-menu" mat-raised-button (click)="clearColumn(item.name)">Очистить</button>
                    <button class="mat-button mat-button-menu" mat-raised-button (click)="applyFilterColum(selectFilter, item.name)">Найти</button>
                  </div>
                </div>
              </mat-menu>

            </mat-header-cell>
            <mat-cell class="th-left" [ngClass]="{'th-right': AlignRight(item.name)}" *matCellDef="let element"> {{element[item.name]}} </mat-cell>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="reportName == 'CrmExpiringSubscriptions' || 'CrmSubscriptions'" matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let detail">
            <mat-table [dataSource]="dataSubtable[detail.numeric] | splitMatTableData | async" [trackBy]="trackByFn" *ngIf="detail.isExpanded" style="background-color: #f7f7f7">
              <ng-container *ngIf="displayedColumns && displayedColumns.length">
                <ng-container [matColumnDef]="item.name" *ngFor="let item of subTableColumns">
                  <mat-header-cell *matHeaderCellDef>{{item.displayName}}</mat-header-cell>
                  <mat-cell [ngClass]="{'th-right': AlignRight(item.name)}" *matCellDef="let element">{{element[item.name]}}</mat-cell>
                </ng-container>
              </ng-container>
              <mat-header-row *matHeaderRowDef="subTableColumnNames"></mat-header-row>
              <mat-row class="table-width" *matRowDef="let row; columns: subTableColumnNames;"></mat-row>
            </mat-table>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnNames"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnNames;" matRipple class="element-row"
                 [class.expanded]="row.isExpanded" (click)="expandCollapse($event, row)"></mat-row>
        <ng-container *ngIf="dataSummary">
        <mat-row *matRowDef="let row; columns: ['expandedDetail'];"
                 [@detailExpand]="row.isExpanded == true ? 'expanded' : 'collapsed'" style="overflow: hidden; background-color: #f7f7f7">
        </mat-row>
        </ng-container>
      </mat-table>

      <mat-table [dataSource]="dataSummary | splitMatTableData | async" [trackBy]="trackByFn" *ngIf="dataSummary">
        <ng-container *ngIf="displayedColumns && displayedColumns.length">
          <ng-container [matColumnDef]="item.name" *ngFor="let item of displayedColumns">
            <mat-cell [ngClass]="{'th-right': AlignRight(item.name)}" *matCellDef="let element"><b>{{element[item.name]}}</b></mat-cell>
          </ng-container>
        </ng-container>
        <mat-row class="table-width" *matRowDef="let row; columns: displayedColumnNames;"></mat-row>
      </mat-table>

    </div>

    <mat-paginator [pageSize]=fullPageSizeOptions [pageSizeOptions]=getSizeOption() showFirstLastButtons></mat-paginator>

  </ng-container>

</div>
