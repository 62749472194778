import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EditItemComponent } from 'app/common/edit-item.component';
import { IGroupChat, MemberCategory } from 'app/common/models/chat.models';
import { NotEmpty } from 'app/common/validators/required-not-empty.validator';
import { ClientsService } from '../clients/services/clients.service';
import { GroupsService } from '../groups/services/groups.service';
import { ChatService } from './chats.service';
import { ChatEditService } from './services/chat-edit.service';

@Component({
  selector: 'app-chat-edit',
  animations: [
    trigger('enterTrigger', [
      state('moveLeft', style({ transform: 'translateX(0%)' })),
      transition('void => moveLeft', [ style({ transform: 'translateX(100%)' }), animate('200ms') ]),
      state('moveRight', style({ transform: 'translateX(0%)' })),
      transition('void => moveRight', [ style({ transform: 'translateX(-100%)' }), animate('200ms') ]),
      state('init', style({ })),
      transition('void => init', [ ]),
    ])
  ],
  templateUrl: './chat-edit.component.html',
  styleUrls: ['./chat-edit.component.scss']
})
export class ChatEditComponent extends EditItemComponent<IGroupChat> {
  public static readonly componentName: string = 'ChatEditComponent';

  public groupTypeOptions = { group: 'общий', mailing: 'рассылка' };
  searchBoxValue: string;
  public groups: GroupModel[];
  memberSelectMode: boolean = false;
  members: MemberModel[];
  private clients: { id: string, userId: string, memberName: string, phone: string, groups: string[] }[];
  public admins: { unchecked?: boolean, members: AdminModel[] } = { members: [] };

  @ViewChild('searchBox') private searchBoxRef: ElementRef;
  memberSearchList: MemberModel[] = [];
  memberSearchMode: boolean = false;

  private virtualGroups = {
    clientsClub: 'Все клиенты',
    clientsWithActiveSubscription: 'Клиенты с действующими абонентами',
    clientsWithExpiredSubscription: 'Клиенты с истекшими абонентами'
  };

  constructor(
    protected groupService: GroupsService,
    protected clientService: ClientsService,
    protected chatService: ChatService,
    protected service: ChatEditService,
    protected injector: Injector
  ) {
    super(injector, service);
  }

  public modelTemplate(): Promise<IGroupChat> {
    const result: IGroupChat = {
      chat: undefined,
      id: null,
      title: null,
      groupType: 'group',
      isReadOnly: false,
      groupsIds: [],
      groupsCategories: [],
      excludedUsersIds: [],
      fixedUsersIds: [],
      adminsIds: [],
      members: [],
    };

    return Promise.resolve(result);
  }

  public buildForm() {
    this.form = this.formBuilder.group({
      title: [this.Model.title, [NotEmpty()]],
      groupType: [this.Model.groupType, [NotEmpty()]],
      isReadOnly: [this.Model.isReadOnly],
    });
  }
  fieldMessages = {
    title: {
      NotEmpty: 'Это поле обязательно',
    },
  };

  public afterModelInit() {
    this.getEntities()
      .then(() => {
        if (this.Model && this.Model.chat) {
          this.Model.id = this.Model.chat.id;
          this.Model.title = this.Model.chat.title;
          this.Model.groupType = this.Model.chat.groupType === 'notGroup' ? undefined : this.Model.chat.groupType;
          this.Model.isReadOnly = this.Model.chat.isReadOnly;
          this.groups.forEach(a => {
            a.attached = this.Model.groupsIds.indexOf(a.id) >= 0 || this.Model.groupsCategories.indexOf(a.category) >= 0;
          });
          this.members = this.Model.members
            .map(a => {
              const client = this.clients.find(b => b.userId == a.userId);
              return {
                ...client,
                groups: this.getGroups(client.groups),
                attached: a.detachedAt == null,
                fixed: !a.isGroupMember,
              };
            })
            .sort((a, b) => a.memberName.localeCompare(b.memberName));
        }
      });
  }

  public finishEdit() {
    if (this.Model.groupType != 'mailing')
      this.Model.isReadOnly = false;
    this.Model.groupsIds = this.groups
      .filter(x => x.attached && !x.category).map(x => x.id);
    this.Model.groupsCategories = this.groups
      .filter(x => x.attached && x.category).map(x => x.category);
    this.Model.excludedUsersIds = this.members
      .filter(a => a.fixed && !a.attached)
      .map(a => a.userId);
    this.Model.fixedUsersIds = this.members
      .filter(a => a.fixed && a.attached)
      .map(a => a.userId);
    super.finishEdit();
  }

  public _close() {
    var url = this.chatService.redirectUrl || this.chatService.pendingUrl.slice(0, this.chatService.pendingUrl.length-2).reduce((x, y) => x + `/${y}`, '');
    this.router.navigate([url]);
  }

  enterTriggerState: string = 'init';

  selectedTabIndex: number = 0;
  selectedTabChanged(event: MatTabChangeEvent) {
    this.enterTriggerState = this.selectedTabIndex < event.index ? 'moveLeft' : 'moveRight';
    event.index === 2
      && this.members.forEach(x => {
        x.groups = this.getGroups(this.clients.find(a => a.userId == x.userId)?.groups);
      });
    this.selectedTabIndex = event.index;
  }

  getAttachedMembers() {
    return this.members.filter(a => a.groups || a.attached);
  }

  isMemberAttached(userId: string) {
    return this.members.find(a => a.userId == userId)?.attached;
  }

  getAdmins() { return this.members.filter(x => x.isAdmin); }

  searchFormClickOutside(event) {
    if (this.searchBoxRef.nativeElement === event.target)
      this.memberSearchMode = !this.memberSearchMode;
    else if (event.isInside === false)
      this.memberSearchMode = false;
  }

  async searchMembers() {
    const pattern = this.searchBoxValue.trim();

    if (pattern.length < 2) {
      this.memberSearchList = [];
      return;
    } else {
      let resp = await this.clientService.searchClients(pattern);
      let members = [...resp.clients ];
      members.forEach(x => {
        x.groups = this.getGroups(x.userId);
        x.attached = this.members.find(y => y.userId === x.userId)?.attached;
      });
      this.memberSearchList = members;
    }
    this.memberSearchMode = true;
  }

  attachMember(member: MemberModel) {
    // Сбрасываем все параметры поиска
    [ this.searchBoxRef.nativeElement.value, this.memberSearchMode, this.memberSearchList ] = [ '', false, [] ];

    let existed = this.members.find(x => x.userId === member.userId);
    if (existed) {
      existed.attached = true;
      existed.fixed = true;
    }
    else {
      const client = this.clients.find(a => a.userId == member.userId);
      member.attached = true;
      member.fixed = true;
      member.groups = this.getGroups(client?.groups);
      member.memberName = client.memberName;
      this.members.push(member);
      this.members = this.members.sort((a, b) => a.memberName.localeCompare(b.memberName));
    }
  }

  detachMember(member: MemberModel) {
    // Сбрасываем все параметры поиска
    [ this.searchBoxRef.nativeElement.value, this.memberSearchMode, this.memberSearchList ] = [ '', false, [] ];

    member.attached = false;
    let ind = this.members.findIndex(x => x.userId === member.userId);
    if (ind < 0) {
      this.members.push(member);
    }
    else if (!this.members[ind].groups) {
      this.members.splice(ind, 1);
    }
  }

  detachChecked() {
    this.memberSelectMode = false;
    this.members = this.members.filter(a => !a.checked || a.groups);
    this.members.filter(x => x.checked).forEach(x => { x.checked = false; x.fixed = true; x.attached = false; });
  }

  restoreChecked() {
    this.memberSelectMode = false;
    this.members.filter(x => x.checked).forEach(x => { x.checked = false; x.groups && (x.fixed = false); x.attached = true; });
  }

  assignAdminChecked() {
    this.memberSelectMode = false;
    this.members.filter(x => x.checked).forEach(x => { x.checked = false; x.isAdmin = !x.isAdmin; });
  }

  onGroupChange(event: Event) {
    let id = event.target['id'].substr(2);
    let group = this.groups.find(x => x.id === id);
    group && (group.checked = event.target['checked']);
  }

  isAnyGroupChecked() { return this.groups.some(x => x.checked); }
  getCheckedGroupsCount() { return this.groups.filter(x => x.checked).length; }
  isAnyGroupCheckedOrAttached() { return this.groups.some(x => x.checked || x.attached) }
  isAnySpecialGroupCheckedOrAttached() { return this.groups.some(x => (x.checked || x.attached) && x.category); }
  checkGroup(group: GroupModel) { group.checked = !group.checked; }
  attachChecked(groups: GroupModel[]) {
    groups.filter(x => x.checked).forEach(x => {
      x.checked = false;
      x.attached = true;
      this.clients
        .filter(a => a.groups && a.groups.indexOf(x.id) >= 0)
        .forEach(a => {
          let existed = this.members.find(x => x.userId === a.userId);
          if (existed) {
            existed.attached = true;
          }
          else {
            let member = Object.assign({
              attached: true,
              fixed: false,
              groups: this.getGroups(a.groups),
            }, a);
            this.members.push(member);
            this.members = this.members.sort((a, b) => a.memberName.localeCompare(b.memberName));
          }
        });
    });
  }
  detachGroup(group: GroupModel) {
    group.attached = false;
    let excl = this.clients
      .filter(a => a.groups && a.groups.indexOf(group.id) >= 0)
      .map(a => {
        let existed = this.members.find(x => x.userId === a.userId);
        existed.groups = this.getGroups(a.groups);
        return existed.fixed || this.groups.some(b => a.groups.indexOf(b.id) >= 0 && b.attached) ? null : a.userId;
      });
    this.members = this.members.filter(a => excl.indexOf(a.userId) < 0);
  }

  getAttachedGroups() { return this.groups.filter(x => x.attached); }

  isAnyMemberChecked() { return this.members.some(x => x.checked); }
  checkMember(member: MemberModel) { member.checked = !member.checked; }
  deleteRule(member: MemberModel) { this.members = this.members.filter(x => x.userId !== member.userId); }

  changeEmploye(employe: AccountModel) { employe.checked = !employe.checked; }

  dismissAdmin(member: AdminModel) {
    member.isAdmin = !member.isAdmin;
  }

  getGrouppedClients() {
    let grouppedClients = this.groups
      .filter(x => x.checked)
      .map(x => x.members);
    return grouppedClients.length ? grouppedClients.reduce((p, c) => [ ...p, ...c ]) : [];
  }

  goToClient(client) {
    const url = this.contextService.makeContextUrl(`clubclients/${client.id}`);
    this.router.navigate([url]);
  }

  goToEmploye(employe) {
    const url = this.contextService.makeContextUrl(`clubusers/${employe.id}`);
    this.router.navigate([url]);
  }

  private async getEntities() {
    let resp = await this.chatService.requestEntities();
    resp.clientGroups
      .filter(a => !a.id)
      .forEach(a => { a.id = a.category[0].toUpperCase() + a.category.slice(1, a.category.length); a.name = this.virtualGroups[a.category] });
    this.groups = resp.clientGroups;
    this.clients = resp.clients;
    this.members = [];
  }

  private getGroups(groupsIds: string[]): string {
    if (!groupsIds || !groupsIds.length)
      return '';
    let ref = this.groups
      .filter(x => x.attached && groupsIds.indexOf(x.id) >= 0)
      .map(x => x.name);
    return ref.length ? ref.reduce((p, c) => p + ', ' + c) : '';
  }
}


class AccountModel {
  checked?: boolean;
  isAdmin?: boolean;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
}

class GroupModel {
  checked?: boolean;
  attached?: boolean;
  id: string;
  name: string;
  members?: MemberModel[];
  disabled?: boolean;
  category?: MemberCategory;
}

class MemberModel {
  checked?: boolean;
  isAdmin?: boolean;
  memberName: string;
  phone: string;
  userId: string;
  systemRegisteredAt?: string;
  primaryPhotoUrl?: string;

  isGroupMember?: boolean;
  groups?: string;
  attached?: boolean;
  fixed?: boolean;
}

class AdminModel {
  isAdmin?: boolean;
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
}
