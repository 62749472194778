import {Component, OnInit} from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {Subject} from 'rxjs';
import {IRoutingParams} from '../../common/models/context.model';
import {CertificateStatus, ICertificate, ICertificateList} from '../../common/models/certificate.model';
import {ContextService} from '../../common/services/context.service';
import {ApiV2Service} from '../../common/services/api-v2.service';
import {Location} from '@angular/common';
import {FormControl} from '@angular/forms';
import {AlertsService} from '../../common/components/alerts/services/alerts.service';
import { AuthService } from 'app/common/services/auth.service';

@Component({
  selector: 'app-club-certificates',
  templateUrl: './club-certificates.component.html',
  styleUrls: ['./club-certificates.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.4s', style({ opacity: 1 })),
      ])
    ]),
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('isExpanded', style({ height: '*', visibility: 'visible' })),
      transition('isExpanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class ClubCertificatesComponent implements OnInit {
  public static readonly componentName: string = 'ClubCertificatesComponent';
  protected ngUnsubscribe: Subject<void> = new Subject();

  public context: IRoutingParams;
  public searchTerm = new FormControl();
  public certificates: ICertificateList;
  public isLoading = false;

  constructor(
    private contextService: ContextService,
    private _location: Location,
    private alertsService: AlertsService,
    private apiV2: ApiV2Service,
    public authService: AuthService
  ) { }

  async ngOnInit(): Promise<any> {
    // -------------------- Загрузка таблицы "Сертификаты"
    this.context = await this.contextService.getRoutingParams();
    if (this.context) {
      this.uploadList();
    }
  }
  // ---------------------- Методы для вкладки "Сертификаты"
  async uploadList(): Promise<void> {
    await this.apiV2.certificates.get({ clubId: this.context.clubId, page: 1, query: this.searchTerm.value }).then(async (certificates) => {
      if (certificates) {
        this.certificates = certificates;
        this.isLoading = true;
      }
    });
  }

  close() { this._location.back(); }

  activateCertificate(cert: ICertificate) {
    if (cert.status === CertificateStatus.Suspended || cert.status === CertificateStatus.Expired) {
      this.alertsService.showConfirm({
        header: `Вы уверены что хотите активировать сертификат<br />${cert.certificateNumber}?`,
        message: '',
        buttons: [{
          title: 'Да',
          callback: async () => {
            await this.apiV2.certificates.changeStatusCertificate({ certId: cert.id, status: CertificateStatus.Active, page: this.certificates.currentPage, query: this.searchTerm.value }).then(async (resp) => {
              if (resp) { cert.status = CertificateStatus.Active; cert.isTouch = !cert.isTouch; setTimeout(() => cert.isTouch = !cert.isTouch, 10000) }
            });
          }
        }, {
          title: 'Отмена'
        }]
      });
    }
  }

  expiredCertificate(cert: ICertificate) {
    if (cert.status === CertificateStatus.Suspended) {
      this.alertsService.showConfirm({
        header: 'Вы уверены что хотите погасить сертификат?',
        message: '',
        buttons: [{
          title: 'Погасить',
          callback: async () => {
            await this.apiV2.certificates.changeStatusCertificate({ certId: cert.id, status: CertificateStatus.Expired, page: this.certificates.currentPage, query: this.searchTerm.value }).then(async (resp) => {
              if (resp) { cert.status = CertificateStatus.Expired; }
            });
          }
        }, {
          title: 'Отмена'
        }]
      });
    }
  }

  async showNextPage(): Promise<void> {
    this.certificates.currentPage++;
    await this.apiV2.certificates.get({ clubId: this.context.clubId, page: this.certificates.currentPage, query: this.searchTerm.value }).then(async (certificates) => {
      if (certificates) {
        this.certificates.currentPage = certificates.currentPage;
        this.certificates.result = this.certificates.result.concat(certificates.result)
      }
    });
  }

  copyCertificateNumber(value: string) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (value));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.alertsService.alert.next({
      type: 'info',
      message: 'Код скопирован',
      header: null,
      position: 'top',
      timeout: 2000
    });
  }
}
