import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';

import {IReportInfo, IReportParameter, IReportTableColumn, ReportValueType} from 'app/common/models/reports.model';
import {TipsService} from 'app/common/services/tips.service';
import {CityService} from 'app/common/services/city.service';
import { ClientsService } from 'app/components/clients/services/clients.service';
import {ClubAnalyticsService} from 'app/components/clubanalytics/services/club-analytics.service';
import {ICityModel} from 'app/common/models/city-model';
import {filter, take} from 'rxjs/operators';
import {AlertsService} from '../../../common/components/alerts/services/alerts.service';
import {ExcelService} from '../../../common/services/excel.service';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {FormControl} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-clubanalytics-detail',
  templateUrl: './club-analytics-detail.component.html',
  styleUrls: ['./club-analytics-detail.component.scss'],
  providers: [
    ClubAnalyticsService
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('isExpanded', style({ height: '*', visibility: 'visible' })),
      transition('isExpanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClubAnalyticsDetailComponent implements OnInit {
  public static readonly componentName: string = 'ClubAnalyticsDetailComponent';

  public reportInfo: IReportInfo;
  public params: IReportParameter[] = null;
  public displayedColumns: IReportTableColumn[];
  public displayedColumnNames: string[];
  public dataSource: MatTableDataSource<any>;
  public reportName: string;
  public isValid: boolean;
  public validationStatus: string;
  public userSearchDescription: string;
  public dataSummary: any;
  public filterSelectObj = [];
  public dataConsolidated: {}[];
  public consolidatedColumnNames: string[];
  public consolidatedColumns: {}[];
  public dateToday: FormControl = new FormControl(moment());
  public selectedColumnFilter = '';
  public searchString = '';
  public filteredValues =  {};
  public selectModel: any = {};
  public selectFilter: any = {};
  public selectButtonFilter: {};
  public fullPageSizeOptions = null;
  public hideTitle: any;
  public dataSubtable: {}[][];
  public subTableColumnNames: string[];
  public subTableColumns: {}[];
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatPaginator) set MatPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  constructor(
    public serviceHistor: ClientsService,
    private router: Router,
    private route: ActivatedRoute,
    private service: ClubAnalyticsService,
    private cityService: CityService,
    private excelService: ExcelService,
    private sanitized: DomSanitizer,
    private alertsService: AlertsService,
    tipsService: TipsService
  ) {
    this.userSearchDescription = tipsService.getStringByKey('userSearch');
  }

  ngOnInit() {
    this.route.params.subscribe(res => {
      if (res.name) {
        this.reportName = res.name;
        this.service.getReportByName(this.reportName).then(report => {
          this.reportInfo = report;

          report.parameters.forEach(param => {
            param.value = null;

            if (param.valueType === ReportValueType.EntCity) {
              param.isHidden = true;
              this.cityService.currentCity.pipe(
                filter<ICityModel>(Boolean),
                take(1))
                .subscribe(city => {
                  param.value = city.id;
                  this.onParamChange();
                })
            }
          });

          this.params = report.parameters;
          this.dataSource = new MatTableDataSource([]);
        });
      }
    });
  }

  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(String(obj[key])) && obj[key] != '') {
        uniqChk.push(String(obj[key]));
      }
      return obj;
    });
    return uniqChk;
  }
  AdditionDataSummary() {
    let PriceFinal = 0;
    let Paid = 0;
    this.dataSource.filteredData.filter((o) => {
      if (o.PriceFinal) {
        PriceFinal += Number(o.PriceFinal);
      }
        Paid += Number(o.Paid);
    });
    this.dataSummary.filter((o) => {
      o.PriceFinal = PriceFinal.toFixed(2);
      o.Paid = Paid.toFixed(2);
    });
  }

  public filterVisibleParams() {
    return this.params.filter(param => !param.isHidden);
  }

  public onParamChange() {
    setTimeout(() => {
      this.isValid = true;
      this.validationStatus = '';
      this.params.reduce((accum, param) => {
        if (!param.value && param.isRequired) {
          this.isValid = false;
          this.validationStatus += `необходимо заполнить поле '${param.displayName}'<br>`;
        }
        if (param.editor === 'PeriodSelectorFrom' || param.editor === 'PeriodSelectorTo') {
          accum[param.editor] = param.value;
          if (accum['PeriodSelectorFrom'] && accum['PeriodSelectorTo']) {
            if (moment(accum['PeriodSelectorTo'], 'DD.MM.YYYY').valueOf() - moment(accum['PeriodSelectorFrom'], 'DD.MM.YYYY').valueOf() < 0) {
              this.isValid = false;
              this.validationStatus += 'Дата конца периода не может быть раньше даты начала периода<br>';
            }
          }
        }
        return accum;
      }, {});
    }, 1);
  }

  public getReportResult() {
    const req = this.params.reduce((accum, curr) => {
      accum[curr.name] = curr.value;
      return accum;
    }, {});

    this.service.getReportResult(this.reportName, req)
      .then(result => {
        this.displayedColumns = result.reportInfo.tableColumns;
        this.displayedColumnNames = result.reportInfo.tableColumns.map(item => item.name);
        this.dataSource.data = result.resultGroups.reduce((arr, item) => [...arr, ...item.rows], []);
        this.dataSource.data.filter((item, index) => item.id = index)
        this.dataSource.filterPredicate = this.customFilterPredicate();
        const dataSource_sum = result.resultGroups[0].summary;

        if (this.dataSource.data.length === 0) {
          this.hideTitle = true
        } else {
          this.hideTitle = false

          if (this.reportName == 'CrmExpiringSubscriptions') {
            this.dataSubtable = []
            this.subTableColumnNames = ['startDate', 'currentStatusText'];
            this.subTableColumns = [{name: 'startDate', displayName: 'Время начала'}, {name: 'currentStatusText', displayName: 'Статус посещения'}];
            for (const i in this.dataSource.data) {
              if (!this.dataSource.data[i].Visiting || this.dataSource.data[i].Visiting.length == 0) {
                this.dataSource.data[i].Visiting = [];
                this.dataSource.data[i].Visiting.push({class: {startTime: null}, currentStatusText: 'Н/Д'});
              }
              const Visiting = this.dataSource.data[i].Visiting
              if (this.dataSource.data[i].Paid == null) {this.dataSource.data[i].Paid = 0}
              this.dataSource.data[i].Paid = this.dataSource.data[i].Paid.toFixed(2)
              this.dataSubtable.push([])
              this.dataSource.data[i].numeric = Number(i)
              let countVisit = 0
              for (const j in  Visiting) {
                this.dataSubtable[i].push({startDate:  Visiting[j].class.startTime ? this.transformDate(Visiting[j].class.startTime) : 'Н/Д', currentStatusText:  Visiting[j].currentStatusText})
                const visitStatus =  Visiting[j].classVisitStatus
                const visitStatusArr = ['Booked', 'WaitingBookingConfirmation', 'VisitedByClub', 'VisitedByClubConflict', 'CancelledByUserConflict',
                                        'Visited', 'MissedByUserConflict', 'MissedByClub', 'MissedByClubConflict', 'MissedByUser', 'Missed']
                if (visitStatusArr.includes(visitStatus)) {
                  countVisit++
                }
              }
              for (const status in  Visiting) {
                if ( Visiting[status].classVisitStatus == 'Visited' || Visiting[status].classVisitStatus == 'VisitedByClub') {
                  this.dataSource.data[i].Visiting = this.transformDate( Visiting[status].class.startTime);
                  break;
                } else {this.dataSource.data[i].Visiting = 'Нет'; }
              }
              this.dataSource.data[i].SubscriptionVariantVisitsLimit = String(countVisit) + '/' + this.dataSource.data[i].SubscriptionVariantVisitsLimit
              if ( Visiting.length == 0) {this.dataSource.data[i].Visiting = 'Нет'; }
            }
          }

          if (this.reportName == 'CrmSubscriptions') {
            this.dataSubtable = []
            this.subTableColumnNames = ['datePaid', 'summPaid', 'formPaid'];
            this.subTableColumns = [{name: 'datePaid', displayName: 'Дата оплаты'}, {name: 'summPaid', displayName: 'Сумма оплаты'}, {name: 'formPaid', displayName: 'Форма оплаты'}];
            for (const i in this.dataSource.data) {
              this.dataSubtable.push([])
              this.dataSource.data[i].numeric = Number(i)
              if (!this.dataSource.data[i].Quantity || this.dataSource.data[i].Quantity.length == 0) {
                this.dataSource.data[i].Quantity = [];
                this.dataSource.data[i].Quantity.push({paymentDate: this.dataSource.data[i].BoughtAt, amount: 'Н/Д', source: 'Н/Д'});
              }
              for (const j in this.dataSource.data[i].Quantity) {
                this.dataSubtable[i].push({id: i, datePaid:  this.dataSource.data[i].Quantity[j].paymentDate, summPaid: this.dataSource.data[i].Quantity[j].amount, formPaid: this.dataSource.data[i].Quantity[j].source})
              }
              this.dataSource.data[i].Paid = this.dataSource.data[i].Paid !== null ? this.dataSource.data[i].Paid.toFixed(2) : 'Н/Д'
              this.dataSource.data[i].PriceFinal = this.dataSource.data[i].PriceFinal.toFixed(2)
              this.dataSource.data[i].Price = this.dataSource.data[i].Price.toFixed(2)
              this.dataSource.data[i].Quantity = this.dataSource.data[i].Quantity.length
            }
          }
          // формирование масива обьектов фильтрации
          this.filterSelectObj = []
          for (const i of this.displayedColumnNames) {
            this.filterSelectObj.push({name: i, options: []});
          }
          this.fullPageSizeOptions = this.dataSource.data.length
          // переменные фильтрации
          this.filterSelectObj.filter((o) => {
            o.options = this.getFilterObject(this.dataSource.data, o.name);
            o.options.sort()
          });
          // создает значения для фильтрации
          this.filteredValues = JSON.parse(JSON.stringify(this.dataSource.data[0]));
          this.selectButtonFilter = JSON.parse(JSON.stringify(this.dataSource.data[0]));
          for (const i in this.filteredValues) {
            this.filteredValues[i] = [];
            this.selectButtonFilter[i] = null;
          }
          // суммарные данные
          if (dataSource_sum) {
            this.dataSummary = [dataSource_sum];
            this.dataSummary[0].PriceFinal = this.dataSummary[0].PriceFinal.toFixed(2)
            this.dataSummary[0].Paid = this.dataSummary[0].Paid.toFixed(2)
            this.dataSummary[0].BoughtAt = 'Итого:'
            this.dataSummary[0].id = 0;

            this.dataConsolidated = [
              {id: 0, dateFrom: this.params[0].value, dateTo: this.params[1].value, dateToday: this.dateToday.value.format('DD.MM.YYYY'), PriceFinal: this.dataSummary[0].PriceFinal, Paid: this.dataSummary[0].Paid}
            ];
            this.consolidatedColumnNames = ['dateFrom', 'dateTo', 'dateToday', 'PriceFinal', 'Paid'];
            this.consolidatedColumns = [{name: 'dateFrom', displayName: 'Дата начала'}, {name: 'dateTo', displayName: 'Дата конца'}, {name: 'dateToday', displayName: 'Дата формирования'}, {name: 'PriceFinal', displayName: 'Итог'}, {name: 'Paid', displayName: 'Оплачено'}];
          }
        }
      });
  }

  public close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public export(): any {
    const exportA: any[] = [];
    const listExport = JSON.parse(JSON.stringify(this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort)));
    listExport.forEach(item => {
      for (const i in item) {
        if (!(isNaN(Number(item[i])))) {
          item[i] = Number(item[i])
        }
      }
      const e: any = new Object();
      this.displayedColumns.forEach(f => {
        e[f.displayName] = item[f.name];
      });
      exportA.push(e)
    });

    if (exportA.length === 0) {
      this.alertsService.alert.next({
        type: 'danger',
        header: 'Ошибка экспорта',
        message: 'Отчет пустой',
        position: 'top',
        timeout: 3000
      });
      return;
    }

    this.excelService.exportAsExcelFile(
      exportA,
      `${this.reportInfo.displayName}`);
  }
  applyFilterInput(filterValue) {
    this.searchString = filterValue;
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
    if (this.dataSummary) {this.AdditionDataSummary()}
  }

  clearFilterInput() {
    this.searchString = '';
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
    if (this.dataSummary) {this.AdditionDataSummary()}
  }
  applyFilterColum(filterValue, filterColum) {
    this.filteredValues[filterColum] = filterValue;
    // tslint:disable-next-line:triple-equals
    if (this.filteredValues[filterColum] == null) {
      this.selectButtonFilter[filterColum] = null
    } else {this.selectButtonFilter[filterColum] = filterValue}
    this.selectedColumnFilter = filterColum;
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
    if (this.dataSummary) {this.AdditionDataSummary()}
  }
  clearColumn(filterColum) {
    this.filteredValues[filterColum] = [];
    this.selectModel[filterColum] = [];
    this.selectButtonFilter[filterColum] = null;
    this.selectFilter = [];
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    this.filterCheckBoxValue()
    if (this.dataSummary) {this.AdditionDataSummary()}
  }
  customFilterPredicate() {
    return(data, columnFilter: string): boolean => {
      let rowDataAsString = '';
      let isPositionAvailable = true;
      const searchString = JSON.parse(columnFilter);
      for (const colName of this.displayedColumnNames) {
        if (data[colName]) {
          rowDataAsString += data[colName] + '\n';
        }
      }
      rowDataAsString = rowDataAsString.toLowerCase();

      if (rowDataAsString.indexOf(this.searchString.toLowerCase()) === -1) {
        return false;
      }
      if (this.selectedColumnFilter === '') {
        return true;
      } else {
        for (const filterKey in searchString) {
          if (searchString[filterKey].length) {
            isPositionAvailable = isPositionAvailable && searchString[filterKey].includes(data[filterKey] && data[filterKey].toString()); /// .trim()
          }
        }
        return isPositionAvailable;
      }
    };
  }
  onChangeSelect(filterValue, filterColum) {
    this.selectFilter = filterValue[filterColum];
  }
  filterCheckBoxValue() {
    for (const selectValue in this.filteredValues) {
      if (this.filteredValues[selectValue].length === 0) {
        this.filterSelectObj.filter((o) => {
          if (o.name === selectValue) {
            o.options = this.getFilterObject(this.dataSource.filteredData, o.name);
            o.options.sort()
          }
        });
      }
    }
  }

  getSizeOption() {
    if (this.dataSource.data.length < 100) {
        return [this.fullPageSizeOptions]
    } else {
        return [50, 100, this.fullPageSizeOptions]
    }
  }

  trackByFn(index, item) {
    console.log(item)
    return item.id; // unique id corresponding to the item
  }

  AlignRight(Element) {
    switch (Element) {
      case 'Paid':
        return true
      case 'PriceFinal':
        return true
      case 'Price':
        return true
      case 'VisitedCount':
        return true
      case 'MissedCount':
        return true
      case 'VisitedRate':
        return true
    }
  }
  expandCollapse(event, row) {
    row.isExpanded = !row.isExpanded;
    // if (this.dataSource.data[0].Visiting && row.isExpanded) {
    //   this.HistorySlaps(row.Visiting.clientId, row.Visiting.subId, row.numeric)
    // }
  }
  // HistorySlaps(ClientId, SubId, numeric) {
  //   const test1 = []
  //   this.serviceHistor.history(ClientId).then(async histories => {
  //     await histories.history.forEach(history => {
  //       if (history.subscription != null) {
  //         if (history.subscription.id === SubId) {
  //           test1.push(history);
  //         }
  //       }
  //     });
  //     this.dataSubtable[numeric] = test1
  //     for (const i in test1) {
  //       this.dataSubtable[numeric][i]['class'] = this.transformDate(this.dataSubtable[numeric][i]['class'].startTime)
  //     }
  //   });
  // }
  transformDate ( value: Date ) {
    return moment(value).utc().format('DD.MM.YYYY HH:mm');
  }
}
