<div class="main-table">
  <!-- Ширина: кол-во ячеек * ширину ячеек + ширина пустой первой ячейки + кол-во отображаемых дней * ширину разделителей между ними -->
  <div class="table-responsive" style="width: max-content" [class.table-header-stick]="tableHeader.stick" [style.margin-top]="tableHeader.stick ? heightTableStick : '0'" #table>
    <div class="table-schedule">
      <div class="table-schedule__head"  style="width: max-content" [style.left.px]="tableHeader.leftScroll">
        <div class="table-schedule__row">
          <div class="table-cell"></div>
          <ng-container *ngFor="let dayM of schedule.hours[0].days; let d=index">
            <div class="table-cell day" style="{{ 'width: ' + (displayCategoryFilter(displayCategory, dayM.dayAlias).length * 178) +'px;' }}" *ngIf="d < displayExtraDaysMode">
              <div class="headItem">
                <span class="title">{{dayM.day | date: 'EEEEEE' | titlecase}}</span>
                <span class="marker">{{dayM.day | date: 'd'}}</span>
              </div>
            </div>
            <div *ngIf="d < displayExtraDaysMode" class="day-separate"></div>
          </ng-container>
        </div>
        <div class="table-schedule__row">
          <div class="table-cell"></div>
          <ng-container *ngFor="let dayM of schedule.hours[0].days; let d=index">
            <ng-container *ngIf="d < displayExtraDaysMode">
              <div class="table-cell" style="width: 178px;" *ngFor="let cat of displayCategoryFilter(displayCategory, dayM.dayAlias)">
                <div class="headItem">
                  <span class="title" *ngIf="cat.id === null && isUnnamed">{{cat.name}}</span>
                  <span class="title" *ngIf="cat.id">{{cat.name}}</span>
                </div>
              </div>
              <div *ngIf="d < displayExtraDaysMode" class="day-separate"></div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="table-schedule__body">
        <!-- Сначала выводим строку 00:00-00:05 -->
        <div class="table-schedule__row" *ngIf="(!schedule.nightTime.isEmptyHour) || showEmptyHours">
          <div class="table-cell">
            <div class="cell">{{ schedule.nightTime.hour }}</div>
          </div>
          <ng-container *ngFor="let dayM of schedule.hours[0].days; let d=index">
            <ng-container *ngIf="d < displayExtraDaysMode">
              <ng-container *ngFor="let cat of displayCategoryFilter(displayCategory, dayM.dayAlias)">
                <ng-container *ngFor="let day of schedule.nightTime.days; let i = index">
                  <ng-container *ngIf="day.dayAlias === dayM.dayAlias">
                    <div *ngIf="IsEmptyColl(day, cat)" class="table-cell" style="width: 178px;">
                      <div class="cell" (click)="onEditStartEmpty(day, '00:00')">{{ schedule.nightTime.hour }}</div>
                      <div class="clubDownTime" *ngIf="day.day | clubDownTimeFilter : '00:00' : clubDownTime : cat.id : displayCategoryInput"></div>
                    </div>
                    <div *ngIf="!IsEmptyColl(day, cat)" class="table-cell" style="width: 178px; padding-top: 0;">
                      <ng-container *ngFor="let item of classesSortOrderInHourseCell(day.items); let isLast=last">
                        <div app-class-item
                             [isInCopyMode]="isInCopyMode"
                             [showEdit]="isInCopyMode"
                             [colorBy]="colorBy"
                             [Model]="item"
                             (click)="onEditStart(item)"
                             [last]="isLast"
                             [ngClass]="{'last':isLast}"
                             class="cell"
                             *ngIf="isShowClassItem(item, cat) && (isDraftViewMode || !isDraft(item)) && !item.hide"
                        ></div>
                      </ng-container>
                      <div class="cell" (click)="onEditStartEmpty(day, '00:00')"></div>
                      <div class="backgroundColorBusyCells" *ngIf="backgroundColorBusyCellsMode"></div>
                      <div class="clubDownTime" *ngIf="day.day | clubDownTimeFilter : '00:00' : clubDownTime : cat.id : displayCategoryInput"></div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <div *ngIf="d < displayExtraDaysMode" class="day-separate"></div>
          </ng-container>
        </div>
        <ng-container *ngFor="let hour of schedule.hours">
          <div class="table-schedule__row" *ngIf="(!hour.isEmptyHour) || showEmptyHours">
            <div class="table-cell">
              <div class="cell">{{ hour.hour }}</div>
            </div>
            <ng-container *ngFor="let dayM of schedule.hours[0].days; let d=index">
              <ng-container *ngIf="d < displayExtraDaysMode">
                <ng-container *ngFor="let cat of displayCategoryFilter(displayCategory, dayM.dayAlias)">
                  <ng-container *ngFor="let day of hour.days; let i = index">
                    <ng-container *ngIf="day.dayAlias === dayM.dayAlias">
                      <div *ngIf="IsEmptyColl(day, cat)" class="table-cell" style="width: 178px;" (click)="onEditStartEmpty(day, hour.hour)">
                        <div class="cell">{{ hour.hour }}</div>
                        <div class="backgroundColorBusyCells" *ngIf="backgroundColorBusyCellsMode && isBackgroundColorBusyCells(dayM.dayAlias, hour.hour, cat)" [ngStyle]="{ 'background-color': getCellColor(dayM.dayAlias, hour.hour, cat), 'opacity': 0.5 }"></div>
                        <div class="clubDownTime" *ngIf="day.day | clubDownTimeFilter : hour.hour : clubDownTime : cat.id : displayCategoryInput"></div>
                      </div>
                      <div *ngIf="!IsEmptyColl(day, cat)" class="table-cell" [class.place]="isDraftViewMode || !isOnlyDraftInCell(day.items)" style="width: 178px; {{IsEmptyCollFilter(day.items, cat) ? 'display: block;' : 'padding-top: 0;'}}">
                        <ng-container *ngFor="let item of classesSortOrderInHourseCell(day.items, cat); let isLast=last;">
                          <div app-class-item
                               [isInCopyMode]="isInCopyMode"
                               [showEdit]="isInCopyMode"
                               [colorBy]="colorBy"
                               [Model]="item"
                               (click)="onEditStart(item)"
                               [last]="isLast"
                               [ngClass]="{'last':isLast}"
                               class="cell"
                               *ngIf="isShowClassItem(item, cat) && (isDraftViewMode || !isDraft(item)) && !item.hide"
                          ></div>
                        </ng-container>
                        <div class="cell" *ngIf="IsEmptyCollFilter(day.items, cat)" (click)="onEditStartEmpty(day, hour.hour)">{{ hour.hour }}</div>
                        <div class="cell" (click)="onEditStartEmpty(day, hour.hour)"></div>
                        <div class="backgroundColorBusyCells" *ngIf="backgroundColorBusyCellsMode && isBackgroundColorBusyCells(dayM.dayAlias, hour.hour, cat)" [ngStyle]="{ 'background-color': getCellColor(dayM.dayAlias, hour.hour, cat), 'opacity': 0.5 }"></div>
                        <div class="clubDownTime" *ngIf="day.day | clubDownTimeFilter : hour.hour : clubDownTime : cat.id : displayCategoryInput"></div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <div *ngIf="d < displayExtraDaysMode" class="day-separate"></div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
