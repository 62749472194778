import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ITableHeader } from 'app/common/models/scroll-models.model';
import * as moment from 'moment';
import { IClassItemModel } from '../../../common/models/class-item.model';
import { EntityStatus } from '../../../common/models/entity-status.model';
import { ISchedule } from '../../../common/models/schedule.model';
import { TableListComponent } from '../../../common/table-list/table-list.component';
import { ScheduleService } from '../services/schedule.service';

@Component({
  selector: 'app-day-schedule-mode',
  templateUrl: './day-schedule-mode.component.html',
  styleUrls: ['./day-schedule-mode.component.scss']
})
export class DayScheduleModeComponent extends TableListComponent<ISchedule> implements OnInit, OnChanges {
  isLoading = false;
  isUnnamed = true;
  @Input() schedule: any;
  @Input() classesFilter: any[] = [];
  @Input() clubDownTime: any[] = [];
  @Input() colorBy;
  @Input() isInCopyMode: boolean;
  @Input() showEmptyHours: boolean;
  @Input() isDraftViewMode: boolean;
  @Input() cellUnknownMode: boolean;
  @Input() backgroundColorBusyCellsMode: boolean;
  @Input() displayExtraDaysMode: number;
  @Input() currentDay: Date;
  @Input() displayCategoryInput: 'room' | 'couches' | 'courses' = 'room';
  @Input() availableRooms: any[] = [];
  @Input() availableCoaches: any[] = [];
  @Input() availableCourses: any[] = [];
  @Input() tableStick: any;
  @Output() stickHeader = new EventEmitter<boolean>();

  public couches: any[] = [];
  public courses: any[] = [];
  public rooms: any[] = [];
  public displayCategory: any[] = [];
  public backgroundColorBusyCells: any[] = [];
  public tableHeader: ITableHeader;

  @ViewChild('table') tableView: ElementRef;

  public heightTableStick: any;

  constructor(
    injector: Injector,
    protected service: ScheduleService
  ) {
    super(injector, service);
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll() {
    if(window.innerWidth <= 992) {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.stickTableHead(scrollPosition);
    }
  }

  async ngOnInit(): Promise<void> {
    await this.displayCategoryUpdate();
    await this.objectCorrection().then();
    setTimeout(() => {
      if (window['CoordinatesAppContainer']) {
        document.getElementById('app_container').scroll(window['CoordinatesAppContainer'].x, window['CoordinatesAppContainer'].y);
      }
    }, 1);

    this.tableHeader = {
      position: 0,
      positionBottom: 0,
      offset: 0,
      stick: false,
      leftScroll: 0,
    };

        //Для прилипания шапки таблицы при прокрутке, когда app_container на 100vh и нельзя обратиться к прокрутке window
        if(window.innerWidth > 992) {
          const appContainer = document.querySelector('.app_container');

          appContainer.addEventListener('scroll', () => {
            this.stickTableHead(appContainer.scrollTop);
          });
        }

        const table = document.querySelector('.main-table') as HTMLElement;
        let tableOffsetLeft = table.offsetLeft;
        this.tableHeader.leftScroll = tableOffsetLeft;

        table.addEventListener('scroll', () => {
          if (table) {
            this.tableHeader.leftScroll = -(table.scrollLeft - tableOffsetLeft);
          }
        });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    await this.displayCategoryUpdate().then();
    await this.objectCorrection().then();
    this.filter();
    if (this.classesFilter.length > 0) { this.onClassesFilter(); }
  }

  public stickTableHead(pageOffset: number) {
    this.tableHeader.position = this.tableView.nativeElement.offsetTop;
    this.tableHeader.positionBottom = this.tableHeader.position + this.tableView.nativeElement.offsetHeight;

    let pageHeaderHeight = window.innerWidth <= 992 ? 0 : 110;

    if(pageOffset + pageHeaderHeight >= this.tableHeader.position && pageOffset <= this.tableHeader.positionBottom - 100){
      this.tableHeader.stick = true;
      this.stickHeader.emit(this.tableHeader.stick)
    } else {
      this.tableHeader.stick = false;
      this.stickHeader.emit(this.tableHeader.stick)
      const marginTop = parseInt(getComputedStyle(document.getElementsByClassName('controls schedule-title__box')[0]).marginTop);
      const marginBottom = parseInt(getComputedStyle(document.getElementsByClassName('dropdown-wrap')[0]).marginBottom);
      this.heightTableStick = String(this.tableStick.offsetHeight + marginBottom + marginTop) + 'px';
    }
  }

  private onClassesFilter(): void {
    for (let h = 0; h < this.schedule.hours.length; h++) {
      const hour = this.schedule.hours[h];
      for (let d = 0; d < hour.days.length; d++) {
        if (d >= this.displayExtraDaysMode) { break; }
        const day = hour.days[d];
        if (day.items.length > 0) {
          for (let t = 0; t < day.items.length; t++) {
            const item = day.items[t];
            const date = `${day.dayAlias.substr(6, 2)}.${day.dayAlias.substr(4, 2)}.${day.dayAlias.substr(0, 4)}`;
            day.items[t].hide = this.classesFilter.filter(x =>
              x.startDate === date &&
              // x.startTime === hour.hour &&
              x.id === item.id &&
              x.prototypeId === item.prototypeId &&
              x.updateAt === item.updateAt
            ).length === 0;
          }
        }
      }
    }
  }

  private async objectCorrection(): Promise<void> {
    for (let h = 0; h < this.schedule.hours.length; h++) {
      for (let d = 0; d < this.schedule.hours[h].days.length; d++) {
        const _temp = this.schedule.hours[h].days[d];
        this.schedule.hours[h].days[d].dayAlias = moment(_temp.day).format('YYYYMMDD');
      }
    }
    for (let d = 0; d < this.schedule.nightTime.days.length; d++) {
      const _temp = this.schedule.nightTime.days[d];
      this.schedule.nightTime.days[d].dayAlias = moment(_temp.day).format('YYYYMMDD');
    }
    this.isLoading = true;
  }

  private filter() {
    let data = null;
    switch (this.displayCategoryInput) {
      case 'room':
        data = this.availableRooms;
        break;
      case 'courses':
        data = this.availableCourses;
        break;
      case 'couches':
        data = this.availableCoaches;
        break;
    }

    if (data && data.filter(x => x.isEnabled).length > 0) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (!item.isEnabled) {
          this.displayCategory = this.displayCategory.filter(x => x.id !== item.id);
        }
      }
    }
  }

  async displayCategoryUpdate(): Promise<void> {
    this.displayCategory = [];
    switch (this.displayCategoryInput) {
      case 'room':
        for (let h = 0; h < this.schedule.hours.length; h++) {
          const hour = this.schedule.hours[h];
          for (let d = 0; d < hour.days.length; d++) {
            if (d >= this.displayExtraDaysMode) { break; }
            const day = hour.days[d];
            if (day.items.length > 0) {
              for (let t = 0; t < day.items.length; t++) {
                const item = day.items[t];
                if (item.room != null && this.displayCategory.filter(x => x.id === item.room.id && x.dayAlias === day.dayAlias).length === 0) {
                  this.displayCategory.push({ id: item.room.id, name: item.room.name, type: this.displayCategoryInput, dayAlias: day.dayAlias })
                }
              }
            }
          }
        }
        break;
      case 'courses':
        for (let h = 0; h < this.schedule.hours.length; h++) {
          const hour = this.schedule.hours[h];
          for (let d = 0; d < hour.days.length; d++) {
            if (d >= this.displayExtraDaysMode) { break; }
            const day = hour.days[d];
            if (day.items.length > 0) {
              for (let t = 0; t < day.items.length; t++) {
                const item = day.items[t];
                if (item.course != null && this.displayCategory.filter(x => x.id === item.course.id && x.dayAlias === day.dayAlias).length === 0) {
                  this.displayCategory.push({ id: item.course.id, name: item.course.name, type: this.displayCategoryInput, dayAlias: day.dayAlias })
                }
              }
            }
          }
        }
        break;
      case 'couches':
        for (let h = 0; h < this.schedule.hours.length; h++) {
          const hour = this.schedule.hours[h];
          for (let d = 0; d < hour.days.length; d++) {
            if (d >= this.displayExtraDaysMode) { break; }
            const day = hour.days[d];
            if (day.items.length > 0) {
              for (let t = 0; t < day.items.length; t++) {
                const item = day.items[t];
                if (item.coach != null && this.displayCategory.filter(x => x.id === item.coach.id && x.dayAlias === day.dayAlias).length === 0) {
                  this.displayCategory.push({ id: item.coach.id, name: item.coach.name, type: this.displayCategoryInput, dayAlias: day.dayAlias })
                }
              }
            }
          }
        }
        break;
    }
    this.displayCategory = this.displayCategory.sort((a, b) => a.name.localeCompare(b.name) );
    if (!this.cellUnknownMode) { this.setCellUnknown(); }
    this.setBackgroundColorBusyCells();
  }

  public setBackgroundColorBusyCells(): any {
    this.isLoading = true;
    this.backgroundColorBusyCells = [];
    for (let h = 0; h < this.schedule.hours.length; h++) {
      const hour = this.schedule.hours[h];
      for (let d = 0; d < hour.days.length; d++) {
        const day = hour.days[d];
        if (day.items.length > 0) {
          for (let t = 0; t < day.items.length; t++) {
            const item = day.items[t];
            if (!day.dayAlias) {
              return;
            }
            const [currentDay, currentMonth, currentYear] = item.startDate.split('.');
            const [currentHours, currentMinutes] = item.startTime.split(':');
            const currentDate = new Date(currentDay, currentMonth - 1, currentYear);
            const { course, entityStatus } = item;
            const { color } = course;
            const isItemDraft = entityStatus === 'draft';
            const checkDrafts = (this.isDraftViewMode && isItemDraft)
              || (this.isDraftViewMode && !isItemDraft)
              || (!this.isDraftViewMode && !isItemDraft);

            const q = {
              startTime: moment(currentDate)
                .add(currentHours, 'hours')
                .add(currentMinutes, 'minutes')
                .format('HHmm'),

              endTime: moment(currentDate)
                .add(currentHours, 'hours')
                .add(currentMinutes, 'minutes')
                .add(item.duration, 'minutes')
                .format('HHmm'),

              catId: this.getCatIdByItem(item),
              dayAlias: day.dayAlias,
              color
            }

            const hasBackgroundColorBusyCellsItem = this.backgroundColorBusyCells.some(x =>
                x.startTime === q.startTime
                && x.endTime === q.endTime
                && x.catId === q.catId
                && x.dayAlias === q.dayAlias
              );

            if (!hasBackgroundColorBusyCellsItem) {
              if (checkDrafts) {
                this.backgroundColorBusyCells.push(q);
              }
            }
          }
        }
      }
    }
    this.isLoading = false;
  }

  isBackgroundColorBusyCells(dayAlias: string, hourVal: string, cat: any): boolean {
    const hour =  Number(hourVal.replace(':', ''));
    const resp = this.backgroundColorBusyCells.filter(x => x.catId === cat.id && x.dayAlias === dayAlias);
    for (let t = 0; t < resp.length; t++) {
      const { startTime, endTime } = resp[t];
      const [h1, h2, _m1, _m2] = startTime
      const hours = `${h1}${h2}`;
      const startTimeInt = Number(startTime);
      const endTimeInt = Number(endTime);

      if ((hour >= startTimeInt && hour < endTimeInt) || (hourVal.includes(hours))) {
        return true;
      }
    }
    return false;
  }

  getCellColor(dayAlias: string, hourVal: string, cat: any) {
    const hour =  hourVal.replace(':', '');
    const currentBusyCell = this.backgroundColorBusyCells.find(q => {
      const validDay = q.dayAlias === dayAlias;
      const validTime = q.startTime === hour || q.endTime > hour;
      const validCat = cat.id === q.catId;
      return validDay && validTime && validCat;
    })

    if (currentBusyCell) {
      return currentBusyCell.color;
    }

    return null;
  }

  private getCatIdByItem(item: any) {
    if (this.displayCategoryInput === 'room') {
      return item.room ? item.room.id : null;
    } else if (this.displayCategoryInput === 'couches') {
      return item.coach ? item.coach.id : null;
    } else if (this.displayCategoryInput === 'courses') {
      return item.course ? item.course.id : null;
    } else {
      return null;
    }
  }

  private setCellUnknown() {
    // Сначала обработаем строку 00:00-00:05
    for (let d = 0; d < this.schedule.nightTime.days.length; d++) {
      const day = this.schedule.nightTime.days[d];
      if (day.items.length > 0) {
        for (let t = 0; t < day.items.length; t++) {
          const item = day.items[t];
          if (this.compareDisplayCategory(item) && this.displayCategory.filter(x => x.dayAlias === day.dayAlias && x.id === null).length === 0) {
            this.displayCategory.push({ id: null, name: 'Не задано', type: null, dayAlias: day.dayAlias });
          }
        }
      }
    }
    for (let h = 0; h < this.schedule.hours.length; h++) {
      const hour = this.schedule.hours[h];
      for (let d = 0; d < hour.days.length; d++) {
        const day = hour.days[d];
        if (day.items.length > 0) {
          for (let t = 0; t < day.items.length; t++) {
            const item = day.items[t];
            if (this.compareDisplayCategory(item) && this.displayCategory.filter(x => x.dayAlias === day.dayAlias && x.id === null).length === 0) {
              this.displayCategory.push({ id: null, name: 'Не задано', type: null, dayAlias: day.dayAlias });
            }
          }
        }
      }
    }
  }

  private compareDisplayCategory(item: any) {
    return ((this.displayCategoryInput === 'room' && !item.room) ||
      (this.displayCategoryInput === 'couches' && !item.coach) ||
      (this.displayCategoryInput === 'courses' && !item.course))
  }

  compareDay(day: Date, _day: Date = null) {
    return moment(day).format('YYYYMMDD') === moment(_day ?? new Date(this.currentDay)).format('YYYYMMDD');
  }

  public classesSortOrderInHourseCell(dayItems: Array<IClassItemModel>, cat: any = null) {
    if (!cat) { return dayItems.sort((a, b) => a.startTime.localeCompare(b.startTime)); } else if (cat) {
      switch (this.displayCategoryInput) {
        case 'room':
          if (cat.id === null) { return  dayItems.filter(x => x.room === null).sort((a, b) => a.startTime.localeCompare(b.startTime)); }
          return dayItems.filter(x => x.room?.id === cat.id).sort((a, b) => a.startTime.localeCompare(b.startTime));
        case 'couches':
          if (cat.id === null) { return  dayItems.filter(x => x.coach === null).sort((a, b) => a.startTime.localeCompare(b.startTime)); }
          return dayItems.filter(x => x.coach?.id === cat.id).sort((a, b) => a.startTime.localeCompare(b.startTime));
        case 'courses':
          if (cat.id === null) { return  dayItems.filter(x => x.course === null).sort((a, b) => a.startTime.localeCompare(b.startTime)); }
          return dayItems.filter(x => x.course?.id === cat.id).sort((a, b) => a.startTime.localeCompare(b.startTime));
      }
    }
  }

  onEditStart(item: IClassItemModel) {
    let scrollPosition;
    if (window.innerWidth <= 992) {
      scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    } else {
      scrollPosition = document.querySelector('.app_container').scrollTop;
    }

    if (item != null) {
      if (item.id === '00000000000000000000000000000000') {
        this.router.navigate([`virtual/${item.prototypeId}/${item.startDate}`], {
          relativeTo: this.route,
          queryParams: {scroll: scrollPosition}
        });
      } else {
        this.router.navigate([`${item.id}`], {relativeTo: this.route, queryParams: {scroll: scrollPosition}});
      }
    }
    return true;
  }

  onEditStartEmpty(day: any, hour: any): void {
    this.router.navigate([`create/${moment(day.day).format('DD.MM.YYYY')}/${hour}`], {
      relativeTo: this.route
    });
  }

  isShowClassItem(item: IClassItemModel, cat: any): boolean {
    if (cat.id === null) {
      return this.compareDisplayCategory(item);
    } else {
      return (this.displayCategoryInput === 'room' && cat.id === item.room?.id) ||
        (this.displayCategoryInput === 'couches' && cat.id === item.coach?.id) ||
        (this.displayCategoryInput === 'courses' && cat.id === item.course?.id)
    }
  }

  public isDraft(item: IClassItemModel): boolean {
    return item.entityStatus === EntityStatus.draft;
  }

  public isOnlyDraftInCell(items: Array<IClassItemModel>) {
    return items.every(i => this.isDraft(i));
  }

  public IsEmptyColl(day: any, cat: any): boolean {
    switch (this.displayCategoryInput) {
      case 'room':
        if (cat.id === null) { return  day.items.filter(x => x.room === null).length === 0 }
        return day.items.filter(x => x.room?.id === cat.id).length === 0;
      case 'couches':
        if (cat.id === null) { return  day.items.filter(x => x.coach === null).length === 0 }
        return day.items.filter(x => x.coach?.id === cat.id).length === 0;
        break;
      case 'courses':
        if (cat.id === null) { return  day.items.filter(x => x.course === null).length === 0 }
        return day.items.filter(x => x.course?.id === cat.id).length === 0;
        break;
    }
    return day.items.length === 0;
  }

  displayCategoryFilter(displayCategory: any[], dayAlias: any) {
    return displayCategory.filter(x => x.dayAlias === dayAlias);
  }

  IsEmptyCollFilter(items: any[], cat: any) {
    switch (this.displayCategoryInput) {
      case 'room':
        if (cat.id === null) { return  items.filter(x => x.room === null && x.hide).length !== 0 }
        return items.filter(x => x.room?.id === cat.id && x.hide).length !== 0;
      case 'couches':
        if (cat.id === null) { return  items.filter(x => x.coach === null && x.hide).length !== 0 }
        return items.filter(x => x.coach?.id === cat.id && x.hide).length !== 0;
        break;
      case 'courses':
        if (cat.id === null) { return  items.filter(x => x.course === null && x.hide).length !== 0 }
        return items.filter(x => x.course?.id === cat.id && x.hide).length !== 0;
        break;
    }
    return items.filter(x => x.hide).length !== 0;
  }
}
