import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'clubDownTimeFilter'
})
export class ClubDownTimeFilterPipe implements PipeTransform {

  transform(day: any, hour: any, clubDownTime: any[], catId: any = null, displayCategoryInput: any = null) {
    const date = moment(day); // Создаем объект Moment из дня
    const formattedDate = date.format('YYYYMMDD'); // Форматируем дату в 'YYYYMMDD'
    const [hours, minutes] = hour.split(':').map(Number);
    const formattedTime = `${hours.toString().padStart(2, '0')}${minutes.toString().padStart(2, '0')}`;
    const dateValue = `${formattedDate}${formattedTime}`; // Возвращаем строку формата YYYYMMDDHHmm

    // Преобразуем строку времени в число для сравнения с startHoursAlias и endHoursAlias
    const numericDateValue = parseInt(dateValue, 10);

    if (displayCategoryInput) {
      if (clubDownTime.some(x => x.startHoursAlias <= numericDateValue && x.endHoursAlias >= numericDateValue && x.roomId === null)) {
        return true;
      }

      if (displayCategoryInput === 'room') {
        return clubDownTime.some(x => x.startHoursAlias <= numericDateValue && x.endHoursAlias >= numericDateValue && x.roomId === catId);
      }
    }

    // Фильтруем clubDownTime, проверяя, попадает ли numericDateValue в диапазон startHoursAlias и endHoursAlias
    return clubDownTime.some(x =>
      x.startHoursAlias <= numericDateValue && x.endHoursAlias >= numericDateValue
    );
  }

}
