<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание чата/рассылки</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование чата/рассылки</span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="close()">Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <div class="row">
    <ul class="tab-group">
      <li class="tab-group__item" [class.active]="selectedTabIndex === 0" (click)="selectedTabChanged({index: 0})">
        Название
      </li>

      <li class="tab-group__item" [class.active]="selectedTabIndex === 1" (click)="selectedTabChanged({index: 1})">
        Группы
      </li>

      <li class="tab-group__item" [class.active]="selectedTabIndex === 2" (click)="selectedTabChanged({index: 2})">
        Участники
      </li>
    </ul>
  </div>

  <ng-container [ngSwitch]="selectedTabIndex">
    <!-- Параметры -->
    <div *ngSwitchDefault [@enterTrigger]="enterTriggerState">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12">
            <div class="form-group" myScrollTo>
              <div class="row__field">
                <input name="title" id="title" formControlName="title" [(ngModel)]="Model.title"
                       class="form-control input__text input__text--short"
                       placeholder="Чат с группами"
                       maxlength="500"/>
                <label for="title" class="field-validation-error" *ngIf="fieldErrors.title && isSubmited">
                  {{fieldErrors.title}}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12" [style.visibility]="Model.chat == null ? 'visible' : 'hidden'">
            <div class="form-group" myScrollTo>
              <div class="row__field">
                <app-option-selector-radio formControlName="groupType" (ngModelChange)="updateGroupType($event)"
                                           [(ngModel)]="Model.groupType"
                                           [options]="groupTypeOptions"></app-option-selector-radio>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="Model.groupType === 'mailing'">
            <div class="form-group" myScrollTo>
              <div class="row__field">
                <input type="checkbox" [(ngModel)]="Model.isReadOnly" formControlName="isReadOnly"
                       [checked]="Model.isReadOnly" class="checkbox__slide"
                       name="isReadOnly" id="isReadOnly">
                <label for="isReadOnly" class="checkbox__slide_label">Только для чтения</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Присоединить группы -->
    <div *ngSwitchCase="1" [@enterTrigger]="enterTriggerState">
      <div class="row">
        <div class="clue-text members__clue">На этой вкладке перечислены группы-участники чата</div>
      </div>

      <div class="row">
        <ul class="groups-list">
          <li *ngFor="let item of getAttachedGroups()" class="groups-list__item">
            <div class="groups-list__box">
              <div class="groups-list__box-name">{{item.name}}</div>
              <div class="groups-list__box-members" *ngIf="!item.memberCategory">
                <span appPlural="" [value]="item.clientsCount" [arVariants]="['участник', 'участника', 'участников']"></span>
              </div>
            </div>
            <button class="button-delete" (click)="detachGroup(item)"></button>
          </li>
        </ul>


        <div class="groups-text mt-2">
          <div class="multytext" [style.visibility]="isAnyGroupChecked() ? 'visible' : 'hidden'">
            <div>Выбрано:&nbsp;{{getCheckedGroupsCount()}} &nbsp;</div>
            <span appPlural="" [value]="getCheckedGroupsCount()" [arVariants]="['группа', 'группы', 'групп']"></span>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-4 p-0">
            <div class="form-group m-bot-0">
              <button class="btn btn-primary btn-small" type="submit" (click)="attachChecked(groups)"
                      [disabled]="!isAnyGroupChecked()">
                <span class="btn-primary__desc">Присоединить</span>
              </button>
            </div>
          </div>
        </div>


        <ul class="groups-list mar-t">
          <li *ngFor="let item of groups" (click)="checkGroup(item)" class="groups-list__item border-none"
              [class.active]="item.checked" [class.disabled]="item.disabled">
            <div class="groups-list__box-name">{{item.name}}</div>
            <div class="groups-list__box-members" *ngIf="!item.memberCategory">
              {{item.clientsCount}}
              <span appPlural="" [value]="item.clientsCount" [arVariants]="['участник', 'участника', 'участников']"></span>
            </div>
          </li>
        </ul>
      </div>

    </div>

    <!-- Присоединить клиентов -->
    <div *ngSwitchCase="2" [@enterTrigger]="enterTriggerState">
      <div class="row">
        <div class="search-box" id="search-block">
          <div class="row">
            <div class="col-12">
              <div class="form-group" appClickOutside (clickOutside)="searchFormClickOutside($event)">
                <div class="row__field search-wrapper">
                  <input class="form-control input__text input__text--long" autocomplete="off" #searchBox
                         id="search-box"
                         [(ngModel)]="searchBoxValue" (keyup)="searchMembers()"
                         placeholder="Имя, телефон, email клиента"/>
                </div>

                <ul class="search-box__list" *ngIf="memberSearchMode && memberSearchList && memberSearchList.length">
                  <ng-container *ngFor="let item of memberSearchList">
                    <li class="search-box__list-item search-box__list-item_pointer" *ngIf="item">
                      <div>{{item.firstName}} {{item.lastName}}</div>
                      <div><span class="phone" *ngIf="item.phone">{{item.phone}}</span></div>
                      <div>
                        <button class="btn btn-special" (click)="attachMember(item)">Добавить</button>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row wrapper">
        <div *ngIf="members.length" class="buttons-container">

          <div class="col-sm-4 col-lg-3">
            <div class="form-group m-bot-1">
              <button class="btn btn-primary btn-small" (click)="memberSelectMode = !memberSelectMode">
                <span class="btn-primary__desc">Редактировать список</span>
              </button>
            </div>
          </div>

          <div class="right-buttons">
            <div class="col-lg-offset-6 col-sm-4 col-md-5 col-lg-3">
              <div class="form-group m-bot-1">
                <button class="btn btn-primary btn-small" (click)="detachChecked()" [disabled]="!isAnyMemberChecked()">
                  <span class="btn-primary__desc">Удалить</span>
                </button>
              </div>
              <div class="form-group m-bot-1">
                <button class="btn btn-primary btn-small" (click)="restoreChecked()" [disabled]="!isAnyMemberChecked()">
                  <span class="btn-primary__desc">Восстановить</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row members">
        <ul class="members__list groups-list mt-2">
          <li *ngFor="let item of getAttachedMembers()" class="members__list-row members__list-item groups-list__item"
              [class.members__list-item_fixed]="item.fixed && item.attached"
              [class.members__list-item_detached]="!item.attached">
            <div class="members__list-cell client-name">
              <div>
                <label class="check-box" [class.checked]="item.checked"
                       [style.visibility]="memberSelectMode ? 'visible' : 'hidden'" (click)="checkMember(item)"></label>
                <span>{{item.memberName}}</span>
              </div>
            </div>
            <div class="members__list-cell client-phone" *ngIf="item.phone"><span>{{item.email}}</span></div>
            <div class="members__list-cell client-phone" *ngIf="item.phone"><span class="phone">{{item.phone}}</span>
            </div>
            <div class="members__list-cell client-groups" *ngIf="item.groups"><span>{{item.groups}}</span></div>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>

  <br>

  <div class="clearfix hidden-md-up"></div>

  <br>

  <div *ngIf="members.length && memberSearchList && !memberSearchList.length" class="col-xs-12 col-sm-6 col-md-4">
    <div class="form-group">
      <div class="row__field">
        <button class="btn btn-primary" (click)="finishEdit()" [disabled]="!this.Model.title">
          <span class="btn-primary__desc">
            <span>Сохранить</span>
          </span>
        </button>
      </div>
    </div>
  </div>

</div>
