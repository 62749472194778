<div class="search-box" id="search-block" appClickOutside (clickOutside)="onClickOutside($event)" style="margin-right: 1rem;">
    <input class="input__text input__text--long" autocomplete="off" [formControl]="searchTerm" id="search-box" [placeholder]="placeholder"
    />
    <label class="search-box__description" *ngIf="!!description">{{description}}</label>
    <ul class="search-box__list" *ngIf="suggestionList && suggestionList.length">
        <ng-container *ngFor="let suggestion of suggestionList">
            <li class="search-box__list-item search-box__list-item_pointer" *ngIf="suggestion" (click)="choose(suggestion)">
                {{niceItemName(suggestion)}}
            </li>
        </ng-container>
    </ul>
</div>
