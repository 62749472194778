<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
       Группы
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container *ngIf="Model!=null">
    <div class="groups-container">
      <div class="tableSideLinkWrap">
        <div class="clue-text">
          <span class="key" app-key-help [key]="'search'"></span>
          <span class="key" app-key-help [key]="'searchHK'"></span>
          <ng-container *ngIf="isEditAvailable">
            <a class="box-link" (contextmenu)="onEditStart(null,$event)" (click)="onEditStart(null, $event)">Добавить
              <span class="key">
              <span app-key-help [key]="'plus'"></span>
            </span>
            </a>
          </ng-container>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table rowHover">
          <thead>
            <tr>
              <td class="table-cell table-title-cell">Название</td>
            </tr>
          </thead>
          <tbody *ngIf="(Model && Model.length)">
            <tr *ngFor='let group of Model' (contextmenu)="onEditStart(group,$event)" (click)="onEditStart(group, $event)">
              <td class="table-cell">
                <div class="field desc">
                  <span><b>{{ group.name }} ({{group.countClient}})</b></span>
                </div>
              </td>
            </tr>
          </tbody>
          <div *ngIf="!(Model && Model.length)" class="table-cell">
            Группы — это список клиентов, которые регулярно ходят в одно и то же время к одному и тому же тренеру. <br/>
            Группу можно закрепить за предстоящим занятием в расписании. Минимальное количество человек в группе — 1.<br/>
            При добавлении или удалении клиента из группы он добавляется или исчезает из всех записей на предстоящие занятия, где закреплена эта группа.<br/>

            Пример названия группы: Фитнес Пн, Ср, Пт, 10:00
          </div>
        </table>
      </div>
    </div>
  </ng-container>

</div>
