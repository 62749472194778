<header class="content__header">
  <div class="content__header-title">
    <span class="content__header-title-value content__header-title-value_default">
       Финансы
    </span>
  </div>
</header>

<div class="content__body">
  <ng-container>
    <mat-tab-group>
      <mat-tab label="Реестры">
        <ng-container *ngIf="!registry || registry?.length == 0">
          <div class="empty-queue">
            <p class="empty-queue__p" @animation>
              Здесь публикуются реестры онлайн-покупок.<br> Аккаунт-менеджер поможет вам подключить эту услугу и ответит на вопросы.
            </p>
            <br>
            <br>
            <div>
              <a class="restore-link connect-club" target="_blank" href="{{linkTelegram}}">Написать в Telegram</a>
              &nbsp;
              <a class="restore-link connect-club" target="_blank" href="{{linkWhatsApp}}">Написать в WhatsApp</a>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="registry && registry?.length != 0">
          <div class="clubnets-container">
            <div class="table-responsive">
              <table class="table rowHover">
                <thead>
                <tr>
                  <td class="table-cell table-title-cell">Название</td>
                  <td class="table-cell table-title-cell">Кол-во</td>
                  <td class="table-cell table-title-cell" style="text-align: right">Сумма</td>
                  <td class="table-cell table-title-cell" style="text-align: right">Итог</td>
                  <td class="table-cell table-title-cell">Статус</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor='let r of registry' (click)="onRegistry(r)">
                  <td class="table-cell">
                    <span class="table-cell__mobile-title">Название</span>
                    <span>Реестр от {{dateFormat(r.dateFrom)}} - {{dateFormat(r.dateTo)}}</span>
                  </td>
                  <td class="table-cell">
                    <span class="table-cell__mobile-title">Кол-во транзакций</span>
                    <span>{{data(r.data, 'TransactionCount')}}</span>
                  </td>
                  <td class="table-cell" style="text-align: right">
                    <span>{{formatNumber(data(r.data, 'Amount').toFixed(2))}}</span>
                  </td>
                  <td class="table-cell" style="text-align: right">
                    <span>{{formatNumber(data(r.data, 'AmountTotal').toFixed(2))}}</span>
                  </td>
                  <td class="table-cell">
                    <span class="table-cell__mobile-title">Статус</span>
                    <span *ngIf="r.status == 'confirmed'" style="color: yellowgreen;">Подтвержденный</span>
                    <span *ngIf="r.status == 'completed'" style="color: #01e001;">Завершенный</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span matBadge="Beta" matBadgeOverlap="false">Все оплаты</span>
        </ng-template>
        <div class="content__body" *ngIf="showPaymentScreenToAdmin" @animation #tablePaymentsRef>
          <ng-container>
              <div class="row">
                <!-- DateTimePicker -->
                  <div class="col-md-7 margin-top-15">
                    <div class="date-time-picker-pos">
                      <div class="main-select-period">
                        <div class="block-range-date">
                          <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [dateFilter]="dateFilter">
                            <input matStartDate formControlName="start">
                            <input matEndDate formControlName="end" (dateChange)="onRangePickerChange($event)">
                          </mat-date-range-input>
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-date-range-picker #picker></mat-date-range-picker>
                        </div>
                        <div class="">
                          <button class="btn btn-primary btn-sm" type="submit" (click)="reloadData()">
                            <span class="btn-primary__desc">↻</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- Строка поиска -->
                <div class="col-md-5 margin-top-15">
                  <div class="input__text">
                    <input class="input-no-border" matInput type="text" placeholder="Поиск" [ngModel]="searchString" (ngModelChange)="applyFilterInput($event)">
                    <button class="clear" matSuffix mat-icon-button (click)="clearFilterInput()" [disabled] = "!searchString">
                      <mat-icon>
                        {{ searchString == null || searchString.length == 0 ? 'search' : 'close' }}
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </div>
          </ng-container>
          <!-- Выводится если нет данных для таблицы -->
          <div *ngIf="isExistData === false" class="empty-queue">
            <p class="empty-queue__p" @animation>
              За выбранный промежуток данные отсутствуют.
            </p>
          </div>
          <!-- Таблица основная -->
          <div *ngIf="isExistData">
            <div class="box-title" *ngIf="ordersArraySummary">Сводный отчёт</div>
            <div class="table-scroll">
              <mat-table class="table-width-consolidated" [dataSource]="dataConsolidated | splitMatTableData | async" [trackBy]="trackByFn" *ngIf="ordersArraySummary">
                <ng-container *ngIf="consolidatedColumns && consolidatedColumns.length">>
                  <ng-container [matColumnDef]="item.name" *ngFor="let item of consolidatedColumns">
                    <mat-header-cell *matHeaderCellDef>{{item.displayName}}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [innerHtml]="element[item.name]"></mat-cell>
                  </ng-container>
                </ng-container>
                <mat-header-row class="border-row" *matHeaderRowDef="consolidatedColumnNames"></mat-header-row>
                <mat-row class="table-summ border-row" *matRowDef="let row; columns: consolidatedColumnNames;"></mat-row>
              </mat-table>
            </div>
            <br>
            <br>
            <div class="table-scroll" >
              <mat-table class="table-width" #table [dataSource]="ordersArray | splitMatTableData | async" [trackBy]="trackByFn" *ngIf="ordersArray" matSort>
                <ng-container *ngIf="ordersColumns && ordersColumns.length">
                  <ng-container [matColumnDef]="item.name" *ngFor="let item of ordersColumns; let i = index;">
                    <mat-header-cell
                      [ngClass]="{'th-right': AlignRight(item.name)}"
                      *matHeaderCellDef
                      [style.border-bottom]="(header['_sort'].active == item.name && header['_sort'].direction) ? '3px solid #00C168' :''"
                    >
                      <span mat-sort-header #header>
                        {{item.displayName}}
                      </span>
                      <button mat-icon-button class="btn" [matMenuTriggerFor]="menu">
                        <mat-icon
                          [style.color]="(filteredValues[item.name] == selectButtonFilter[item.name]) ? '#00C168' :''">
                          filter_list_alt
                        </mat-icon>
                      </button>
                      <mat-menu #menu>
                        <div mat-menu-item class="menu-title" [disableRipple]="true"  (click)="$event.stopPropagation();">
                          {{item.displayName}}
                        </div>
                        <div class="check-box-filter" mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation();">
                          <mat-selection-list [(ngModel)]="selectModel[item.name]" (selectionChange)="onChangeSelect(selectModel, item.name)">
                            <mat-list-option *ngFor="let shoe of filterSelectObj[i].options" checkboxPosition="before" [value]="shoe">
                              {{shoe}}
                            </mat-list-option>
                          </mat-selection-list>
                        </div>
                        <div class="mat-menu-width" mat-menu-item [disableRipple]="true">
                          <div class="button-group-menu">
                            <button class="mat-button mat-button-menu" mat-raised-button (click)="clearColumn(item.name)">Очистить</button>
                            <button class="mat-button mat-button-menu" mat-raised-button (click)="applyFilterColum(selectFilter, item.name)">Найти</button>
                          </div>
                        </div>
                      </mat-menu>
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'th-right': AlignRight(item.name)}" *matCellDef="let element" [innerHtml]="cellContent(element, item.name)"></mat-cell>
                  </ng-container>
                </ng-container>

                <!-- expand pop-up -->
                <ng-container matColumnDef="expandedDetail">
                  <mat-cell *matCellDef="let detail">
                    <div [innerHTML]="detail.infoDetail"></div>
                    <!--<button (click)="showPopUp(detail, $event)">Open file</button>-->
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="ordersColumnNames"></mat-header-row>
                <mat-row
                  class="element-row"
                  *matRowDef="let row; columns: ordersColumnNames;"
                  matRipple
                  [class.expanded]="row.isExpanded"
                  (click)="showPopUp(row, $event)"
                >
                </mat-row>
              </mat-table>
              <mat-table [dataSource]="ordersArraySummary | splitMatTableData | async" [trackBy]="trackByFn" *ngIf="ordersArraySummary">
                <ng-container *ngIf="ordersColumns && ordersColumns.length">
                  <ng-container [matColumnDef]="item.name" *ngFor="let item of ordersColumns">
                    <mat-cell [ngClass]="{'th-right': AlignRight(item.name)}" *matCellDef="let element"><b>{{element[item.name]}}</b></mat-cell>
                  </ng-container>
                </ng-container>
                <mat-row class="table-width" *matRowDef="let row; columns: ordersColumnNames;"></mat-row>
              </mat-table>
            </div>
            <mat-paginator #paginator [pageSize]="pageSize" [pageIndex]="pageIndex" [length]="totalItems" [pageSizeOptions]="[20, 50, this.totalItems]" (page)="onPageChange($event)" [showFirstLastButtons]="true"></mat-paginator>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</div>


<ng-template #tmplPaymentPopup let-item="item">
  <div class="data-pop-up">
    <div class="row-pop-up">
      <div class="cell-pop-up">Клиент:</div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.fullName }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Телефон:</div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.phone }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Email: </div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.email }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Комментарий:</div>
      <div class="cell-data-pop-up">{{ item.billingData.productDescription ?? item.billingData.comment }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Дата:</div>
      <div class="cell-data-pop-up">{{ item.paymentDate }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Сумма:</div>
      <div class="cell-data-pop-up">{{ item.amount }}</div>
    </div>
  </div>
</ng-template>

<ng-template #tmplSubscriptionPopup let-item="item">
  <div class="data-pop-up">
    <div class="row-pop-up">
      <div class="cell-pop-up">Клиент:</div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.fullName }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Телефон:</div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.phone }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Email: </div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.email }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Абонемент:</div>
      <div class="cell-data-pop-up">{{ item.subscriptionInfo?.name }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Кол-во посещений:</div>
      <div class="cell-data-pop-up">{{ item.subscriptionInfo?.visitCount }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Срок действия:</div>
      <div class="cell-data-pop-up">{{ this.convertDurationUnit(item.subscriptionInfo) }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Комментарий:</div>
      <div class="cell-data-pop-up">{{ item.billingData.productDescription ?? item.billingData.comment }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Дата:</div>
      <div class="cell-data-pop-up">{{ item.paymentDate }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Сумма:</div>
      <div class="cell-data-pop-up">{{ item.amount }}</div>
    </div>
  </div>
</ng-template>

<ng-template #tmplCertificatePopup let-item="item" let-expiredAt="expiredAt">
  <div class="data-pop-up">
    <div class="row-pop-up">
      <div class="cell-pop-up">Клиент:</div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.fullName }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Телефон:</div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.phone }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Email: </div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.email }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Тип сертификата:</div>
      <div class="cell-data-pop-up" *ngIf="item.certInfo?.type === 1">На услугу</div>
      <div class="cell-data-pop-up" *ngIf="item.certInfo?.type === 3">На сумму</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Код сертификата:</div>
      <div class="cell-data-pop-up">{{ item.certInfo?.number }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Активировать до:</div>
      <div class="cell-data-pop-up">{{ expiredAt }}</div>
    </div>
    <ng-container *ngIf="item.certInfo?.type === 1 && item.subscriptionInfo">
      <div class="row-pop-up">
        <div class="cell-pop-up">Услуга:</div>
        <div class="cell-data-pop-up">{{ item.subscriptionInfo.name }}</div>
      </div>
      <div class="row-pop-up">
        <div class="cell-pop-up">Кол-во посещений:</div>
        <div class="cell-data-pop-up">{{ item.subscriptionInfo.visitCount }}</div>
      </div>
      <div class="row-pop-up">
        <div class="cell-pop-up">Срок действия:</div>
        <div class="cell-data-pop-up">{{ this.convertDurationUnit(item.subscriptionInfo) }}</div>
      </div>
    </ng-container>
    <div class="row-pop-up">
      <div class="cell-pop-up">Дата:</div>
      <div class="cell-data-pop-up">{{ item.paymentDate }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Сумма:</div>
      <div class="cell-data-pop-up">{{ item.amount }}</div>
    </div>
  </div>
</ng-template>

<ng-template #tmplClubeventPopup let-item="item">
  <div class="data-pop-up">
    <div class="row-pop-up">
      <div class="cell-pop-up">Клиент:</div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.fullName }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Телефон:</div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.phone }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Email: </div>
      <div class="cell-data-pop-up">{{ item.clientInfo?.email }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Название мероприятия:</div>
      <div class="cell-data-pop-up">{{ item.clubEventInfo?.desc }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Тип билета:</div>
      <div class="cell-data-pop-up">{{ item.clubEventInfo?.ticketTitle }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Количество билетов:</div>
      <div class="cell-data-pop-up">{{ item.clubEventInfo?.ticketsCount }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Дата:</div>
      <div class="cell-data-pop-up">{{ item.paymentDate }}</div>
    </div>
    <div class="row-pop-up">
      <div class="cell-pop-up">Сумма:</div>
      <div class="cell-data-pop-up">{{ item.amount }}</div>
    </div>
  </div>
</ng-template>
