export interface IModuleRoute {
    url: string;
    class: string;
    title: string;
    visibility: ModuleVisibility[];
    urlPrefix?: string;
    urlSuffix?: string;
}

export enum ModuleVisibility {
    admin,
    club
}

export const moduleRoutes: Array<IModuleRoute> = [
    {
        url: 'clubdashboard',
        class: 'sp-desktop',
        title: 'Рабочий стол',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubschedule',
        class: 'sp-schedule',
        title: 'Расписание',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubclients',
        class: 'sp-client',
        title: 'Клиенты',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubgroups',
        class: 'sp-client_group',
        title: 'Группы',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubplans',
        class: 'sp-subscribe',
        title: 'Абонементы',
        visibility: [ModuleVisibility.club]
    },
    {

      url: 'certificates',
      class: 'sp-w',
      title: 'Сертификаты',
      visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubcourses',
        class: 'sp-classes',
        title: 'Виды занятий',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'club',
        class: 'sp-info',
        title: 'О клубе',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubcoaches',
        class: 'sp-whistle',
        title: 'Тренеры',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubcoachsalarycalc',
        class: 'sp-coach_salary',
        title: 'Расчет ЗП',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubrooms',
        class: 'sp-home',
        title: 'Залы',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubchats',
        class: 'sp-chats',
        title: 'Чаты',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubevents',
        class: 'sp-event',
        title: 'Мероприятия',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubsportpriorityprofile',
        class: 'sp-sp',
        title: 'Профиль',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubusers',
        class: 'sp-user',
        title: 'Пользователи',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubmfimport',
        class: 'sp-download',
        title: 'Импорт',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubnets',
        class: 'sp-w',
        title: 'Сети клубов',
        visibility: [ModuleVisibility.admin]
    },
    {
        url: 'adminsettings',
        class: 'sp-settings',
        title: 'Настройки',
        visibility: [ModuleVisibility.admin]
    },
    {
        url: 'clubsettings',
        class: 'sp-settings',
        title: 'Настройки',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'users',
        class: 'sp-user',
        title: 'Пользователи',
        visibility: [ModuleVisibility.admin]
    },
    {
      url: 'finance',
      class: 'sp-document',
      title: 'Финансы',
      visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubanalytics',
        class: 'sp-analytic',
        title: 'Аналитика',
        visibility: [ModuleVisibility.club, ModuleVisibility.admin]
    },
    {
      url: 'webhook',
      class: 'sp-w',
      title: 'Веб-хуки',
      visibility: [ModuleVisibility.club, ModuleVisibility.admin]
    },
    {
        url: 'clubwidgets',
        class: 'sp-widget',
        title: 'Виджеты',
        visibility: [ModuleVisibility.club]
    },
    {
        url: 'clubs',
        class: 'sp-club',
        title: 'Клубы',
        visibility: [ModuleVisibility.admin]
    },
    {
        url: 'chats',
        class: 'sp-chats',
        title: 'Чаты',
        visibility: [ModuleVisibility.admin]
    },
    {
        url: 'cities',
        class: 'sp-cities',
        title: 'Города',
        visibility: [ModuleVisibility.admin]
    },
    {
      url: 'registry',
      class: 'sp-analytic',
      title: 'Реестры',
      visibility: [ModuleVisibility.admin]
    },
    {
      url: 'collections',
      class: 'sp-event',
      title: 'Подборки',
      visibility: [ModuleVisibility.admin]
    },
];

