import { Component, ViewChild, ElementRef, ViewChildren, QueryList, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';

import { EditItemComponent } from 'app/common/edit-item.component';
import { IChat, IChatMessage, IChatInfo, IChatMember } from 'app/common/models/chat.models';
import { ChatService } from 'app/components/chats/chats.service';
import { IPagingInfo } from 'app/common/models/paging-info.model';
import { BrowserStateService } from 'app/common/services/browser-state.service';
import { ChatHub } from 'app/common/services/chat.hub';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-chat]',
    templateUrl: './chat.component.html'
})
export class ChatComponent extends EditItemComponent<any> {
    public static readonly componentName: string = 'ChatComponent';

    protected refreshModelOnRoutingParamsChange = true;
    protected chatLoaded: Subject<void> = new Subject();

    @ViewChild('contentbody') contentBody: ElementRef;
    @ViewChildren('message') messages: QueryList<ElementRef>;

    public chat: IChatInfo;
    public msgList: IChatMessage[] = null;
    private pagingInfo: IPagingInfo = null;
    private prevIndex: number;
    private pageSize = 32;

    public finalizeMessage: IChatMessage;
    public greetingMessage: string;

    private isMobile: boolean;
    private isUpdating = false;
    private hasFirstUnread = false;

    public editorMsg = '';

    constructor(
        injector: Injector,
        browserStateService: BrowserStateService,
        private location: Location,
        private chatHub: ChatHub,
        protected service: ChatService
    ) {
        super(injector, service);
        this.isMobile = browserStateService.isMobile();
    }

    public afterModelInit() {
        this.msgList = [];
        this.chat = this.Model as IChatInfo;
        this.chat.isMeberMe
            = function (id: string): boolean { return this.members.some(x => x.id === id && x.isMe); }
        this.chat.chat.updatedAt = 0;
        this.chatLoaded.next();
        this.chatHub.disconnect();
        this.chatHub.connectToChat(this.chat.chat.id);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.chatLoaded.subscribe(
            () => timer(0, 60000).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.chatLoaded))
            .subscribe(() => !this.isUpdating && this.refresh())
        );
        this.chatHub.messageReceived.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(this.onMessageReceived);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.chatHub.disconnect();
    }

    private onMessageReceived = (message: IChatMessage) => {
        const ids = [];
        this.msgList = (this.msgList || [])
            .concat(message)
            .sort((a, b) => this.chat.chat.groupType == 'mailing' ? (a.primaryMessageAt - b.primaryMessageAt) : (a.createdAt - b.createdAt))
            .filter(elem => !ids.includes(elem.id) && !!ids.push(elem.id));
        this.finalizeMessage = this.msgList.length && !this.msgList[this.msgList.length-1].text ? this.msgList.pop() : undefined;
        this.prevIndex = Math.floor(this.msgList.length / this.pageSize);
        this.scrollToBottom();
    }

    public async refresh(loadAll = false, backwards = false) {
        if (this.chat) {
            try {
                this.isUpdating = true;
                const response = await this.service.getMessages(this.chat, loadAll, this.pageSize, backwards ? this.prevIndex : 0);

                if (response) {
                    response.items.reverse().forEach(message => {
                        message.isFirstUnread
                            = this.chat.me && !this.hasFirstUnread && (message.from.userId !== this.chat.me.userId)
                            && message.createdAt > (response.lastReadMessageAt || 0)
                            && (this.hasFirstUnread = true);
                        this.chat.chat.updatedAt = Math.max(this.chat.chat.updatedAt, message.createdAt);
                    });

                    const ids = [];
                    this.msgList = (this.msgList || [])
                        .concat(response.items)
                        .sort((a, b) => this.chat.chat.groupType == 'mailing' ? (a.primaryMessageAt - b.primaryMessageAt) : (a.createdAt - b.createdAt))
                        .filter(elem => !ids.includes(elem.id) && !!ids.push(elem.id));
                    this.finalizeMessage = this.msgList.length && !this.msgList[this.msgList.length-1].text ? this.msgList.pop() : undefined;
                    this.greetingMessage = this.msgList.length == response.pagingInfo.totalItemCount && this.chat.chat.greetingMessage || undefined;

                    this.prevIndex = Math.floor(this.msgList.length / this.pageSize);
                    (backwards || loadAll) && (this.pagingInfo = response.pagingInfo);
                    response.items.length && this.scrollToBottom();
                }
            } finally {
                this.isUpdating = false;
            }
        }
    }

    private async getPreviousMessages() {
        try {
            const messages = this.messages;
            const first: HTMLElement = messages ? messages.first.nativeElement : null;
            const offset = first ? first.offsetTop : 0;
            await this.refresh(true, true);
            setTimeout(() => {
                const elem: HTMLElement = this.contentBody && this.contentBody.nativeElement;
                elem && elem.scrollTo(0, first ? first.offsetTop - offset : 0);
            }, 0);
        } catch { }
    }

    isMessageMerged(msg: IChatMessage): boolean { return this.chat.chat.id !== msg.from.chatId; }

    messageInfo(member: IChatMember): string {
        let result = member.memberName;
        if (this.chat.chat.groupType === "mailing") {
            if (member.chatId === this.chat.chat.id)
                result = `${result} ВСЕМ`;
            else if (member.isAdmin) {
                const memb = this.chat.members.find(x => x.chatId === member.chatId && !x.isAdmin);
                result = `${result} \uD83E\uDC7A ${memb.memberName}`;
            }
        }
        else if (member.chatId !== this.chat.chat.id) {
            result = `${result} ВСЕМ`;
        }
        return result;
    }

    onOtherChatClick(chatId: string)
    {
        var url = this.service.pendingUrl
            .reduce((x, y, i, arr) => x + `/${i == arr.length - 1 ? chatId : y}`, '');
        this.router.navigateByUrl(url);
    }

    private isFinalized(): boolean {
        return !this.msgList || !this.msgList.length || this.msgList[this.msgList.length-1].isFinalize || this.finalizeMessage != undefined;
    }

    showFinalizeButton(): boolean {
        return this.chat.canFinalize && !this.isFinalized() && this.editorMsg == "";
    }

    editorPlaceholder(): string {
        return this.chat.chat.groupType === "notGroup"
            ? "Напишите что-нибудь"
            : "Сообщение для ВСЕХ участников чата";
    }

    public sendMsg() {
        if (!this.editorMsg.trim() && (!this.chat.canFinalize || this.isFinalized())) { return; }

        const newMsg: IChatMessage = {
            isFinalize: true,
            isAdmin: true,
            status: 'pending',
            text: this.editorMsg,
            from: this.chat.me,
            createdAt: Date.now(),
            editedAt: null,
            readAt: null,
            id: null
        };

        this.service.sendMessage(this.chat, this.editorMsg, this.chatHub.connectionId)
            .then(result => {
                newMsg.isFinalize = result.chatMessage.isFinalize;
                newMsg.status = 'succes';
                newMsg.id = result.chatMessage.id;
                newMsg.createdAt = result.chatMessage.createdAt;
                newMsg.primaryMessageAt = result.chatMessage.primaryMessageAt;
                newMsg.text = result.chatMessage.text;
                this.msgList = this.msgList.filter((elem, i, arr) => elem.text || i == arr.length-1);
            });

        this.scrollToBottom();
        if (newMsg.text) {
            this.finalizeMessage = undefined;
            this.msgList.push(newMsg);
        }
        else {
            this.finalizeMessage = newMsg;
        }
        this.editorMsg = '';
    }


    public scrollToBottom() {
        setTimeout(() => {
            const elem: HTMLElement = this.contentBody && this.contentBody.nativeElement;
            elem && elem.scrollTo(0, elem.scrollHeight);
        }, 0);
    }

    public onReturn() {
        !this.isMobile && this.sendMsg();
    }

    public onScroll(event) {
        const scrollTop = this.contentBody.nativeElement.scrollTop;

        if (this.pagingInfo && this.msgList.length < this.pagingInfo.totalItemCount && !this.isUpdating && event && scrollTop < 120) {
            this.contentBody.nativeElement.scrollTo(0, 0);
            this.getPreviousMessages();
        }
    }

    public _close() {
        this.location.back();
    }

    public buildForm() { }
    protected modelTemplate(): Promise<IChat> {
        return Promise.resolve(null);
    }
}
