import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from './http.service';
import {IContext} from 'app/common/models/context.model';
import {IClubModel} from 'app/common/models/club.model';
import {ICityModel} from 'app/common/models/city-model';
import {EntityStatus} from 'app/common/models/entity-status.model';
import {IPagedList} from 'app/common/models/common.models';
import {IClubNetModel} from 'app/common/models/clubnet.model';
import {IAccount} from 'app/common/models/account.model';
import {IGroupModel} from 'app/common/models/group.model';
import {ChatGroupType, IChatGetActiveResponse, IChatGetAllResponse, IChatGetMessagesReq, IChatInfo, IChatMessage, IChatPageReq, IChatReadMessageReq, IChatResponse, IChatSendMessageReq, IGroupChat, IGroupChatEntities, MemberCategory} from 'app/common/models/chat.models';
import {IThemeModel, IWidgetModel} from 'app/common/models/widgets.model';
import {IUserInfo} from 'app/common/models/user-info.model';
import {IClassRequest} from '../models/class-request.model';
import {IClubEventBooking, IClubEventModel} from '../models/club-events.model';
import {IMedias} from '../models/medias.model';
import * as _ from 'lodash';
import {IImageParams} from '../models/image.model';
import {IClientModel} from '../models/client.model';
import {IRegistryParameter, Registry, RegistryElement} from '../models/registry.model';
import {Collections, CollectionsTypes} from '../models/collections.model';
import {Accounting} from '../models/accounting.model';
import {GraphsSummary} from '../models/graphs-summary.model';
import {ElectronicQueue} from '../models/electronic-queue.model';
import {ResponseContentType} from '@angular/http';
import {ClubDownTime, ClubDownTimeGetByClubReq} from '../models/club-down-time.model';
import {ICertificate, ICertificateList} from '../models/certificate.model';
import {IWebhookModel} from '../models/webhook.model';

@Injectable()
export class ApiV2Service {
    private requestCounter: BehaviorSubject<number>;

    public classRequest = ((api: this) => {
        return {
            getByClub(clubId: string, handlePreloader: boolean): Promise<Array<IClassRequest>> {
                return api.apiPost('classRequest-getByClub', { clubId }, undefined, undefined, undefined, handlePreloader);
            },
            delete(id: string, withoutNotification: boolean = false, handleResult: boolean = true): Promise<any> {
                return api.apiPost('classRequest-delete', { id, withoutNotification },
                    undefined, undefined, undefined, false, undefined, handleResult);
            },
            confirm(subscriptionId: string, classRequestId: string, userId?: string, withoutNotification: boolean = false): Promise<any> {
                return api.apiPost('classRequest-confirm', { classRequestId, userId, subscriptionId, withoutNotification },
                    undefined, undefined, undefined, false, undefined, undefined);
            },
            searchConfirmAndDeleteOrBook(classVisitId: string, subscriptionId: string, clubId: string, withoutNotification: boolean = false, incrementRequestCount: boolean = true): Promise<any> {
              return api.apiPost('searchConfirmAndDeleteOrBook', { classVisitId, subscriptionId, clubId, withoutNotification },
                undefined, undefined, undefined, incrementRequestCount, undefined, undefined);
            }
        };
    })(this);

    public clubPayments = ((api: this) => {
      return {
          getOrdersByClub(clubId: string, dateFrom?: string, dateTo?: string, pageIndex?: number, pageSize?: number): Promise<any> {
              return api.apiPost('clubPayments-getOrdersByClub', { clubId: clubId, dateFrom: dateFrom, dateTo: dateTo, pageIndex: pageIndex,  pageSize: pageSize},
                  undefined, undefined, undefined, false);
          }
      };
    })(this);

    public cities = ((api: this) => {
        return {
            getAll(): Promise<ICityModel[]> {
                return api.apiPost('cities-getAll', {
                    statuses: [EntityStatus.published, EntityStatus.draft]
                });
            },
            get(id: string): Promise<ICityModel> {
                return api.apiPost('cities-get', { id });
            },
            save(model: ICityModel): Promise<void> {
                return api.apiPost('cities-save', { model });
            }
        };
    })(this);

    public themes = ((api: this) => {
        return {
            get(id: string): Promise<IThemeModel> {
                return api.apiPost('widgetTheme-get', { id });
            },
            find(clubId: string, widgetType: string): Promise<IThemeModel[]> {
                return api.apiPost('widgetTheme-getByWidget', { clubId, widgetType });
            },
            save(model: IThemeModel): Promise<void> {
                return api.apiPost('widgetTheme-save', { model });
            }
        };
    })(this);

    public widgets = ((api: this) => {
        return {
            get(id: string): Promise<IWidgetModel> {
                return api.apiPost('widget-get', { id });
            },
            getByClub(clubId): Promise<IWidgetModel[]> {
                return api.apiPost('widget-getByClub', { clubId });
            },
            save(model: IWidgetModel): Promise<void> {
                return api.apiPost('widget-save', { model });
            }
        };
    })(this);

    public clubEvents = ((api: this) => {
        return {
            get(id: string): Promise<IClubEventModel> {
                return api.apiPost('clubEvent-get', { id });
            },
            getByClub(clubId: string, pageIndex?: number, pageSize?: number, parentId?: string): Promise<IClubEventModel[]> {
                return api.apiPost('clubEvent-getByClub', { clubId, pageIndex, pageSize, parentId });
            },
            save(model: IClubEventModel): Promise<void> {
                return api.apiPost('clubEvent-save', api.modelToFormData(model), undefined, 'v2/crm/', true);
            },
            getBookings(model: IClubEventModel): Promise<IClubEventBooking[]> {
              return api.apiPost('clubEvent-getBookings', { ClubEventId: model.id });
            }
        };
    })(this);

    public chats = ((api: this) => {
        return {
            request(req: IChatPageReq): Promise<IChatInfo> {
                return api.apiPost(`chats-request`, req);
            },

            get(chatId: string, cityId?: string, clubId?: string): Promise<IChatInfo> {
                return api.apiPost(`chats-get`, { chatId, cityId, clubId });
            },

            getAll(clubId?: string, pageIndex?: number, pageSize?: number, cityId?: string, mailingId?: string): Promise<IChatGetAllResponse> {
              return api.apiPost(`chats-getAll`, { cityId, clubId: clubId, mailingId: mailingId, pageIndex: pageIndex, pageSize: pageSize });
            },

            getActive(clubId: string, cityId?: string, pageSize?: number): Promise<IChatGetActiveResponse> {
              return api.apiPost(`chats-getActive`, { clubId, cityId, pageSize }, undefined, undefined, false, false );
            },

            getMessages(req: IChatGetMessagesReq): Promise<IChatResponse> {
                return api.apiPost(`chats-getMessages`, req);
            },

            readMessages(req: IChatReadMessageReq) {
                return api.apiPost(`chats-readMessages`, req);
            },

            sendMessage(req: IChatSendMessageReq): Promise<{ chatMessage: IChatMessage }> {
                return api.apiPost(`chats-sendMessage`, req);
            },

            save(req: { id?: string, groupType: ChatGroupType, clubId?: string, isReadOnly: boolean, title: string,
                groupsIds: string[], groupsCategories: MemberCategory[],
                excludedUsersIds: string[], usersIds: string[], adminsIds: string[]
            }): Promise<IGroupChat> {
                return api.apiPost(`chats-save`, req);
            },

            getChat(chatId: string, cityId?: string, clubId?: string): Promise<IGroupChat> {
                return api.apiPost(`chats-getChat`, { chatId, cityId, clubId });
            },

            requestEntities(clubId?: string): Promise<IGroupChatEntities> {
                return api.apiPost(`chats-entities`, { clubId });
            }
        };
    })(this);

    public search = ((api: this) => {
        return {
            clubs(text: string, city?: ICityModel): Promise<IPagedList<any>> {
                return api.apiPost('clubs-search', {
                    text,
                    cityId: city ? city.id : null
                })
            }
        };
    })(this);

    public clubnets = ((api: this) => {
        return {
            getAll(city?: ICityModel): Promise<IPagedList<IClubNetModel>> {
                return api.apiPost(`nets-getAll`, {
                    cityId: city ? city.id : null,
                    statuses: [EntityStatus.published, EntityStatus.draft]
                });
            }
        };
    })(this);

    public context = ((api: this) => {
        return {
            get(clubId?: string): Promise<IContext> {
                return api.apiPost(`context-get`, { clubId });
            },
            getUserInfo(): Promise<IUserInfo> {
                return api.apiPost(`context-getUserInfo`);
            },
            getModulesForRoleIsInsideClub(): Promise<Array<any>> {
              return api.apiPost(`GetModulesForRoleIsInsideClub`);
            }
        };
    })(this);

    public image = ((api: this) => {
        return {
            get(imageId: string): Promise<IImageParams> {
                return api.apiPost('images-get', { id: imageId });
            },
            center(imageId: string, centerX?: number, centerY?: number): Promise<IImageParams> {
                return api.apiPost('images-center', { id: imageId, centerX, centerY });
            },
            save(body: string): Promise<IImageParams> {
              return api.apiPost('images-save', { body: body });
            },
            addToClient(clientId: string, imageId: string): Promise<IImageParams> {
              return api.apiPost('image-add-to-client', { clientId: clientId, imageId: imageId});
            },
        };
    })(this);

    public accounts = ((api: this) => {
        return {
          getAll(city?: ICityModel, pageIndex?: number, pageSize?: number): Promise<IPagedList<IAccount>> {
            return api.apiPost(`accounts-getAll`, {
              pageIndex,
              pageSize,
                    cityId: city ? city.id : null,
                    statuses: [EntityStatus.published, EntityStatus.draft]
                });
            },
            sendInvite(accountId: string): Promise<void> {
                return api.apiPost('accounts-sendInvite', {
                    id: accountId
                });
            }
        };
    })(this);

    public clubs = ((api: this) => {
        return {
            getAll(city?: ICityModel, pageIndex?: number, pageSize?: number): Promise<IPagedList<IClubModel>> {
                return api.apiPost(`clubs-getAll`, {
                    pageIndex,
                    pageSize,
                    cityId: city ? city.id : null,
                    statuses: [EntityStatus.published, EntityStatus.draft]
                });
            }
        };
    })(this);

    public clients = ((api: this) => {
        return {
            prolong(id: string, fromDate?: string, toDate?: string, purchasedAt?: number, visitCount?: number): Promise<any> {
                return api.apiPost(`clientSubscriptions-prolong`, {
                    id,
                    fromDate,
                    toDate,
                    purchasedAt,
                    visitCount
                });
            },
            getByGroup(groupId: string): Promise<IClientModel[]> {
                return api.apiPost('clients-getByGroup', { groupId });
            },
          returnSubscriptionFromArchive(id: string): Promise<any> {
            return api.apiPost(`clientSubscriptions-returnFromArchive`, { id });
          },
        }
    })(this);

    public groups = ((api: this) => {
        return {
            get(id: string): Promise<IGroupModel> {
                return api.apiPost('group-get', { id });
            },
            getByClub(clubId, incrementRequestCount: boolean = true): Promise<IGroupModel[]> {
                return api.apiPost('group-getByClub', { clubId }, 'result', 'v2/crm/', false, incrementRequestCount);
            },
            save(model: IGroupModel): Promise<void> {
                return api.apiPost('group-save', { model });
            },
            addUser(groupId: string, userId: string): Promise<void> {
                return api.apiPost('group-addUser', { groupId, userId });
            },
            getByClass(classId: string, classPrototypeId: string, startDate: any): Promise<any> {
                return api.apiPost('group-getByClass', { classId, classPrototypeId, startDate });
            },
            addClass(groupId: string, classId: string): Promise<void> {
                return api.apiPost('group-addClass', { classId, groupId });
            },
            addSingleClass(groupId: string, classPrototypeId: string, startDate: number): Promise<any> {
              return api.apiPost('group-addSingleClass', { groupId, classPrototypeId, startDate });
            },
            addClassPrototype(groupId: string, classPrototypeId: string, startDate: number): Promise<void> {
                return api.apiPost('group-addClassPrototype', { groupId, classPrototypeId, startDate });
            },
            removeUser(groupId: string, userId: string): Promise<void> {
                return api.apiPost('group-removeUser', { groupId, userId });
            },
            removeClass(groupId: string, classId: string, classPrototypeId: string, startDate: number, removeClassPrototype: boolean): Promise<any> {
                return api.apiPost('group-removeClass', { groupId, classId, classPrototypeId, startDate, removeClassPrototype });
            }
        }
    })(this);

    public schedule = ((api: this) => {
        return {
            getByClass(classId: string, classPrototypeId: string, startDate: any, cropName: string, incrementRequestCount: boolean = true): Promise<any> {
                return api.apiPost('classVisit-getByClass', { classId, classPrototypeId, startDate, cropName }, 'result', 'v2/crm/', false, incrementRequestCount)
            },
            getByGroup(classId: string, classPrototypeId: string, startDate: any, groupId: string, cropName: string): Promise<any> {
                return api.apiPost('classVisit-getByGroup', { groupId, classId, classPrototypeId, startDate, cropName });
            },
            createVisitForGroupUser(model, incrementRequestCount: boolean = true): Promise<any> {
              return api.apiPost('classVisit-CreateVisitForGroup', model, 'result', 'v2/crm/', false, incrementRequestCount, true, false);
            },
            confirmForGroupUser(model, handleResult: boolean = true): Promise<any> {
                return api.apiPost('classVisit-confirmForGroupUserByClub', model, 'result', 'v2/crm/', false, false, true, handleResult);
            },
            missForGroupUser(model, handleResult: boolean = true): Promise<any> {
                return api.apiPost('classVisit-missForGroupUserByClub', model, 'result', 'v2/crm/', false, false, true, handleResult);
            },
            cancelForGroupUser(model, handleResult: boolean = true): Promise<any> {
                return api.apiPost('classVisit-cancelForGroupUserByClub', model, 'result', 'v2/crm/', false, false, true, handleResult);
            }
        }
    })(this);

    public clientOrders = ((api: this) => {
        return {
            getClientOrder(id: string): Promise<any> {
                return api.apiPost(`clientOrders-getByClientSubscription`, { id });
            },
            createPayment(model): Promise<any> {
                return api.apiPost('clientOrders-createPayment', model);
            }
        }
    })(this);

    public registry = ((api: this) => {
      return {
        get(): Promise<any> {
          return api.apiPost('registry-get');
        },
        getById(id: string): Promise<any> {
          return api.apiPost('registry-getById', { id });
        },
        getElementByRegistryId(registryId: string): Promise<any> {
          return api.apiPost('registry-getByRegistryId-element', { id: registryId });
        },
        getElementById(id: string): Promise<any> {
          return api.apiPost('registry-getById-element', { id });
        },
        add(req: IRegistryParameter): Promise<any> {
          return api.apiPost(`registry-create`, req)
        },
        edit(req: RegistryElement): Promise<any> {
          return api.apiPost('registry-edit', req)
        },
        remove(id: string): Promise<boolean> {
          return api.apiPost('registry-remove', { id })
        },
        removeElement(id: string): Promise<boolean> {
          return api.apiPost('registry-remove-element', { id })
        },
        removeElements(registryId: string, clubId: string): Promise<boolean> {
          return api.apiPost('registry-remove-elements-byClub', { RegistryId: registryId, ClubId: clubId })
        },
        save(req: Registry): Promise<any> {
          return api.apiPost('registry-save', req)
        },
        addElement(req: any): Promise<any> {
          return api.apiPost('registry-add-element', req)
        },
        getByClub(id: string): Promise<any> {
          return api.apiPost('registry-get-byClubId', { id });
        },
        getElementsByClub(req: any): Promise<any> {
          return api.apiPost('registry-getElements-byClubId', req);
        },
        getLegalInfoByClubIds(ids: string[]): Promise<any> {
          return api.apiPost('getLegalInfo-byClubIds', { ids })
        },
        registryPaymentOrder(req: any) {
          return api.apiPost('registryPaymentOrder', req, null, 'v2/crm/', false, true, true, true, null, ResponseContentType.Blob);
        },
        summaryReport(req: any): Promise<any> {
          return api.apiPost('registry-summaryReport', req)
        },
        detailedRegister(req: any): Promise<any> {
          return api.apiPost('registry-detailedRegister', req)
        }
      }
    })(this);

  public collections = ((api: this) => {
    return {
      getAll(): Promise<Collections[]> {
        return api.apiPost('CollectionsGetAll');
      },
      getById(id: string): Promise<Collections> {
        return api.apiPost('CollectionsGetById', { id });
      },
      getByCityId(id: string): Promise<Collections[]> {
        return api.apiPost('CollectionsGetByCityId', { id });
      },
      getByType(type: CollectionsTypes): Promise<Collections[]> {
        return api.apiPost('CollectionsGetByType', { type });
      },
      save(req: Collections): Promise<Collections> {
        return api.apiPost('CollectionsSave', req)
      },
      remove(id: string): Promise<boolean> {
        return api.apiPost('CollectionsRemove', { id })
      },
      collectionsGetSubscriptions(ids: string[]): Promise<any> {
        return api.apiPost('CollectionsGetSubscriptions', { ids })
      },
      collectionsGetCourses(ids: string[]): Promise<any> {
        return api.apiPost('CollectionsGetCourses', { ids })
      },
      collectionsGetSubscriptionVariants(ids: string[]): Promise<any> {
        return api.apiPost('CollectionsGetSubscriptionVariants', { ids })
      },
      saveSorting(req: any[]): Promise<void> {
        return api.apiPost('CollectionsSaveSorting', req)
      }
    }
  })(this);

  public accounting = ((api: this) => {
    return {
      getAll(): Promise<Accounting[]> {
        return api.apiPost('AccountingGetAll');
      },
      getById(id: string): Promise<Accounting> {
        return api.apiPost('AccountingGetById', { id });
      },
      getByClubId(id: string): Promise<Accounting[]> {
        return api.apiPost('AccountingGetByClubId', { id });
      },
      save(req: Accounting): Promise<Accounting> {
        return api.apiPost('AccountingSave', req)
      },
      remove(id: string): Promise<boolean> {
        return api.apiPost('AccountingRemove', { id })
      }
    }
  })(this);

  public certificates = ((api: this) => {
    return {
      get(req: any): Promise<ICertificateList> {
        return api.apiPost('CertificatesGetAll', req);
      },
      changeStatusCertificate(req: any): Promise<boolean> {
        return api.apiPost('ChangeStatusCertificate', req);
      }
    }
  })(this);

  public electronicQueue = ((api: this) => {
    return {
      getByClubId(id: string): Promise<ElectronicQueue[]> {
        return api.apiPost('QueueGetByClubId', { id });
      }
    }
  })(this);

  public clubDownTime = ((api: this) => {
    return {
      getById(id: string): Promise<ClubDownTime> {
        return api.apiPost('ClubDownTimeGetById', { id });
      },
      getByClubId(req: ClubDownTimeGetByClubReq): Promise<ClubDownTime[]> {
        return api.apiPost('ClubDownTimeGetByClubId', req);
      },
      save(req: any): Promise<ClubDownTime> {
        return api.apiPost('ClubDownTimeSave', req)
      },
      remove(id: string): Promise<boolean> {
        return api.apiPost('ClubDownTimeRemove', { id })
      }
    }
  })(this);

  public graphs = ((api: this) => {
    return {
      getGraphsSummary(clubId: string, startTime?: string, endTime?: string): Promise<GraphsSummary> {
        return api.apiPost('graphs/GraphsSummary', { clubId: clubId, startTime: startTime, endTime: endTime });
      },
    }
  })(this);

  public quickRecord = ((api: this) => {
    return {
      booking(req: any): Promise<any> {
        return api.apiPost('classVisit-quickRecord', req);
      }
    }
  })(this);

  public ResourceQueue = ((api: this) => {
    return {
      getIssueOrReturn(clubId: string): Promise<any[]> {
        return api.apiPost('resourceQueue/IssueOrReturn', { id: clubId });
      },
      setIssue(clubIds: string[], classRequestId: string): Promise<boolean> {
        return api.apiPost('resourceQueue/SetIssue', { Ids: clubIds, classRequestId: classRequestId });
      },
      setReturn(clubIds: string[], classRequestId: string): Promise<boolean> {
        return api.apiPost('resourceQueue/SetReturn', { Ids: clubIds, classRequestId: classRequestId });
      },
      getIssued(clubId: string): Promise<any> {
        return api.apiPost('resourceQueue/GetIssued', { id: clubId });
      },
      setReturnByClientId(clientId: string): Promise<boolean> {
        return api.apiPost('resourceQueue/SetReturnByClientId', { Id: clientId });
      }
    }
  })(this);

  public webhook = ((api: this) => {
    return {
      getAll(): Promise<IWebhookModel[]> {
        return api.apiPost('WebhookGetAll', { });
      },
      getById(id: string): Promise<IWebhookModel> {
        return api.apiPost('WebhookGetById', { id });
      },
      findByClubId(id: string): Promise<IWebhookModel[]> {
        return api.apiPost('WebhookFindByClubId', { id });
      },
      setStatus(req: any): Promise<any> {
        return api.apiPost('WebhookSetStatus', req);
      },
      save(req): Promise<any> {
        return api.apiPost('WebhookSave', req);
      }
    }
  })(this);

    constructor(
        private http: HttpService
    ) {
        this.requestCounter = <BehaviorSubject<number>>new BehaviorSubject(0);
    };

    public get isBusy(): Observable<boolean> {
        return this.requestCounter.pipe(map(res => res !== 0));
    }

    private errorHandler(e) {
        throw new Error((`Запрос ${e.url} завершился неуспешно: ${e.status} ${e.statusText}`));
    }

    private increment() {
        this.requestCounter.next(this.requestCounter.value + 1);
    }

    private decrement() {
        this.requestCounter.next(Math.max(this.requestCounter.value - 1, 0));
    }

    private apiPost<T>(url: string, params?: any, key: string = 'result', prefix: string = 'v2/crm/', isForm = false,
            incrementRequestCount: boolean = true, handleError: boolean = true, handleResult: boolean = true, options: any = null, responseType: ResponseContentType = null
    ): Promise<any> {
      incrementRequestCount && this.increment();
      return responseType !== ResponseContentType.Blob ? this.http.post(url, params, options, isForm, prefix, handleError, handleResult, responseType).pipe(
        map(x => {
          if (x.text().length > 0) { return (key ? x.json()[key] : x.json()) as T; }
          return x.status;
        }))
        .toPromise()
        .then(r => {
          incrementRequestCount && this.decrement();
          return r;
        })
        .catch(e => {
          incrementRequestCount && this.decrement();
          this.errorHandler(e);
        }) : this.http.post(url, params, options, isForm, prefix, handleError, handleResult, responseType)
        .toPromise()
        .then(r => {
          incrementRequestCount && this.decrement();
          return r;
        }).catch(e => {
          incrementRequestCount && this.decrement();
          this.errorHandler(e);
        });
    }

    // Преобразует json-представление модели в form data, помещая изображения из medias в отдельные поля
    private modelToFormData<T extends { medias: IMedias }>(model: T) {
        const formData: FormData = new FormData();
        const data: T = _.cloneDeep(model);

        if (data.medias && data.medias.images) {
            data.medias.images
                .reduce((fd, image, index) => {
                    image.body = null;
                    image.filename = null;
                    image.file = null;
                    image.url = `image${index}`;
                    if (model.medias.images[index].file) {
                        fd.append(
                            `image${index}`,
                            model.medias.images[index].file,
                            model.medias.images[index].file.name
                        );
                    }
                    return fd;
                }, formData);
        }

        formData.append('data', JSON.stringify(data));
        return formData;
    }
}
