import { ApiV2Service } from './../../../common/services/api-v2.service';
import {Component, Injector, ViewChild, OnInit, OnDestroy, AfterViewChecked, HostListener} from '@angular/core';
import { Validators, AbstractControl, FormControl } from '@angular/forms/';
import {Router, ActivatedRoute, RouterEvent, NavigationEnd} from '@angular/router';
import { ClubSetting } from 'app/common/models/club-setting.model';
import { ClubSettingService } from 'app/components/club-settings/club-settings.service';
import { IClassItemModel } from 'app/common/models/class-item.model';
import { timeValidator } from 'app/common/validators/time.validator';
import { dateValidator } from 'app/common/validators/date.validator';
import { numberValidator } from 'app/common/validators/number.validator';
import { ScheduleService } from 'app/components/schedule/services/schedule.service';
import { EditItemComponent } from 'app/common/edit-item.component';
import { ICoachModel } from 'app/common/models/coach.model';
import { IClientModel } from 'app/common/models/client.model';
import { ClientsService } from 'app/components/clients/services/clients.service';
import { GroupsService } from 'app/components/groups/services/groups.service';
import { HotkeysService, Hotkey } from 'custom-modules/angular2-hotkeys';
import { ApiService } from 'app/common/services/api.service';
import { EntityStatus } from 'app/common/models/entity-status.model';
import { IGroupModel } from 'app/common/models/group.model';
import { isGuidEmpty } from 'app/common/validators/guid-not-empty';
import { AccountStatus } from 'app/common/models/account-status.model';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { animate, style, transition, trigger } from '@angular/animations';
import {FormGroup} from '@angular/forms';
import {ClubDownTime} from '../../../common/models/club-down-time.model';
import * as helpers from 'app/common/helpers.functions';

@Component({
  selector: 'app-class-item-edit',
  templateUrl: './class-item-edit.component.html',
  styleUrls: ['./class-item-edit.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.2s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.2s', style({ opacity: 0 }))
      ])
    ])
  ],
})

export class ClassItemEditComponent extends EditItemComponent<IClassItemModel> implements OnInit, OnDestroy, AfterViewChecked {
  public static readonly componentName: string = 'ClassItemEditComponent';

  public coaches: Array<ICoachModel> = [];
  public groups: Array<IGroupModel> = [];
  public clubDownTime: ClubDownTime[] = [];
  public classGroups: any[];
  public groupVisits: any[];
  private courses = [];
  public rooms = [];
  public clients = [];
  public isClientsScheduled: boolean;
  public selectedClient;
  public clientSearchList = [];
  public clubSettings: ClubSetting[];
  public classTimeStamp: number;
  public subscriptionPlans: any[];
  public scrollPosition: number;
  public activeTabFromSchedule: number;
  public isShowDetails = true;
  protected hkCancel: Hotkey | Hotkey[];
  private sourceModel: IClassItemModel;
  private modelChanged: boolean;
  private isHideBtnDoТotWrite = false;
  private isDisableConfirmSignWithoutSubscr = false;
  private isClassVisitClientAvailable = false;
  private sortOrders: any[] = [];
  public isNeedSort = "";
  public ordersSorting = [];
  public groupControl = new FormControl('');
  public formForSort: any;
  public isVirtualToRealityClass = false;
  public isWaitingToReality = false;

  @ViewChild('searchBox') searchBox;
  @ViewChild('selectItem') selectItem;

  fieldMessages = {
    startDate: {
      required: 'Это поле обязательно',
      dateValidator: 'Введите дату в формате дд.мм.гггг между 01.01.1900 и 01.01.2100'
    },
    startTime: {
      required: 'Это поле обязательно',
      timeValidator: 'Введите время в формате чч:мм'
    },
    duration: {
      required: 'Это поле обязательно',
      numberValidator: 'Это поле должно быть числом от 1 до 10 000'
    },
    onlineType: {
      required: 'Это поле обязательно',
    },
    streamUrl: {
      required: 'Это поле обязательно',
    },
    comment: {
      required: 'Это поле обязательно'
    },
    normCapacity: {
      required: 'Это поле обязательно',
      numberValidator: 'Это поле должно быть числом от 1 до 1000'
    },
    maxCapacity: {
      required: 'Это поле обязательно',
      numberValidator: 'Это поле должно быть числом от 0 до 1000'
    },
    level: {
      required: 'Необходимо выбрать уровень'
    },
    course: {
      required: 'Это поле обязательно',
      requiredCourse: 'Это поле обязательно'
    },
  };

  levels = [];

  constructor(
    injector: Injector,
    protected service: ScheduleService,
    private apiService: ApiService,
    private clientsService: ClientsService,
    private groupsService: GroupsService,
    public router: Router,
    public route: ActivatedRoute,
    protected location: Location,
    private settingsService: ClubSettingService,
    private apiV2Service: ApiV2Service
  ) {
    super(injector, service);
    this.hotkeysService = injector.get(HotkeysService);
    this.onControlChange = this.onControlChange.bind(this);
  }

  // ----- Методы для сортировки
  public sortOptionChange(val: string) {
    let _int = 1;
    this.getOrderSort();
    let _order = this.sortOrders;

    if (val == "") {
      this.isNeedSort = "-1";
    }

    if (val != "-1") {
      if (_order.length == 0) {
        let _arr = this.groupVisits;
        this.groupVisits = this.sortStatus(_arr, _int);
        this.sortOrders.push(["sortStatus", "asc"]);
        this.saveOrderSort();
        return;
      }
      else if (_order.length > 0 && val == "") {
        if (_order[0][1] == "asc") {
          _int = 1;
        }
        else {
          _int = -1;
        }
        switch (_order[0][0]) {
          case "sortClient":
            this.groupVisits.sort((a, b) => {
              if (a.firstName < b.firstName) { return -_int; }
              if (a.firstName > b.firstName) { return _int; }
              if (a.lastName < b.lastName) { return -_int; }
              if (a.lastName > b.lastName) { return _int; }
            });
            break;
          case "sortSubscription":
            let _tupaboniments = [];
            this.groupVisits.forEach(x => {
              _tupaboniments.push([
                x.clientId,
                x.subscriptions.filter(e => e.isSelected == true)[0],
                x.firstName
              ]);
            });
            let _nullaboniments = [];
            let _noaboniments = [];
            let _otheraboniments = [];
            _tupaboniments.forEach(i => {
              if (i[1] == null) {
                _nullaboniments.push(i);
              }
              else if (i[1].name == "Без абонемента") {
                _noaboniments.push(i);
              }
              else {
                _otheraboniments.push(i);
              }
            });
            //
            _nullaboniments.sort((a, b) => {
              if (a[2] < b[2]) { return -_int; }
              if (a[2] > b[2]) { return _int; }
              return 0;
            });
            _noaboniments.sort((a, b) => {
              if (a[2] < b[2]) { return -_int; }
              if (a[2] > b[2]) { return _int; }
              return 0;
            });
            _otheraboniments.sort((a, b) => {
              if (a[1].name < b[1].name) { return -_int; }
              if (a[1].name > b[1].name) { return _int; }
              if (a[2] < b[2]) { return -_int; }
              if (a[2] > b[2]) { return _int; }
              return 0;
            });
            //
            let _resarray = [];
            if (_int == 1) {
              _nullaboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
              _noaboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
              _otheraboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
            }
            else {
              _otheraboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
              _noaboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
              _nullaboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
            }
            this.groupVisits = _resarray;
            break;
          case "sortFrom":
            let _gr: any[] = [];
            this.groupVisits.forEach(x => {
              _gr.push([
                x.clientId,
                x.groups.map(s => s.name).join(";"),
                x.firstName
              ])
            });
            let _res: any[] = [];
            _gr.forEach(s => {
              if (s[1] == "Индивидуальная") {
                _res.push(s[0]);
              }
            });
            _gr = _gr.sort((a, b) => {
              if (a[2] < b[2]) { return -_int; }
              if (a[2] > b[2]) { return _int; }
              return 0;
            });
            _gr = _gr.filter(item => item[1] !== "Индивидуальная");
            _gr = _gr.sort((a, b) => {
              if (a[1] < b[1]) { return -_int; }
              if (a[1] > b[1]) { return _int; }
              if (a[2] < b[2]) { return -_int; }
              if (a[2] > b[2]) { return _int; }
              return 0;
            });
            let _t: any[] = [];
            if (_int == 1) {
              _res.forEach(f => {
                this.groupVisits.forEach(v => {
                  if (f == v.clientId) {
                    _t.push(v);
                  }
                })
              });
              _gr.forEach(f => {
                this.groupVisits.forEach(v => {
                  if (f[0] == v.clientId) {
                    _t.push(v);
                  }
                })
              });
            }
            else {
              _gr.forEach(f => {
                this.groupVisits.forEach(v => {
                  if (f[0] == v.clientId) {
                    _t.push(v);
                  }
                })
              });
              _res.forEach(f => {
                this.groupVisits.forEach(v => {
                  if (f == v.clientId) {
                    _t.push(v);
                  }
                })
              });
            }
            this.groupVisits = _t;
            break;
          case "sortStatus":
            let _arr = this.groupVisits;
            this.groupVisits = this.sortStatus(_arr, _int);
            break;
        }
      }
      else {
        if (val != "after") {
          if (_order[0][1] == "asc") {
            _int = -1;
            _order[0][0] = val;
            _order[0][1] = "desc";
          }
          else {
            _int = 1;
            _order[0][0] = val;
            _order[0][1] = "asc";
          }
        }

        switch (val) {
          case "sortClient":
            this.groupVisits.sort((a, b) => {
              if (a.firstName < b.firstName) { return -_int; }
              if (a.firstName > b.firstName) { return _int; }
              if (a.lastName < b.lastName) { return -_int; }
              if (a.lastName > b.lastName) { return _int; }
            });
            break;
          case "sortSubscription":
            let _tupaboniments = [];
            this.groupVisits.forEach(x => {
              _tupaboniments.push([
                x.clientId,
                x.subscriptions.filter(e => e.isSelected == true)[0],
                x.firstName
              ]);
            });
            let _nullaboniments = [];
            let _noaboniments = [];
            let _otheraboniments = [];
            _tupaboniments.forEach(i => {
              if (i[1] == null) {
                _nullaboniments.push(i);
              }
              else if (i[1].name == "Без абонемента") {
                _noaboniments.push(i);
              }
              else {
                _otheraboniments.push(i);
              }
            });
            //
            _nullaboniments.sort((a, b) => {
              if (a[2] < b[2]) { return -_int; }
              if (a[2] > b[2]) { return _int; }
              return 0;
            });
            _noaboniments.sort((a, b) => {
              if (a[2] < b[2]) { return -_int; }
              if (a[2] > b[2]) { return _int; }
              return 0;
            });
            _otheraboniments.sort((a, b) => {
              if (a[1].name < b[1].name) { return -_int; }
              if (a[1].name > b[1].name) { return _int; }
              if (a[2] < b[2]) { return -_int; }
              if (a[2] > b[2]) { return _int; }
              return 0;
            });
            //
            let _resarray = [];
            if (_int == 1) {
              _nullaboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
              _noaboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
              _otheraboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
            }
            else {
              _otheraboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
              _noaboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
              _nullaboniments.forEach(n => {
                this.groupVisits.forEach(f => {
                  if (f.clientId == n[0]) {
                    _resarray.push(f);
                  }
                })
              });
            }
            this.groupVisits = _resarray;
            break;
          case "sortFrom":
            let _gr: any[] = [];
            this.groupVisits.forEach(x => {
              _gr.push([
                x.clientId,
                x.groups.map(s => s.name).join(";"),
                x.firstName
              ])
            });
            let _res: any[] = [];
            _gr.forEach(s => {
              if (s[1] == "Индивидуальная") {
                _res.push(s[0]);
              }
            });
            _gr = _gr.sort((a, b) => {
              if (a[2] < b[2]) { return -_int; }
              if (a[2] > b[2]) { return _int; }
              return 0;
            });
            _gr = _gr.filter(item => item[1] !== "Индивидуальная");
            _gr = _gr.sort((a, b) => {
              if (a[1] < b[1]) { return -_int; }
              if (a[1] > b[1]) { return _int; }
              if (a[2] < b[2]) { return -_int; }
              if (a[2] > b[2]) { return _int; }
              return 0;
            });
            let _t: any[] = [];
            if (_int == 1) {
              _res.forEach(f => {
                this.groupVisits.forEach(v => {
                  if (f == v.clientId) {
                    _t.push(v);
                  }
                })
              });
              _gr.forEach(f => {
                this.groupVisits.forEach(v => {
                  if (f[0] == v.clientId) {
                    _t.push(v);
                  }
                })
              });
            }
            else {
              _gr.forEach(f => {
                this.groupVisits.forEach(v => {
                  if (f[0] == v.clientId) {
                    _t.push(v);
                  }
                })
              });
              _res.forEach(f => {
                this.groupVisits.forEach(v => {
                  if (f == v.clientId) {
                    _t.push(v);
                  }
                })
              });
            }
            this.groupVisits = _t;
            break;
          case "sortStatus":
            let _arr = this.groupVisits;
            this.groupVisits = this.sortStatus(_arr, _int);
            break;
        }
      }
      this.ordersSortSave();
    }
    else {
      this.ordersSortLoad();
    }
    this.saveOrderSort();
  }

  ordersSortSave() {
    let _arr = [];
    this.isNeedSort = "-1";
    this.groupVisits.forEach(i => {
      if (i) {
        _arr.push(i.userId);
      }
    });
    this.ordersSorting = _arr;
  }
  ordersSortLoad() {
    // Необходимо для добавления новых пол-ей/групп
    if (this.groupVisits) {
      if (this.groupVisits.length < this.ordersSorting.length) { // Для удаления групп
        // this.ordersSorting = [];
        // this.groupVisits.forEach(c => {
        //   this.ordersSorting.unshift(c.userId);
        // });
        let _o = [];
        this.ordersSorting.forEach(x => {
          if (this.groupVisits.some(i => i.userId === x)) {
            //this.ordersSorting.unshift(x.userId);
            _o.push(x);
          }
        });
        this.ordersSorting = _o;
      }
      else {
        this.groupVisits.forEach(x => {
          if (!this.ordersSorting.some(i => i === x.userId)) {
            this.ordersSorting.unshift(x.userId);
          }
        });
      }
    }
    // Востанавливам порядок сортировки
    let _arr = [];
    if (this.ordersSorting) {
      this.ordersSorting.forEach(x => {
        let _ = this.groupVisits.filter(u => u.userId === x)[0];
        _arr.push(_);
      });
    }
    this.groupVisits = _arr;
  }

  sortStatus(array: any[], order: number) {
    let _blue = [];
    let _green = [];
    let _red = [];
    let _queued = [];
    let _purple = [];
    array.forEach(i => {
      let _code = this.convertStatusText(i.currentVisitStatus);
      if (_code == 100 || _code == 600) {
        _blue.push(i);
      }
      if (_code == 200 || _code == 210 || _code == 300 || _code == 430 || _code == 530) {
        _green.push(i);
      }
      if (_code == 220 || _code == 230 || _code == 400 || _code == 410 || _code == 420 || _code == 800 || _code == 900) {
        _red.push(i);
      }
      if (_code == 500 || _code == 510 || _code == 520 || _code == 540) {
        _purple.push(i);
      }
      if (_code == 700) {
        _queued.push(i);
      }
    });
    _blue.sort((a, b) => {
      if (a.firstName < b.firstName) { return -order; }
      if (a.firstName > b.firstName) { return order; }
      if (a.lastName < b.lastName) { return -order; }
      if (a.lastName > b.lastName) { return order; }
    });
    _purple.sort((a, b) => {
      if (a.firstName < b.firstName) { return -order; }
      if (a.firstName > b.firstName) { return order; }
      if (a.lastName < b.lastName) { return -order; }
      if (a.lastName > b.lastName) { return order; }
    });
    _green.sort((a, b) => {
      if (a.firstName < b.firstName) { return -order; }
      if (a.firstName > b.firstName) { return order; }
      if (a.lastName < b.lastName) { return -order; }
      if (a.lastName > b.lastName) { return order; }
    });
    _red.sort((a, b) => {
      if (a.firstName < b.firstName) { return -order; }
      if (a.firstName > b.firstName) { return order; }
      if (a.lastName < b.lastName) { return -order; }
      if (a.lastName > b.lastName) { return order; }
    });
    _queued.sort((a, b) => {
      return a.updatedAt > b.updatedAt ? -1 : 1;
    });
    let _result = [];
    if (order == 1) {
      _result.push(..._blue);
      _result.push(..._green);
      _result.push(..._purple);
      _result.push(..._red);
    }
    if (order == -1) {
      _result.push(..._purple);
      _result.push(..._red);
      _result.push(..._green);
      _result.push(..._blue);
    }
    _result.push(..._queued);
    return _result;
  }

  public changeSortOptionTable(event: any) {
    let sort = event.target.value;
    sort = sort.split('-');
    this.sortOrders[0] = [sort[0], sort[1]]
    this.saveOrderSort();
    this.sortOptionChange(sort[0])
  }

  convertStatusText(text: string) {
    switch (text) {
      case 'Booked': return 100;
      case 'Queued': return 700;
      case 'WaitingBookingConfirmation': return 600;

      case 'QueuedCancelledByUser': return 800;
      case 'QueuedCancelledByClub': return 900;

      case 'VisitedByClub': return 200;
      case 'VisitedByClubConflict': return 210;
      case 'Visited': return 300;
      case 'CancelledByUserConflict': return 430;
      case 'MissedByUserConflict': return 530;

      case 'VisitedByUser': return 220;
      case 'VisitedByUserConflict': return 230;
      case 'CancelledByClub': return 400;
      case 'CancelledByClubConflict': return 410;
      case 'CancelledByUser': return 420;
      case 'MissedByClub': return 500;
      case 'MissedByClubConflict': return 510;
      case 'MissedByUser': return 520;
      case 'Missed': return 540;
    }
  }

  public sortArrow(field: string) {
    let _arrSort = this.sortOrders;
    if (_arrSort) {
      if (_arrSort[0][0] == field) {
        if (_arrSort[0][1] == 'desc')
          return 'sort-desc';
        else
          return 'sort-asc';
      }
    }
  }

  saveOrderSort() {
    localStorage.setItem('tableOrderSort', JSON.stringify(this.sortOrders));
  }
  removeOrderSort() {
    localStorage.removeItem('tableOrderSort');
  }
  getOrderSort() {
    this.sortOrders = JSON.parse(localStorage.getItem('tableOrderSort')) || [];
  }
  // -----

  ngAfterViewChecked(): void {
    this.onControlChange();
  }

  public hkCancelPress(that: any) {
    return (event: KeyboardEvent) => {
      if (that.Model && that.Model.id != null) {
        that.confirmCancel();
      }

      event.preventDefault();
      return true;
    };
  }

  public hkSavePress(that: this) {
    return (event: KeyboardEvent) => {
      if (this.modelChanged)
        that.confirmDate(true);

      event.preventDefault();
      return true;
    };
  }

  buildForm() {
    this.form = this.formBuilder.group({
      entityStatus: [this.Model.entityStatus, [Validators.required]],
      startDate: [this.Model.startDate, [Validators.required, dateValidator()]],
      startTime: [this.Model.startTime, [Validators.required, timeValidator()]],
      duration: [this.Model.duration, [Validators.required, numberValidator(1, 10000)]],
      room: [this.Model.room || { id: null }, []],
      coach: [this.Model.coach || { id: null }, []],
      course: [this.Model.course, [Validators.required, (c: FormControl) => { return c?.value?.id !== null ? null : { requiredCourse: { valid: false } } }]],
      level: [this.Model.level],
      isNewGroup: [this.Model.isNewGroup, [Validators.required]],
      isHideClassForClients: [this.Model.isHideClassForClients, [Validators.required]],
      normCapacity: [this.Model.normCapacity, [/*Validators.required, numberValidator( 1, 1000 )*/]],
      maxCapacity: [this.Model.maxCapacity, [Validators.required, numberValidator(0, 1000)]],
      comment: [this.Model.comment, []],
      isNotRecurrent: [this.Model.isNotRecurrent, [Validators.required]],
      onlineType: [this.Model.onlineType, [Validators.required]],
      streamUrl: [this.Model.streamUrl, []],
      userIds: [this.Model.userIds, []],
      isDenyRecording: [this.Model.isDenyRecording, []],
      messageDenyRecording: [{ value: this.Model.messageDenyRecording, disabled: !this.Model.isDenyRecording }, []]
    });

    this.form.valueChanges.subscribe(this.onControlChange);
  }

  modelTemplate(): Promise<IClassItemModel> {
    const persistenceModel = this.persistenceService.getModel();
    if (persistenceModel) {
      return Promise.resolve(persistenceModel);
    }

    return Promise
      .all([
        this.service.getCourses(),
        this.service.getCoaches(),
        this.service.getRooms(),
      ])
      .then(() => {
        const res: IClassItemModel = {
          id: null,
          entityStatus: EntityStatus.draft,
          startDate: this.routingParams.startDate,
          startTime: this.routingParams.startTime.replace('.', ':'),
          duration: 60,
          course: { id: null, color: null, name: null },
          coach: { id: null, color: null, name: null },
          room: { id: null, color: null, name: null },
          level: '',
          isNewGroup: false,
          normCapacity: 15,
          maxCapacity: 20,
          comment: null,
          isNotRecurrent: null,
          onlineType: 'none',
          isOnline: false,
          isOpenair: false,
          isHideClassForClients: false,
          notifyBookedUsers: false,
          prototypeId: null,
          numberOfVisits: 0,
          numberOfQueued: 0,
          numberOfBookedVisits: 0,
          userIds: [],
          classStatus: 'available',
          isDenyRecording: false,
          messageDenyRecording: null,
          notifyBookedUserIds: [],
          isShowInSchedule: false
        };

        this.route.queryParams.subscribe(params => {
          if (params.courseId && params.userId) {
            const index = this.courses.findIndex(x => x.id === params.courseId);
            const course = this.courses[index];
            res.course = course;
            res.userIds.push(params.userId);
            this.location.replaceState(this.location.path().slice(0, this.location.path().indexOf('?')));
          }
        });

        return res;
      });
  }

  public addExternalEntity(entityType: string) {
    for (const i in this.form.value) {
      this.Model[i] = this.form.value[i];
    }
    this.persistenceService.addEntity(entityType, this.Model);
  }

  public addCoach() {
    this.addExternalEntity('clubcoaches');
  }

  public addCourse() {
    this.addExternalEntity('clubcourses');
  }

  public addClubroom() {
    this.addExternalEntity('clubrooms');
  }

  isVirtual(): boolean {
    return this.Model.id === '00000000000000000000000000000000';
  }

  isVirtualId(id): boolean {
    return id === '00000000000000000000000000000000';
  }

  public _close() {
    const isCameOutOfSchedule = this.scrollPosition === 0 && this.activeTabFromSchedule === 0;
    this.setHideDetails();

    if (isCameOutOfSchedule) {
      history.go(-1);
      return;
    }

    if (this.isVirtual() || this.Model.id == null || this.isVirtualToRealityClass) {
      this.router.navigate(['../../../'], { relativeTo: this.route, queryParams: { scroll: this.scrollPosition, activeTab: this.activeTabFromSchedule } });
    } else {
      this.router.navigate(['../'], { relativeTo: this.route, queryParams: { scroll: this.scrollPosition, activeTab: this.activeTabFromSchedule } });
    }
  }

  // Пока отложили, надо додумать логику для защиты перезагрузки при актуализации
  // @HostListener('window:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   // Проверяем, была ли нажата клавиша F5
  //   if (event.key === 'F5') {
  //     event.preventDefault(); // Останавливаем стандартное поведение (обновление страницы)
  //     console.log('F5 нажата, обновление страницы отменено!');
  //   }
  // }

  ngOnInit() {
    this.scrollPosition = 0;
    this.activeTabFromSchedule = 0;
    this.isNeedSort = "";
    window.innerWidth >= 992 ?
      document.querySelector('.app_container').scrollTop = 0 :
      document.querySelector('.app_container').scrollTop = document.documentElement.scrollTop = 0;

    this.service.getOtherModels()
      .then(() => {
        Promise
          .all([
            this.service.getCoaches(),
            this.service.getCourses(),
            this.service.getRooms(),
            this.service.getLevels()
          ])
          .then(x => {
            this.coaches = x[0];
            this.courses = x[1];
            this.rooms = x[2];
            this.levels = x[3].levels;
          });
        super.ngOnInit();

        this.hotkeysService.remove(this.hkDelete);
        this.hotkeysService.remove(this.hkDelete2);
        this.hkCancel = this.hotkeysService.add(new Hotkey(['shift+del'], this.hkCancelPress(this), []));
      });

    this.getSettingsClub();

    this.apiV2Service.clubDownTime.getByClubId({ clubId: this.contextService.getRoutingParams().clubId, startAt: null, endAt: null })
      .then((clubDownTime) => {
        this.clubDownTime = clubDownTime;
      });

    this.route.queryParams.subscribe(params => {
      if (params.scroll) {
        this.scrollPosition = params.scroll;
        this.activeTabFromSchedule = params.activeTab;
        this.location.replaceState(this.location.path().slice(0, this.location.path().indexOf('?')));
      }
    });
  }

  getSettingsClub(incrementRequestCount: boolean = true) {
    this.settingsService.getClubSettingsByClub(undefined, incrementRequestCount)
      .then(settings => {
        this.clubSettings = settings;
        const alias = this.clubSettings.find(s => s.alias === 'disableConfirmSignUpWithoutSubscription');
        this.isDisableConfirmSignWithoutSubscr = alias.value === 'true';
      });
  }

  ngOnDestroy() {
    this.hotkeysService.remove(this.hkCancel);
  }

  setShowDetails() {
    this.isShowDetails = !this.isShowDetails;
    localStorage.setItem('isShowDetails', JSON.stringify(this.isShowDetails));
  }

  setHideDetails() {
    this.isShowDetails = false;
    localStorage.setItem('isShowDetails', JSON.stringify(this.isShowDetails));
  }

  afterModelInit() {
    this.Model.onlineType = this.Model.isOnline ? 'online' : this.Model.isOpenair ? 'openair' : 'none';

    const _StorageIsShowDetails = localStorage.getItem('isShowDetails');
    if (!_StorageIsShowDetails) {
      localStorage.setItem('isShowDetails', JSON.stringify(this.isShowDetails));
    } else {
      this.isShowDetails = JSON.parse(_StorageIsShowDetails);
    }

    if (!this.Model.id) {
      this.isShowDetails = true;
    }

    if (this.Model.id && !this.isVirtual()) {
      this.getVisits(this.Model.id);
    }

    if (this.Model.id) {
      this.classTimeStamp = Date.parse(this.Model.startDate.split('.').reverse().join('-') + 'T' + this.Model.startTime);

      if (this.Model.course) {
        this.service.getSubscriptions(this.Model.course.id)
          .then(rs => this.subscriptionPlans = rs.subscriptionPlans);
      }
    }

    this.getGroups();
    if (this.isVirtual()) {
      this.service.checkClassByPrototype(this.Model).then((res) => {
        if (!this.isVirtualId(res.classId)) {
          this.virtualToRealityClass(res);
        }
      })
    }

    this.authService.getUserInfo().then(res => {
      this.isClassVisitClientAvailable = this.permissionService.isAvailable(this, 'isClassVisitClientAvailable', res?.role);
      console.debug(res?.role, this.isClassVisitClientAvailable);
    });

    this.sourceModel = Object.assign({}, this.Model);
    this.modelChanged = false;
  }

  onOnlineTypeChange(e) {
    this.Model.onlineType = e;
    this.Model.isOnline = this.Model.onlineType == 'online';
    this.Model.isOpenair = this.Model.onlineType == 'openair';
  }

  confirmDate(isPublished: boolean) {
    if (this.form.invalid) {
      this.isSubmited = true;
      return;
    }

    if (this.Model.id) {
      if (this.isVirtual() || !this.Model.isNotRecurrent) {
        this.alertsService.showConfirm({
          header: 'Вы уверены?',
          message: 'Применить изменения к последующим занятиям или только к этому?',
          buttons: [{
            title: 'Только к этому',
            callback: () => {
              this.Model.isNotRecurrent = true;
              this.form.get('isNotRecurrent').setValue(true);
              this.Model.notifyBookedUserIds = this.groupVisits.map(x => x.currentVisitStatus === 'Booked' ? x.userId : null).filter(x => x != null);
              if (this.isVirtual() || !this.clients.length) {
                this.finishEdit(isPublished);
              } else {
                this.notifyClients(isPublished);
              }
            },
          }, {
            title: 'К этому и последующим',
            callback: () => {
              this.Model.isNotRecurrent = false;
              this.form.get('isNotRecurrent').setValue(false);
              this.Model.notifyBookedUserIds = this.groupVisits.map(x => x.currentVisitStatus === 'Booked' ? x.userId : null).filter(x => x != null);
              if (this.isVirtual() || !this.clients.length) {
                this.finishEdit(isPublished);
              } else {
                this.notifyClients(isPublished);
              }
            }
          }, {
            title: 'Отменить'
          }]
        }
        );
      } else {
        this.notifyClients(isPublished);
      }
    } else {
      this.finishEdit(isPublished);
    }
  }

  confirmCancel(isShowInSchedule = false) {
    if (this.isVirtual() || !this.Model.isNotRecurrent) {
      const _btn = [{
        title: isShowInSchedule ? 'Отменить' : 'Только это',
        callback: () => {
          this.Model.isNotRecurrent = true;
          this.form.get('isNotRecurrent').setValue(true);
          if (this.isVirtual() && !this.groups.length || !this.isVirtual() && !this.clients.length) {
            this.cancelClasses(null, isShowInSchedule);
          } else {
            this.notifyCancelClients(isShowInSchedule);
          }
        }
      }];
      if (!isShowInSchedule) {
        _btn.push({
          title: 'Это и последующие',
          callback: () => {
            this.Model.isNotRecurrent = false;
            this.form.get('isNotRecurrent').setValue(false);
            if (this.isVirtual() && !this.groups.length || !this.isVirtual() && !this.clients.length) {
              this.cancelClasses(null, isShowInSchedule);
            } else {
              this.notifyCancelClients(isShowInSchedule);
            }
          }
        })
      }
      _btn.push({
        title: 'Не отменять',
        callback: null
      });
      this.alertsService.showConfirm({
        header: 'Отменить занятие?',
        buttons: _btn
      });
    } else {
      this.alertsService.showConfirm({
        header: 'Отменить занятие?',
        buttons: [{
          title: 'Отменить',
          callback: () => {
            this.Model.isNotRecurrent = true;
            this.form.get('isNotRecurrent').setValue(true);
            this.notifyCancelClients(isShowInSchedule);
          }
        }, {
          title: 'Не отменять'
        }]
      });
    }
  }

  notifyClients(isPublished: boolean) {
    this.alertsService.showConfirm({
      header: 'Уведомить записанных клиентов об изменении по PUSH и email?',
      buttons: [{
        title: 'Уведомлять',
        callback: () => {
          this.Model.notifyBookedUsers = true;
          this.Model.notifyBookedUserIds = this.groupVisits.map(x => x.currentVisitStatus === 'Booked' ? x.userId : null).filter(x => x != null);
          this.finishEdit(isPublished);
        }
      }, {
        title: 'Не уведомлять',
        callback: () => {
          this.Model.notifyBookedUsers = false;
          this.finishEdit(isPublished);
        }
      }, {
        title: 'Отменить'
      }]
    });
  }

  notifyCancelClients(isShowInSchedule = false) {
    this.alertsService.showConfirm({
      inputs: [{
        name: 'cause',
        required: true,
        title: 'Причина отмены',
      }],
      header: 'Уведомить записанных клиентов об изменении по PUSH и email?',
      buttons: [{
        title: 'Уведомлять',
        callback: (model: any) => {
          this.Model.notifyBookedUsers = true;
          this.Model.notifyBookedUserIds = this.groupVisits.map(x => x.currentVisitStatus === 'Booked' ? x.userId : null).filter(x => x != null);
          this.cancelClasses(model['input.name'], isShowInSchedule);
        }
      }, {
        title: 'Не уведомлять',
        callback: (model: any) => {
          this.Model.notifyBookedUsers = false;
          this.cancelClasses(model['input.name'], isShowInSchedule);
        }
      }, {
        title: 'Отменить'
      }]
    });
  }

  getVisits(classId: string, incrementRequestCount: boolean = true) {
    return this.service.getVisits(classId, incrementRequestCount)
      .then(res => {
        // Проставляем данные полученный от сервера
        this.clients = res.classVisits
          .sort((a, b) => (a.currentVisitStatus.startsWith('Cancelled') && !b.currentVisitStatus.startsWith('Cancelled')) ? 0 : -1);

        this.isClientsScheduled = this.clients && this.clients.find(x => !x.currentVisitStatus.startsWith('Cancelled'));

        // Теперь нужно добавить пункт 'Без абонемента'
        if (this.clients && this.clients.length) {
          this.clients.forEach(visit => {
            visit.disabled = false;
            if (visit.currentVisitStatus?.startsWith('Cancelled'))
              visit.isCanceled = true;
            if (visit.currentVisitStatus == 'QueuedCancelledByUser' || visit.currentVisitStatus == 'QueuedCancelledByClub')
              visit.isCanceled = true;
            if (visit.currentVisitStatus?.startsWith('Missed'))
              visit.isMissed = true;

            if (visit.subscriptions && visit.subscriptions.length) {
              let hasSelection = false;
              visit.subscriptions.forEach(sub => {
                if (sub.isSelected) {
                  hasSelection = true;
                }
                sub.extraInfo = this.SubscriptionExtraInfo(sub);
                sub.disabled = sub.consumedCount == sub.visitCount;
              });

              visit.subscriptions.splice(0, 0, {
                id: '00000000000000000000000000000000',
                isSelected: !hasSelection,
                name: 'Без абонемента',
                isFree: true
              });
            }
          });
        }
        this.clients = this.sortSubscriptions(this.clients)
      });
  }

  public SubscriptionExtraInfo(subscription: any): string {
    moment.locale('ru_RU');
    const date = moment.utc(subscription.toDate).format('L');
    return `( ${subscription.consumedCount} / ${(subscription.visitCount == null || subscription.visitCount >= 10000) ? '∞' : subscription.visitCount} )`
      + (!subscription.toDate ? '' : ' до ' + date);
  }

  cancelClasses(reason?: string, isShowInSchedule: boolean = false) {
    const canceledModel = {
      classId: this.isVirtual() ? this.Model.prototypeId : this.Model.id,
      notifyBookedUsers: this.Model.notifyBookedUsers,
      isNotRecurrent: this.Model.isNotRecurrent,
      updatedAt: new Date((new Date().toUTCString())).getTime(),
      date: this.Model.startDate,
      reason: reason,
      isShowInSchedule
    };
    this.service.cancelClasses(canceledModel, this.isVirtual())
      .then(() => {
        if (this.isVirtual() || this.Model.id == null) {
          this.router.navigate(['../../../'], { relativeTo: this.route, queryParams: { scroll: this.scrollPosition, activeTab: this.activeTabFromSchedule } });
        } else {
          this.router.navigate(['../'], { relativeTo: this.route, queryParams: { scroll: this.scrollPosition, activeTab: this.activeTabFromSchedule } });
        }
      });
  }

  searchClients(input) {
    this.selectedClient = null;
    const pattern = input.trim();

    if (pattern.length < 2) {
      this.clientSearchList = [];
      return;
    } else {
      this.clientsService.searchClients(pattern)
        .then(res =>
          this.clientSearchList = [...res.clients, ...res.users]
        );
    }
  }

  createAndAddBooking() {
    const nameArr = this.searchBox.nativeElement.value.trim().split(' ').filter(item => item !== '');
    if (!nameArr.length) {
      return;
    }

    if (this.Model.id) {
      const bookingModel = {
        userId: null,
        firstName: null,
        lastName: null,
        prototypeId: null,
        startDateTime: null,
        classId: null
      };

      bookingModel.firstName = nameArr[0];

      if (nameArr.length > 1) {
        bookingModel.lastName = nameArr[1];
      }

      this.book(bookingModel);
    } else {
      const bookingModel: IClientModel = {
        id: null,
        entityStatus: EntityStatus.published,
        firstName: null,
        lastName: null,
        fullName: null,
        gender: null,
        phone: null,
        email: null,
        primaryPhotoUrl: null,
        birthday: null,
        updatedAt: null,
        clubRegisteredAt: null,
        systemRegisteredAt: null,
        description: null,
        userId: null,
        accountStatus: AccountStatus.created
      };
      bookingModel.firstName = nameArr[0];
      if (nameArr.length > 1) {
        bookingModel.lastName = nameArr[1];
      }
      this.clientsService.create(bookingModel)
        .then(res => {
          if (res.userId) {
            this.form.patchValue({ userIds: [res.userId] });
            this.finishEdit(true);
          }
        });
    }
  }

  addBooking(e: Event, client) {
    e.stopPropagation();

    if (this.Model.id) {
      const bookingModel = {
        userId: null,
        firstName: null,
        lastName: null,
        prototypeId: null,
        startDateTime: null,
        classId: null
      };

      this.selectedClient = client;
      bookingModel.userId = this.selectedClient.userId ? this.selectedClient.userId : this.selectedClient.id;

      // Сбрасываем все показатели поиска
      this.searchBox.nativeElement.value = '';
      this.clientSearchList = [];

      this.book(bookingModel);
    } else {
      this.form.value.userIds = [client.userId];
      this.finishEdit(true);
    }
  }

  book(bookingModel) {
    // Если занятие "Виртуальное"
    if (this.isVirtual()) {
      bookingModel.prototypeId = this.Model.prototypeId;
      bookingModel.startDateTime = this.classTimeStamp - (new Date().getTimezoneOffset() * 60 * 1000);
    } else {
      bookingModel.classId = this.Model.id;
    }

    // Запрос на бронирование
    this.service.book(bookingModel)
      .then(res => {
        if (this.isVirtual()) {
          this.virtualToRealityClass(res);
        }
        this.getVisits(this.Model.id);
        this.getGroups();

        // Если бронируем за час до занятия сразу ставится отметка о посещении
        /*if (0 < this.classTimeStamp - (new Date((new Date().toUTCString())).getTime()) && this.classTimeStamp - (new Date((new Date().toUTCString())).getTime()) < 3600000) {
          this.confirmVisited({id: res.id, classId: this.Model.id});
        }*/
      });
  }

  cancelBooking(client) {
    client.disabled = true;
    this.instantUpdateInfoVisit(client, 'CancelledByClub');
    this.service.cancelBooking(client, false, false, false)
      .then(() => {
        this.removeRequest(client);
        this.getVisits(this.Model.id, false);
        this.getGroups(false, client);
      })
      .catch((err) => {
        this.getVisits(this.Model.id, false);
        this.getGroups(false, client);
      })
  }

  public isFree(subscriptions) {
    if (!this.isDisableConfirmSignWithoutSubscr) return false;

    if (subscriptions && subscriptions.length) {
      let selected = subscriptions.find(s => s.isSelected);

      return selected ? selected.hasOwnProperty('isFree') : false;
    } else return true;
  }

  confirmVisited(client) {
    if (!this.isFree(client.subscriptions)) {
      client.disabled = true;
      this.instantUpdateInfoVisit(client, 'VisitedByClub');
      this.service.confirmVisited(client, false, false)
        .then((res) => {
          this.removeRequest(client);
          this.getVisits(this.Model.id, false);
          this.getGroups(false, client);
        })
        .catch((err) => {
          this.getVisits(this.Model.id, false);
          this.getGroups(false, client);
        })
    } else {
      this.alertsService.alert.next({
        type: 'danger',
        message: 'Невозможно подтвердить посещение без выбранного абонемента.',
        header: 'Сообщение',
        position: 'bottom',
        timeout: 5000
      });
    }
  }

  removeRequest(client) {
    this.apiV2Service.classRequest.getByClub(this.route.snapshot.paramMap.get('clubId'), false).then(reqs => {
      const reqId = reqs.find(m => m.classVisitId == client.id)?.id;
      if (reqId) {
        // Удаляем новую заявку с рабочего стола. т.к. она уже подтверждена клубом
        this.apiV2Service.classRequest.delete(reqId, false, false);
      }
    });
  }

  confirmMissed(client) {
    if (!this.isFree(client.subscriptions)) {
      client.disabled = true;
      this.instantUpdateInfoVisit(client, 'MissedByClub');
      this.service.confirmMissed(client, false, false)
        .then((res) => {
          this.removeRequest(client);
          this.getVisits(this.Model.id, false);
          this.getGroups(false, client);
        })
        .catch((err) => {
          this.getVisits(this.Model.id, false);
          this.getGroups(false, client);
        })
    } else {
      this.alertsService.alert.next({
        type: 'info',
        message: 'Невозможно отметить занятие как пропущенное без выбранного абонемента.',
        header: 'Сообщение',
        position: 'bottom',
        timeout: 5000
      });
    }
  }

  goToClient(client, event?: any) {
    if (!this.isClassVisitClientAvailable) return;
    const url = this.contextService.makeContextUrl(`clubclients/${client.clientId}`);
    if (event && (event.button === 2 || event.ctrlKey)) {
      window.open(url);
      return false;
    }
    this.router.navigate([url]);
  }

  getFullName(client) {
    return (`${client.firstName || ''} ${client.lastName || ''}`).trim();
  }

  showSubscriptions(clientId) {
    const url = this.contextService.makeContextUrl(`clubplans-sale`)
    this.router.navigate([url], {
      queryParams: {
        clientId: clientId,
        courseId: this.Model.course ? this.Model.course.id : null
      }
    });
  }

  createVisitForGroupUser(client: any, subscriptionId: string) {
    const selectSub = client.subscriptions.find(sub => sub.id === subscriptionId)
    if (typeof selectSub === 'undefined') { this.changeSubPlan(client, subscriptionId); }
    if (this.isVirtualId(client.id)) {
      this.service.createVisitForGroupUser(this.getGroupUserRequestModel(client)).then(newVisit => {
        client.id = newVisit.id;
        client.classId = newVisit.classId;
        this.selectSubscriptionForClient(client, subscriptionId);
      });
    }
  }

  selectSubscriptionForClient(client: any, subscriptionId: string) {
    const selectSub = client.subscriptions.find(sub => sub.id == subscriptionId)

    if (typeof selectSub === 'undefined') this.changeSubPlan(client, subscriptionId);

    if (selectSub.isExpirationDate) {
      this.alertsService.showConfirm({
        header: 'Срок действия абонемента',
        message: 'Дата проведения занятия не входит в срок действия абонемента. Всё равно поменять абонемент?',
        buttons: [{
          title: 'Да',
          callback: () => {
            this.changeSubPlan(client, subscriptionId);
          }
        }, {
          title: 'Изменить срок',
          callback: () => {
            this.router.navigate(
              ['/' + this.contextService.makeContextUrl(`clubclients/${client.clientId}` + '/extension')],
              { queryParams: { subsId: subscriptionId } }
            );
          },
        }, {
          title: 'Нет',
          callback: () => {
            client.subscriptions = JSON.parse(JSON.stringify(client.subscriptions))
          }
        }]
      });
    } else {
      this.changeSubPlan(client, subscriptionId);
    }
  }

  changeSubPlan(client: any, subscriptionId: string) {
    if (subscriptionId === 'null') {
      this.showSubscriptions(client.clientId);
    } else {

      this.service
        .validateSubscriptionNotSuitable({
          classVisitId: client.id,
          clientSubscriptionId: subscriptionId
        })
        .then(valid => {
          if (valid) {
            this.service
              .changeSubscriptionPlan({
                classVisitId: client.id,
                clientSubscriptionId: subscriptionId
              })
              .then(() => {
                if (this.isVirtual() && !this.isVirtualId(client.classId)) {
                  this.virtualToRealityClass(client);
                }
                this.getVisits(this.Model.id, false);
                this.getGroups(false, client);
              })
              .catch(() => {
                this.getVisits(this.Model.id, false);
                this.getGroups(false, client);
              });
          } else {
            this.alertsService.showConfirm({
              header: 'Абонемент клиента не распространяется на данное занятие.',
              message: 'Можем списать занятие с указанного абонемента в обход ограничений. Делаем?',
              buttons: [{
                title: 'Да',
                callback: () => {
                  this.service
                    .changeSubscriptionPlan({
                      classVisitId: client.id,
                      clientSubscriptionId: subscriptionId,
                      isValidateSubscriptionNotSuitable: true
                    })
                    .then(() => {
                      if (this.isVirtual() && !this.isVirtualId(client.classId)) {
                        this.virtualToRealityClass(client);
                      }
                      this.getVisits(this.Model.id, false);
                      this.getGroups(false, client);
                    })
                    .catch(() => {
                      this.getVisits(this.Model.id, false);
                      this.getGroups(false, client);
                    });
                },
              }, {
                title: 'Нет',
                callback: () => {
                  if (this.isVirtual() && !this.isVirtualId(client.classId)) {
                    this.virtualToRealityClass(client);
                  }
                  client.subscriptions.forEach(sub => sub.isSelected = isGuidEmpty(sub.id))
                  client.subscriptions = JSON.parse(JSON.stringify(client.subscriptions))
                }
              }]
            });
          }
        });
    }
  }

  async confirmEvent(classVisit: any) {
    const context = this.contextService.getRoutingParams();
    let subId = '00000000000000000000000000000000';
    if (!classVisit) { return; }
    if (classVisit.subscriptions.length) {
      subId = (classVisit.subscriptions.find((s: any) => s.isSelected)).id;
    }
    await this.apiV2Service.classRequest.searchConfirmAndDeleteOrBook(classVisit.id, subId, context?.clubId).then((res) => {
      classVisit.disabled = true;
      this.instantUpdateInfoVisit(classVisit, res.visitStatus);
      this.getVisits(this.Model.id, false);
      this.getGroups(false, classVisit);
    })
      .catch((err) => {
        this.getVisits(this.Model.id);
        this.getGroups(true, classVisit);
      })
  }

  addGroup(groupId: string) {
    const localStorageGroupId: any = localStorage.getItem('groupId');
    if (!this.Model.id) {
      if (localStorageGroupId === '') {
        localStorage.setItem('groupId', groupId);
        this.finishEdit(true);
      }
      return;
    }
    const startDate = this.classTimeStamp - (new Date().getTimezoneOffset() * 60 * 1000);

    const message = {
      header: 'Записать группу?',
      buttons: [{
        title: 'Записать на это занятие',
        callback: () => {
          !isGuidEmpty(this.Model.id) ?
            this.groupsService.addClass(groupId, this.Model.id)
              .then(() => {
                this.getGroups();
              }) :
            this.groupsService.addSingleClass(groupId, this.Model.prototypeId, startDate)
              .then((res) => {
                console.warn(res);
                this.virtualToRealityClass(res);
                this.getVisits(this.Model.id);
                this.getGroups();
              });
        }
      }, {
        title: 'Записать на все занятия',
        callback: () => {
          this.groupsService.addClassPrototype(groupId, this.Model.prototypeId, startDate)
            .then(() => {
              this.getGroups();
            });
        }
      }],
      callbackEsc: () => this.groupControl.setValue('')
    }

    if (!this.isHideBtnDoТotWrite) {
      message.buttons.push({
        title: 'Не записывать',
        callback: () => {
          this.groupControl.setValue('')
        }
      });
    }

    this.alertsService.showConfirm(message);
    this.isHideBtnDoТotWrite = false;
    return;

    if (this.Model.prototypeId) {
      this.groupsService.addClassPrototype(groupId, this.Model.prototypeId, startDate)
        .then(() => {
          this.getGroups();
        });
    }

    if (this.Model.id && !this.isVirtual()) {
      this.groupsService.addClass(groupId, this.Model.id)
        .then(() => {
          this.getGroups();
        });
    }

    this.groupControl.setValue('');
  }

  getGroups(incrementRequestCount: boolean = true, visitForOneChange: any = null) {
    let scrollPos = document.getElementById('app_container').scrollTop;
    const localStorageGroupId: any = localStorage.getItem('groupId');
    if (localStorageGroupId !== null) {
      this.isHideBtnDoТotWrite = true;
      this.addGroup(localStorageGroupId);
      localStorage.removeItem('groupId');
      this.isHideBtnDoТotWrite = false;
    }
    var startDate = this.classTimeStamp - (new Date().getTimezoneOffset() * 60 * 1000);

    if (this.Model.id || this.Model.prototypeId) {
      this.service.getGroups(this.contextService.getRoutingParams().clubId, incrementRequestCount)
        .then(clubGroups => {
          this.service.getClassVisits(this.Model.id, this.Model.prototypeId, startDate, "", incrementRequestCount)
            .then(res => {
              const groupVisits = res.classVisits;
              let classGroups = [];

              if (groupVisits && groupVisits.length) {
                groupVisits.forEach(visit => {
                  visit.disabled = false;
                  visit.groups.forEach(group => group.id && classGroups.push(group));

                  if (visit.currentVisitStatus?.startsWith('Cancelled'))
                    visit.isCanceled = true;
                  if (visit.currentVisitStatus == 'QueuedCancelledByUser' || visit.currentVisitStatus == 'QueuedCancelledByClub')
                    visit.isCanceled = true;
                  if (visit.currentVisitStatus?.startsWith('Missed'))
                    visit.isMissed = true;

                  // Теперь нужно добавить пункт 'Без абонемента'
                  if (!visit.isExcludedUser && visit.subscriptions && visit.subscriptions.length) {
                    let hasSelection = false;
                    visit.subscriptions.forEach(sub => {
                      if (sub.isSelected) {
                        hasSelection = true;
                      }
                      sub.extraInfo = this.SubscriptionExtraInfo(sub);
                      sub.disabled = sub.consumedCount == sub.visitCount;
                    });

                    visit.subscriptions.splice(0, 0, {
                      id: '00000000000000000000000000000000',
                      isSelected: !hasSelection,
                      name: 'Без абонемента'
                    });
                  }
                });
                this.sortSubscriptions(groupVisits)
              }
              if (visitForOneChange) {
                const clientId = visitForOneChange.clientId;
                const sortGroupVisits = groupVisits.sort(x => !x.isCanceled ? -1 : 1);
                this.groupVisits.forEach((visit, index) => {
                  const updateVisit = sortGroupVisits.find(newVisit =>
                    visit.clientId === clientId && newVisit.clientId === visit.clientId
                  );
                  if (updateVisit) {
                    this.groupVisits[index] = updateVisit;
                  }
                });
              } else {
                this.groupVisits = groupVisits.sort(x => !x.isCanceled ? -1 : 1);
              }
              this.sortOptionChange(this.isNeedSort);
              this.formForSort = new FormGroup({
                sortOption: new FormControl(this.sortOrders.length ? this.sortOrders[0][0] + '-' + this.sortOrders[0][1] : ''),
              });
              setTimeout(() => {
                // document.getElementById('app_container').scrollTo(0, scrollPos);
              })

              //Группы для тегов и отписки без дубликатов
              this.classGroups = classGroups.reduce((groups, group) => {
                if (!groups.find(g => g.id == group.id)) {
                  groups.push(group);
                }
                return groups;
              }, []);

              //Если записанные группы classGroups уже есть, то эти группы не выводим в списке групп clubGroups для записи
              if (this.classGroups && this.classGroups.length > 0) {
                clubGroups = clubGroups.filter(x => this.classGroups.find(m => m.id == x.id) == null)
              }
              this.groups = clubGroups;
            })
            .finally(() => {
              visitForOneChange.disabled = false;
            });
        });
    } else {
      this.service.getGroups(this.contextService.getRoutingParams().clubId)
        .then(clubGroups => {
          this.groups = clubGroups;
        });
    }
  }

  public styleBorderLeftRow(item: any) {
    let _status = item.currentVisitStatus;
    switch (_status) {
      case "Booked":
      case "WaitingBookingConfirmation":
        return { 'border-left': '5px solid #00A3E2' } // blue
      case "Queued":
        return { 'border-left': '5px solid #ffd4bc' }
      case "VisitedByClub":
      case "VisitedByClubConflict":
      case "Visited":
      case "CancelledByUserConflict":
      case "MissedByUserConflict":
        return { 'border-left': '5px solid #00C168' } // green
      case "VisitedByUser":
      case "VisitedByUserConflict":
      case "QueuedCancelledByUser":
      case "QueuedCancelledByClub":
      case "CancelledByClub":
      case "CancelledByClubConflict":
      case "CancelledByUser":
        return { 'border-left': '5px solid rgb(87 70 131 / 73%)' }
      case "MissedByClub":
      case "MissedByClubConflict":
      case "MissedByUser":
      case "Missed":
        return { 'border-left': '5px solid #FFA7B6' } // red
      default:
        return { 'border-left': '5px solid #00C168' }
    }
  }

  confirmForGroupUser(groupClassVisit) {
    this.isWaitingToReality = true;
    groupClassVisit.disabled = true;
    this.instantUpdateInfoVisit(groupClassVisit, 'VisitedByClub');
    var reqModel = this.getGroupUserRequestModel(groupClassVisit);

    // Запрос на бронирование
    this.service.confirmForGroupUser(reqModel, false)
      .then(res => {
        if (res.status === 'WithoutSubscription')
          this.alertsService.alert.next({
            type: 'danger',
            message: 'Невозможно подтвердить посещение без выбранного абонемента.',
            header: 'Сообщение',
            position: 'bottom',
            timeout: 5000
          });
        if (this.isVirtual()) {
          this.virtualToRealityClass(res);
        }
        groupClassVisit.id = res.id;
        groupClassVisit.classId = res.classId;
        this.getVisits(this.Model.id, false);
        this.getGroups(false, groupClassVisit);
      })
      .catch((err) => {
        this.getGroups(false, groupClassVisit);
      })
      .finally(() => {
        this.isWaitingToReality = false;
      })
  }

  missForGroupUser(groupClassVisit) {
    this.isWaitingToReality = true;
    groupClassVisit.disabled = true;
    this.instantUpdateInfoVisit(groupClassVisit, 'MissedByClub');
    var reqModel = this.getGroupUserRequestModel(groupClassVisit);

    this.service.missForGroupUser(reqModel, false)
      .then(res => {
        if (res.status === 'WithoutSubscription')
          this.alertsService.alert.next({
            type: 'danger',
            message: 'Невозможно отметить занятие как пропущенное без выбранного абонемента.',
            header: 'Сообщение',
            position: 'bottom',
            timeout: 5000
          });
        if (this.isVirtual()) {
          this.virtualToRealityClass(res);
        }
        groupClassVisit.id = res.id;
        groupClassVisit.classId = res.classId;
        this.getVisits(this.Model.id, false);
        this.getGroups(false, groupClassVisit);
      })
      .catch((err) => {
        this.getGroups(false, groupClassVisit);
      })
      .finally(() => {
        this.isWaitingToReality = false;
      })
  }

  cancelForGroupUser(groupClassVisit) {
    this.isWaitingToReality = true;
    groupClassVisit.disabled = true;
    this.instantUpdateInfoVisit(groupClassVisit, 'CancelledByClub');
    var reqModel = this.getGroupUserRequestModel(groupClassVisit);

    this.service.cancelForGroupUser(reqModel, false)
      .then(res => {
        if (this.isVirtual()) {
          this.virtualToRealityClass(res);
        }
        groupClassVisit.id = res.id;
        groupClassVisit.classId = res.classId;
        this.getVisits(this.Model.id, false);
        this.getGroups(false, groupClassVisit);
      })
      .catch((err) => {
        this.getGroups(false, groupClassVisit);
      })
      .finally(() => {
        this.isWaitingToReality = false;
      })
  }

  getGroupUserRequestModel(groupClassVisit) {
    var startDate = this.classTimeStamp - (new Date().getTimezoneOffset() * 60 * 1000);
    var classId = !this.isVirtual() ? this.Model.id : null;
    var classPrototypeId = this.Model.prototypeId && this.Model.prototypeId !== '00000000000000000000000000000000'
      ? this.Model.prototypeId
      : null;

    return {
      userId: groupClassVisit.userId,
      groupId: groupClassVisit.groupId,
      classId: classId,
      classPrototypeId: classPrototypeId,
      startDate: startDate
    };
  }

  showGroup(group: IGroupModel, event?: any) {
    if (event && (event.button === 2 || event.ctrlKey)) {
      const url = this.router.url.split('clubschedule')[0] + 'clubgroups/' + group.id;
      window.open(url.toString());
      return false;
    }
    this.router.navigateByUrl(this.router.url.split('clubschedule')[0] + 'clubgroups/' + group.id);
  }
  confirmRemoveGroup(classGroup) {
    var startDate = this.classTimeStamp - (new Date().getTimezoneOffset() * 60 * 1000);
    this.alertsService.showConfirm({
      header: 'Отписать группу?',
      buttons: [{
        title: 'Отписать от занятия',
        callback: () => {
          // Если занятие виртуальное, то нужно создать занятие
          if (this.isVirtual()) {
            this.groupsService.removeClass(classGroup.id, null, this.Model.prototypeId, startDate, false)
              .then(res => {
                this.virtualToRealityClass(res);
                this.getVisits(this.Model.id);
                this.getGroups();
              });
          } else {
            this.groupsService.removeClass(classGroup.id, this.Model.id, null, null, false)
              .then(() => {
                this.ordersSortSave(); // нужно для того чтобы не скакали строки таблицы при нажатии на кнопки
                // Обновляем списки
                this.getGroups();
              });
          }
        }
      }, {
        title: 'Отписать от всех занятий',
        callback: () => {
          // Если занятие виртуальное, то нужно создать занятие
          if (this.isVirtual()) {
            this.groupsService.removeClass(classGroup.id, null, this.Model.prototypeId, startDate, true)
              // .then(res => {
              //     this.router.navigate([`../../../${res.classId}`], { relativeTo: this.route });
              // });
              .then(() => this.getGroups());
          } else {
            this.groupsService.removeClass(classGroup.id, this.Model.id, null, null, true)
              .then(() => {
                this.ordersSortSave(); // нужно для того чтобы не скакали строки таблицы при нажатии на кнопки
                // Обновляем списки
                this.getGroups();
              });
          }
        }
      }, {
        title: 'Не отписывать'
      }]
    });
  }

  private onControlChange() {
    const changed = Object.keys(this.form.controls)
      .find(x => this.isControlChanged(this.form.controls[x], this.sourceModel[x]));
    this.modelChanged = changed !== undefined;
  }

  private isControlChanged(control: AbstractControl, originValue: any): boolean {
    return control?.value != originValue
      && !(typeof (control?.value) === 'object' && control?.value?.id == (originValue ? originValue?.id : null))
      && !((control?.value == null || control?.value == '') && (originValue == null || originValue == ''))
      ;
  }

  private sortSubscriptions(clients: any[]) {
    clients.forEach(client => {
      const arrays = {
        firstArr: [],
        secondArr: [],
        thirdArr: [],
        fourArr: []
      }

      client.subscriptions?.forEach(sub => {
        const now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        let toDate = new Date(sub.toDate);
        if (sub.toDate == null) {
          toDate = now
        }
        if (toDate && (toDate < now)) {
          sub.isExpirationDate = true;
        }

        const isInfinity = sub.extraInfo?.includes('∞');
        if (isInfinity) { sub.visitCount = 9999; }

        if ((sub.consumedCount < sub.visitCount) || isGuidEmpty(sub.id) || (!sub.visitCount)) {
          if (sub.status === 'created' || sub.status === 'active' || sub.id === '00000000000000000000000000000000') {
            arrays.firstArr.push(sub);
          } else if (sub.isSelected) {
            sub.isWarn = true;
            arrays.thirdArr.push(sub);
          }
        } else if (sub.consumedCount < sub.visitCount) {
          arrays.secondArr.push(sub);
        } else if (sub.isSelected) {
          sub.isWarn = true;
          arrays.thirdArr.push(sub);
        }

        if (isInfinity) { sub.visitCount = null; }


      })

      // FIXME: Нужно как-то оптимизировать

      const sortArray = arrays.firstArr.concat(arrays.secondArr).concat(arrays.thirdArr).concat(arrays.fourArr);
      client.subscriptions = [];
      sortArray.forEach((item) => {
        client.subscriptions.push(item);
      })
    })

    const IsSetSubscriptionForGroupAutoCreatedVisits = !this.clubSettings.find(x => x.alias === 'IsSetSubscriptionForGroupAutoCreatedVisits') ? false :
      JSON.parse(this.clubSettings.find(x => x.alias === 'IsSetSubscriptionForGroupAutoCreatedVisits').value);

    if (IsSetSubscriptionForGroupAutoCreatedVisits) {
      for (const client of clients) {
        if (client.subscriptions.find(x => x.isSubscriptionValidForClass) && this.isVirtualId(client.id)) {
          const selectedNotSub = client.subscriptions.find(x => x.isSelected);
          if (selectedNotSub && this.isVirtualId(selectedNotSub.id)) {
            selectedNotSub.isSelected = false;
            client.subscriptions.find(x => x.isSubscriptionValidForClass).isSelected = true;
          }
        }
      }
    }

    return clients
  }

  changeDenyRecording() {
    this.form.get('messageDenyRecording').reset();
    this.onControlChange();
    if (this.Model.isDenyRecording) {
      this.form.get('messageDenyRecording').enable();
    } else {
      this.form.get('messageDenyRecording').disable();
    }
  }

  async confirmQueued(client: any) {
    client.disabled = true;
    this.instantUpdateInfoVisit(client, 'Booked');
    this.service.confirmQueued(client, false, false)
      .then(() => {
        this.getVisits(this.Model.id, false);
        this.getGroups(false, client);
      })
      .catch((err) => {
        this.getVisits(this.Model.id, false);
        this.getGroups(false, client);
      })
  }

  instantUpdateInfoVisit(visit: any, toStatus: any) {
    switch (toStatus) {
      case 'VisitedByClub':
        visit.currentVisitStatus = 'VisitedByClub';
        visit.currentVisitStatusText = 'Подтверждено клубом';
        visit.isCanceled = false;
        visit.isMissed = false;
        break;
      case 'CancelledByClub':
        visit.currentVisitStatus = 'CancelledByClub';
        visit.currentVisitStatusText = 'Отменено клубом';
        visit.canceledAt = moment().valueOf();
        visit.isCanceled = true;
        visit.isMissed = false;
        break;
      case 'MissedByClub':
        visit.currentVisitStatus = 'MissedByClub';
        visit.currentVisitStatusText = 'Пропуск отмечен клубом';
        visit.isCanceled = false;
        visit.isMissed = true;
        break;
      case 'Booked':
        visit.currentVisitStatus = 'Booked';
        visit.currentVisitStatusText = 'Забронировано';
        visit.isCanceled = false;
        visit.isMissed = false;
        break;
      case 'Queued':
        visit.currentVisitStatus = 'Queued';
        visit.currentVisitStatusText = 'В очереди';
        visit.isCanceled = false;
        visit.isMissed = false;
        break;
    }
  }

  private virtualToRealityClass(res: any) {
    this.Model.id = res.classId;
    const _u = this.router.url.split('virtual');
    this.location.replaceState(_u[0] + res.classId);
    this.isVirtualToRealityClass = true;
  }

  // private navigateToClass(classId: string) {
  //   const _u = window.location.href.split('/virtual');
  //   if (_u.length === 2)
  //     window.history.replaceState(null, '', _u[0]);
  //   this.router.navigate([`../../../${classId}`], { relativeTo: this.route });
  // }

  protected readonly helpers = helpers;
}
