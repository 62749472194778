<footer class="footer">
  <div class="footer__data">
    <div class="footer__container wrapper">
      <div [class.partner-logo]="isThemePartner" *ngIf="isThemePartner">
        <img src="./assets/partner-logo/partner-logo-arena.png" alt="" />
      </div>
      <a href="https://sportpriority.com/b2b" class="footer__logo">
        <img src="./assets/icons/logo-color.svg" alt="logo">
        <span class="footer__logo_label">&mdash; ваш цифровой партнёр</span>
      </a>

      <div class="footer__contacts">
        <span class="version">Версия 3.6.56 от 21.01.2025</span>
        <span class="phone">+7 (499) 130-9088</span>
        <a class="widget-url" href="https://api.whatsapp.com/send?phone=+79031309088&text=Здравствуйте%2C+у+меня+есть+вопрос" *ngIf="supportUrl">
          <span class="sp sp-watsup footer__message-icon"></span>
          Написать в техподдержку
        </a>
      </div>

    </div>
  </div>
</footer>
