  <div class="item" [class.showInSchedule]="isShowInSchedule()" [class.checked-border]="Model.isChecked && isInCopyMode" [ngClass]="{'last':last, 'ghost':isDraft(), 'clone':isVirtual()}">
    <div class="bk" [style.border]="Model.numberOfVisits != 0 && !isVirtual() ? '2px solid '+ borderColor : ''" [style.backgroundColor]="Model.numberOfVisits != 0 ? currentColor : ''"
         [ngClass]="{'dash': Model.numberOfVisits >= Model.maxCapacity, 'clip': Model.numberOfVisits == 0}"
         [style.--c]="Model.numberOfVisits == 0 ? currentColor: ''" [style.--b]="isVirtual() && Model.numberOfVisits == 0 ? currentColor : borderColor">
    </div>
    <div class="clubDownTime" *ngIf="isClubDownTime" [ngClass]="{'clubDownTime__clip': Model.numberOfVisits == 0}"></div>
    <div class="badge">
      <span class="letter newGroup" *ngIf="Model.isNewGroup">
          Н
          <span class="help">Новый набор</span>
      </span>

      <span class="letter" *ngIf="isConflicted()">
          !
          <span class="help">{{getConflicts()}}</span>
      </span>

      <span class="letter draft" *ngIf="isDraft()">
        Ч
        <span class="help">Черновик</span>
      </span>
      <span class="letter draft" *ngIf="Model.isHideClassForClients">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 297 297" xml:space="preserve"><path d="M294.908,142.225c-0.566-0.756-14.168-18.72-38.881-36.693c-10.007-7.277-20.418-13.504-31.116-18.652l47.458-47.458 c4.084-4.084,4.084-10.706,0-14.79c-4.085-4.083-10.705-4.083-14.79,0L203.922,78.29c-18.06-6.122-36.7-9.269-55.42-9.269 c-37.501,0-74.683,12.625-107.526,36.51C16.262,123.506,2.658,141.47,2.092,142.225c-2.789,3.718-2.789,8.831,0,12.549 c0.566,0.756,14.17,18.72,38.884,36.694c10.006,7.277,20.418,13.503,31.115,18.651l-47.458,47.458 c-4.084,4.084-4.084,10.706,0,14.79c2.043,2.042,4.719,3.063,7.394,3.063c2.678,0,5.354-1.021,7.396-3.063l53.658-53.658 c18.062,6.122,36.701,9.268,55.421,9.268c37.502,0,74.684-12.625,107.525-36.511c24.713-17.974,38.315-35.938,38.881-36.693 C297.697,151.057,297.697,145.943,294.908,142.225z M207.065,148.5c0,32.292-26.271,58.564-58.563,58.564 c-12.376,0-23.859-3.87-33.328-10.446l23.981-23.98c2.899,1.123,6.05,1.746,9.347,1.746c14.296,0,25.883-11.587,25.883-25.883 c0-3.298-0.623-6.447-1.746-9.348l23.98-23.98C203.196,124.641,207.065,136.123,207.065,148.5z M89.939,148.5 c0-32.292,26.271-58.563,58.564-58.563c12.376,0,23.859,3.868,33.326,10.446l-23.98,23.98c-2.9-1.123-6.049-1.746-9.346-1.746 c-14.296,0-25.883,11.587-25.883,25.883c0,3.297,0.623,6.446,1.746,9.346l-23.98,23.98C93.808,172.358,89.939,160.876,89.939,148.5z M24.153,148.5c5.687-6.283,15.785-16.427,29.681-26.457c9.118-6.581,18.458-12.157,27.996-16.725 c-8.088,12.443-12.807,27.268-12.807,43.182s4.719,30.738,12.807,43.182c-9.538-4.567-18.878-10.144-27.996-16.725 C39.937,164.925,29.836,154.779,24.153,148.5z M243.167,174.957c-9.115,6.581-18.456,12.156-27.991,16.724 c8.086-12.442,12.805-27.268,12.805-43.181s-4.719-30.738-12.805-43.181c9.535,4.567,18.876,10.143,27.991,16.724 c13.897,10.032,23.998,20.178,29.681,26.457C267.162,154.783,257.063,164.927,243.167,174.957z" fill="#000000" style="fill: rgb(255, 255, 255);"></path></svg>
        <span class="help">Скрыто от клиентов</span>
      </span>
      <span class="letter cancel-booking" *ngIf="Model.isDenyRecording">
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m25.48 13.88h-1.7v-4.73c0-4.22-3.43-7.65-7.65-7.65h-.26c-4.22 0-7.65 3.43-7.65 7.65v4.73h-1.7c-1.39 0-2.52 1.14-2.52 2.54v11.54c0 1.4 1.13 2.54 2.52 2.54h18.96c1.39 0 2.52-1.14 2.52-2.54v-11.54c0-1.4-1.13-2.54-2.52-2.54zm-13.21-4.6c0-2.05 1.68-3.73 3.73-3.73s3.73 1.68 3.73 3.73v4.6h-7.46z" fill="#000000" style="fill: rgb(0, 0, 0);"></path></svg>
        <span class="help">Бронирование выключено</span>
      </span>
    </div>

    <div class="title" [style.backgroundColor]="Model.course?.color">
      {{Model.course?.name}}
    </div>

    <div class="time" [style.backgroundColor]="Model.course?.color">
      {{Model.startTime}} - {{endTime |date:'HH:mm'}} ({{Model.duration |duration}})
    </div>

    <div class="coach" *ngIf="Model.coach?.name" [ngStyle]="{'background-color': Model.coach?.color, 'margin-bottom': Model.coach.color ? '0.3rem' : '0' }">
      {{Model.coach.name}}
    </div>
    <div class="room" *ngIf="Model.room?.name" [style.backgroundColor]="Model.room?.color">
      {{Model.room.name}}
    </div>
    <div class="level" *ngIf="Model.level" [style.backgroundColor]="Model.course?.color">
      {{Model.level}}
    </div>
    <div class="comment" *ngIf="Model.comment">
      {{Model.comment}}
    </div>
    <div class="capacity" [style.backgroundColor]="Model.course?.color">
      <ng-container *ngIf="Model.maxCapacity === 0">{{Model.numberOfVisits}} чел.</ng-container>
      <ng-container *ngIf="Model.maxCapacity !== 0">{{Model.numberOfVisits - Model.numberOfQueued}}/{{Model.maxCapacity}} {{Model.numberOfQueued > 0 ? '(+' + Model.numberOfQueued + ')' : null}}</ng-container>
      <ng-container *ngIf="isShowInSchedule()"> - ОТМЕНА</ng-container>
    </div>
    <button *ngIf="showEdit" [style.color]="currentColor" (click)="onClickHandler()">Изменить</button>
  </div>
