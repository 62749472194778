<header class="content__header" *ngIf="Model">
  <div class="content__header-title" [ngSwitch]="Model.id">
    <div class="content__back" (click)="close()">
      <span class="left-arrow">
        <span class="left-arrow__top"></span>
        <span class="left-arrow__bottom"></span>
      </span>
      <span class="content__header-title-value" *ngSwitchCase="null">Создание абонемента</span>
      <span class="content__header-title-value" *ngSwitchDefault>Редактирование абонемента</span>
    </div>
    <div class="">
      <a class="content__header-link" (click)="close()">
        Закрыть
        <span class="key">
          <span app-key-help [key]="'close'"></span>
        </span>
      </a>
    </div>
  </div>
</header>

<div class="content__body" *ngIf="Model">
  <form [formGroup]="form" novalidate>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Название</div>
          <div class="row__field">
            <input type="text" placeholder="Понятное для клиентов название" class="input__text input__text--long"
              formControlName="name" id="name" [ngModel]="Model.name" maxlength="500">
            <label for="name" class="field-validation-error"
              *ngIf="fieldErrors.name && isSubmited">{{fieldErrors.name}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Описание</div>
          <div class="row__field">
            <ckeditor placeholder="Коротко о важных деталях покупки, использования и продления абонемента"
              [(ngModel)]="Model.description" formControlName="description" id="description" maxlength="2000">
            </ckeditor>
            <label for="description" class="field-validation-error"
              *ngIf="fieldErrors.description && isSubmited">{{fieldErrors.description}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">Тип абонемента</div>
          <div class="row__field">
              <select class="select select__long" ([ngModel])="Model.activationType" formControlName="activationType" (ngModelChange)="changeActivationType($event)">
                  <option value="firstclassvisit">Активируется при первом посещении</option>
                  <option value="fixeddates">Фиксированные даты</option>
              </select>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6" *ngIf="form.get('activationType').value == 'firstclassvisit'">
        <div class="form-group">
          <div class="box-title">Выбрать срок активации</div>
          <div class="row__field">
            <input type="number" class="input__text input__text--long" [min]="0"
                   (blur)="_onDaysUntilActivation($event)" id="daysUntilActivation"
                   formControlName="daysUntilActivation" [ngModel]="Model.daysUntilActivation">
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">Добавить дни к сроку действия</div>
          <div class="row__field">
            <input type="number" class="input__text input__text--long"
              (blur)="_onToDateCorrectionBlur($event)"
              id="toDateCorrection" formControlName="toDateCorrection"
              [ngModel]="Model.toDateCorrection">
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" myScrollTo>
          <div class="box-title">Группа</div>
          <div class="row__field">
            <input type="text" class="input__text input__text--long" id="group" formControlName="group"
              [ngModel]="Model.group" maxlength="500">
            <label for="group" class="field-validation-error"
              *ngIf="fieldErrors.group && isSubmited">{{fieldErrors.group}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" myScrollTo>
          <div class="box-title">Порядок сортировки</div>
          <div class="row__field">
            <input type="number" class="input__text input__text--long" id="sortOrder" formControlName="sortOrder"
              [ngModel]="Model.sortOrder" maxlength="500">
            <label for="sortOrder" class="field-validation-error"
              *ngIf="fieldErrors.sortOrder && isSubmited">{{fieldErrors.sortOrder}}</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">Запустить акции для абонемента?</div>
          <div class="row__field">
            <input type="checkbox" [(ngModel)]="visibleOnSaleCampain" (change)="showSales()" class="checkbox__slide"
              formControlName="visibleOnSaleCampain" id="visibleOnSaleCampain">
            <label for="visibleOnSaleCampain" class="checkbox__slide_label"></label>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-sm-up"></div>

      <div *ngIf="visibleOnSaleCampain">
        <div class="col-xs-12 col-sm-6">
          <div class="clue-text">Заполните даты проведения акции и укажите акционную цену.</div>
        </div>
        <div class="clearfix hidden-sm-up"></div>
        <div class="datesBlock">
          <div class="form-group" style="margin-right: 20px;" myScrollTo>
            <div class="row__field">
              <div class="box-title">Начало акции</div>
              <app-date-time-picker [(date)]="this.Model.saleCampainStartDate" [isBindingTimeZone]="true" mode="daytime">
              </app-date-time-picker>
            </div>
          </div>
          <div class="form-group" myScrollTo>
            <div class="row__field">
              <div class="box-title">Окончание акции</div>
              <app-date-time-picker [(date)]="this.Model.saleCampainEndDate" [isBindingTimeZone]="true" mode="daytime">
              </app-date-time-picker>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <div class="box-title">
            <span>Варианты цен</span>
            <a class="box-link" (click)="variants.addVariant(false)">Добавить</a>
          </div>
          <div class="info-text mb-2">
            Первое бесплатное занятие создается как отдельный абонемент с нулевой ценой. Тот же алгоритм работает с
            пробными занятиями
            по специальной цене.
          </div>
          <div class="row__field">
            <app-variants [(ngModel)]="Model.variants" formControlName="variants"
              [VisiblePriceOnSaleCampain]="visibleOnSaleCampain"></app-variants>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">Ограничение на покупку одним клиентом</div>
          <div class="row__field">
            <input type="number" min="0" class="input__text input__text--long"
              id="clientSubscriptionLimit" formControlName="clientSubscriptionLimit"
              [ngModel]="Model.clientSubscriptionLimit">
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group" myScrollTo>
          <div class="box-title">Доступные виды занятий</div>
          <div class="row__field">
            <app-multiple-checkbox [options]="clubCourses" [(ngModel)]="Model.courses" id="courses" formControlName="courses">
            </app-multiple-checkbox>
            <label for="courses" class="field-validation-error"
                   *ngIf="fieldErrors.courses && isSubmited">Это поле обязательно</label>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="box-title">Заморозка</div>
          <div class="row__field">
            <input type="checkbox" [(ngModel)]="visibleOnSuspend" (change)="showSuspend()" class="checkbox__slide"
                   formControlName="visibleOnSuspend" id="visibleOnSuspend">
            <label for="visibleOnSuspend" class="checkbox__slide_label">Разрешить клиентам замораживать абонемент в приложении</label>
          </div>
        </div>
      </div>

      <div class="clearfix hidden-sm-up"></div>

      <div *ngIf="visibleOnSuspend">
        <div class="col-xs-12 col-sm-6">
          <div class="info-text mb-0">Укажите максимальное число дней и максимальное число заморозок для одного абонемента.</div>
        </div>
        <div class="clearfix hidden-sm-up"></div>
        <div class="datesBlock">
          <div class="form-group" style="margin-right: 20px;" myScrollTo>
            <div class="row__field">
              <div class="box-title">Максимум дней</div>
              <input type="number" class="input__text input__text--long" [min]="0" formControlName="suspendNumberDays">
            </div>
          </div>
          <div class="form-group" myScrollTo>
            <div class="row__field">
              <div class="box-title">Максимум заморозок</div>
              <input type="number" class="input__text input__text--long" [min]="0" formControlName="suspendNumberTimes">
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="form-group" >
          <div class="row__field">
            <app-gallery-cva [ngModel]="Model.medias" [multipleUpload]="false" formControlName="medias"
              [title]="'Фотогалерея'" (onItemOpen)="openImage($event)"></app-gallery-cva>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="info-text">

          <div [ngClass]="{'info-text__box' : !isNotSaved()}">
            <span class="icon icon-about"></span>
            <span app-key-help [key]="'draftText'"></span>
          </div>

          <div *ngIf="!isNotSaved()">
            <span class="icon icon-about"></span>
            Статус:
            <ng-container *ngIf="isDraft()">Черновик</ng-container>
            <ng-container *ngIf="isPublished()">Опубликовано</ng-container>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isEditAvailable">

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit(false)" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveText'"></span>
                  <span app-key-help mobile-hidden [key]="'saveHK'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <button class="btn btn-primary" (click)="finishEdit(true)" [disabled]="isSubmited && form.invalid">
                <span class="btn-primary__desc">
                  <span app-key-help [key]="'saveDraft'"></span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="form-group">
            <div class="row__field">
              <ng-container *ngIf="Model.id != null">
                <button class="btn btn-link" (click)="delete()">
                  <span app-key-help [key]="'deleteText'"></span>
                  <span app-key-help mobile-hidden [key]="'deleteHK'"></span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>

      </ng-container>

    </div>
  </form>
</div>
