import {Route} from '@angular/router';
import {AuthGuard} from 'app/common/services/auth-guard.service';
import {ClubCertificatesComponent} from '../../components/club-certificates/club-certificates.component';

export const clubCertificatesRoute: Route = {
  path: ':cityId/:clubNetId/:clubId/certificates',
  component: ClubCertificatesComponent,
  canActivate: [ AuthGuard ],
  data: {title: 'Сертификаты'}
};
